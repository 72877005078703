import {omit, isUndefined} from "lodash-es"
import {RootState} from "../types"
import {Policy, UploadInfo, PolicyState, Claim, ClaimStatusStep} from "./types"
import {Module} from "vuex"

import {policy as policyGateway, claim as claimGateway, communication as communicationApi} from "api/gateway"
import {convertPolicy, convertClaims, convertClaim, convertDocuments, convertStatusOverview} from "./conversion"
import {convertClaimCommunication} from "../communication/conversion"
import store from "../index"
import {PollClaim} from "store/communication/types"

const policyState: PolicyState = {
	policiesLoaded: false,
	claimsLoaded: false,
	policies: [],
	claims: []
}

const policies: Module<PolicyState, RootState> = {
	mutations: {
		hydrate(state, data: Array<Policy>) {
			state.policies = data
			state.policiesLoaded = true
		},
		claimsOverview(state, data: Array<Claim> | Claim) {
			if (Array.isArray(data)) {
				for (const claim of data) {
					const index = state.claims.findIndex(c => c.claimNumber === claim.claimNumber)
					if (index === -1) {
						state.claims.push(claim)
					}
				}
			} else {
				const index = state.claims.findIndex(c => c.claimNumber === data.claimNumber)
				if (index !== -1) {
					state.claims[index] = data
				} else {
					state.claims.push(data)
				}
			}
		},
		claimForOverview(state, payload: Claim) {
			const selectedClaimIndex = state.claims.findIndex(claim => claim.claimNumber === payload.claimNumber)
			if (!isUndefined(selectedClaimIndex)) {
				state.claims[selectedClaimIndex] = payload
			}
		},
		claim(state, payload: {policy: Policy, claim: Claim}) {
			const selectedPolicyIndex = state.policies.findIndex(policy => policy.policyIdentifier === payload.policy.policyIdentifier)
			if (!isUndefined(selectedPolicyIndex)) {
				state.policies[selectedPolicyIndex] = payload.policy
				state.policies[selectedPolicyIndex].claim = payload.claim
			}
		},
		claimsLoaded(state) {
			state.claimsLoaded = true
		},
		currentUserIsCoPolicyholder(state, policyIdentifier: Policy["policyIdentifier"]) {
			const selectedPolicyIndex = state.policies.findIndex(policy => policy.policyIdentifier === policyIdentifier)
			if (!isUndefined(selectedPolicyIndex)) {
				state.policies[selectedPolicyIndex].currentUserIsCoPolicyHolder = true
			}
		}
	},
	actions: {
		async uploadDocs(_context, uploadInfo: UploadInfo): Promise<void> {
			await claimGateway.uploadDocuments(uploadInfo)
		},
		async getOneClaimDocuments(context, selectedpolicy: Policy): Promise<void> {
			if (selectedpolicy.claim) {
				const claimData = await claimGateway.getOne(selectedpolicy.claim.claimNumber)
				const claimStatus: Array<ClaimStatusStep> | undefined = await context.dispatch("claimStatusOverview", selectedpolicy.claim.claimNumber)

				if (claimStatus) {
					const claimStatusName = claimStatus.filter(status => status.stepStatusExternalIdentifier === "ACTIVE")[0].externalStatusName
					claimData.externalStatusName = claimStatusName
				}

				const claim = convertClaim(claimData)

				if (claim) {
					const claimDocuments = await claimGateway.getDocuments(claim.claimNumber)

					if (claimDocuments) {
						claim.requestedInformation = convertDocuments(claimDocuments)!
					}
					context.commit("claim", {policy: selectedpolicy, claim})
					context.commit("claimForOverview", claim)
				}
			}
		},
		async getClaimDocuments(context, selectedpolicy: Policy): Promise<void> {
			const data = await policyGateway.getOne(selectedpolicy.policyIdentifier)
			const policy = convertPolicy(data)

			const claimData = await claimGateway.getMany()
			const claims = convertClaims(claimData)

			if (claims) {
				const policyClaims = claims.filter(item => item.policies && item.policies.includes(selectedpolicy.policyIdentifier)).sort(
					(a, b) => a.damageDate > b.damageDate || a.claimNumber > b.claimNumber ? -1 : 1
				)
				const claim = policyClaims[0] && omit(policyClaims[0], "policies")
				if (claim) {
					const claimDocuments = await claimGateway.getDocuments(claim.claimNumber)
					if (claimDocuments) {
						claim.requestedInformation = convertDocuments(claimDocuments)!
					}
					if (policy) {
						policy.claim = claim
						context.commit("claim", {policy, claim})
						store.commit("routing/selectPolicy", policy)

						const claimCommunication = await communicationApi.getManyClaim()
						const documents = convertClaimCommunication(claimCommunication)

						if (context.rootState.communication) {
							const claimDocumentsAfterLogin = context.rootState.communication.claim.length

							if (documents && (documents.length <= claimDocumentsAfterLogin)) {
								store.commit("communication/pollclaim", {poll: true, numberOfDocuments: claimDocumentsAfterLogin})
							} else {
								store.commit("communication/claim", documents)
							}
						}
					}
					store.commit("policies/claimsOverview", claim)
				}
				store.commit("policies/claimsOverview", claims.filter(c => c.statusId === -4))
				store.commit("policies/claimsLoaded")
			}
		},
		async pollClaimCommunication(_context, pollClaim: PollClaim): Promise<void> {

			const interval = 10000
			const currentInterval = setInterval(async () => {
				const claimCommunication = await communicationApi.getManyClaim()
				const documents = convertClaimCommunication(claimCommunication)

				if (documents && pollClaim.poll && pollClaim.numberOfDocuments !== undefined) {
					if (documents.length > pollClaim.numberOfDocuments) {
						store.commit("communication/claim", documents)
						store.commit("communication/pollclaim", {poll: false, numberOfDocuments: undefined})
						clearInterval(currentInterval)
					}
				} else {
					clearInterval(currentInterval)
				}
			}, interval)
		},
		async claimStatusOverview(_context, claimNumber: string): Promise<Array<ClaimStatusStep> | undefined> {
			const claimStatus = await claimGateway.getClaimStatusOverview(claimNumber)
			return convertStatusOverview(claimStatus)
		}
	},
	getters: {
		currentUserCoPolicyHolderPolicies: state => state.policies.filter(policy => policy.currentUserIsCoPolicyHolder).length
	},
	namespaced: true,
	state: policyState
}

export default policies
