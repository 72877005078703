import AxonIdentification from "./security/AxonIdentification"
import AxonAuthorization from "./security/AxonAuthorization"
import { loginUrl, customerEnvironmentExtId, ssoTokenUrl, jwtTokenUrl, introSpectUrl, sessionTimeout } from "./settings"
import { storageOptions } from "lib/storage/session"
import AuthorizedGateway from "lib/request/AuthorizedGateway"
import FetchGateway from "lib/request/FetchGateway"
import Session from "lib/security/Session"
import { Parameters, RequestMethod } from "lib/types/request"
import JwtAuthorization from "lib/security/authorization/JwtAuthorization"

// We need authorization for our backend and for Axon.
export const jwtAuthorization = new JwtAuthorization()
export const identification = new AxonIdentification(
	new FetchGateway(),
	jwtTokenUrl,
	jwtAuthorization,
	ssoTokenUrl,
	loginUrl,
	introSpectUrl,
	customerEnvironmentExtId
)
const axonAuthorization = new AxonAuthorization(storageOptions, identification)

const axonGateway = new AuthorizedGateway(axonAuthorization)
export const session = new Session(storageOptions, identification, axonAuthorization, sessionTimeout, true)

const gateway = new AuthorizedGateway(jwtAuthorization)

// tslint:disable-next-line: no-empty
const noop = () => {}

export const get = (input: string, data?: Parameters): Promise<Response> => axonGateway.request("GET", input, data)
export const getJson = async <T>(input: string, data?: Parameters): Promise<T> => (await get(input, data)).json()

export const getICS = (input: string, data?: Parameters): Promise<Response> => gateway.request("GET", input, data)
export const getJsonICS = async <T>(input: string, data?: Parameters): Promise<T> => (await getICS(input, data)).json()

export const postICS = (input: string, data?: Parameters): Promise<Response> => gateway.request("POST", input, data)
export const postJsonICS = async <T>(input: string, data?: Parameters): Promise<T> => (await postICS(input, data)).json()

export const getBlob = async (input: string, data?: Parameters): Promise<Blob> => (await get(input, data)).blob()

// Not all POSTs return json, so catch.
const request = async <T>(method: RequestMethod, input: string, data?: Parameters): Promise<T> =>
	(await axonGateway.request(method, input, data)).json().catch(noop)

const requestWithResponse = async <T>(method: RequestMethod, input: string, data?: Parameters): Promise<T> =>
(await axonGateway.request(method, input, data)).json()

export const post = async <T>(input: string, data?: Parameters): Promise<T> => request("POST", input, data)
export const postWithResponse = async <T>(input: string, data?: Parameters): Promise<T> => requestWithResponse("POST", input, data)

export const put = async <T>(input: string, data?: Parameters): Promise<T> => request("PUT", input, data)

export const fetchSsoCredentials = async (): Promise<string> => (await jwtAuthorization.request("POST", ssoTokenUrl)).text()

import { restApiRootUrl } from "./settings"
import { Policy } from "store/policies/types"
import { Attachment } from "store/communication/types"

export const policyScheduleUrl = (policy: Policy) => get(
	`${restApiRootUrl}/my-policies/${policy.policyIdentifier}/version/${policy.policyVersionExtId}/policy-information/pdf`
)

export const attachmentUrl = (attachment: Attachment) => get(
	`${restApiRootUrl}/my-communications/${attachment.communicationExtId}/communication-document/${attachment.externalIdentifier}/pdf`
)
