import { restApiRootUrl } from "api/settings"
import { getJson } from "api/request"
import { PoliciesResponse, BigPolicy, SmallPolicy } from "api/models/policy"

export default class PolicyController {
	async getOverview(): Promise<Array<SmallPolicy>> {
		const body: PoliciesResponse = await getJson(`${restApiRootUrl}/my-policies`)
		return body.policyOverviewValueObjects
	}

	async getOne(policyId: string): Promise<BigPolicy> {

		const parameters = {
			retrieveInsuranceDetails: true,
			retrieveClauseTexts: true
		}

		return getJson(`${restApiRootUrl}/my-policies/${policyId}`, parameters)
	}

	async getMany(): Promise<Array<BigPolicy>> {
		const policies = await this.getOverview()

		const structuredPolicies = policies.filter(policy => policy.externalIdentifier)
		const packagePolicies = policies.flatMap(packageItem => packageItem.subPolicyList)

		return Promise.all([...structuredPolicies, ...packagePolicies].map(policy => this.getOne(policy.externalIdentifier!)))
	}

	async getDocumentContent(documentExtId: string): Promise<any> {
		return getJson(`${restApiRootUrl}/my-policies/documents/${documentExtId}/show-content`)
	}
}
