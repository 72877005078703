import {RootState} from "../types"
import {CustomerState} from "./types"
import {Module} from "vuex"

const customerState: CustomerState = {
	loaded: false,
	communicationPreferenceLoaded: false,
	emailAddress: undefined,
	externalIdentifier: "",
	gender: "UNKNOWN",
	lastNameWithInsertion: undefined,
	nameForDisplay: "",
	onlineCommunication: undefined,
	communicationPreferenceChangeDate: undefined

}

const customer: Module<CustomerState, RootState> = {
	mutations: {
		hydrate(state, data: CustomerState) {
			Object.assign(state, data)
			state.loaded = true
		},
		setOnlineCommunication(state, data: boolean) {
			state.onlineCommunication = data
			state.communicationPreferenceLoaded = true
		},
		setCommunicationPreferenceChangeDate(state, data: Date) {
			state.communicationPreferenceChangeDate = data
			state.communicationPreferenceLoaded = true
		}
	},
	namespaced: true,
	state: customerState
}

export default customer
