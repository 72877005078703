import { ClauseCode } from "leven-shared-components/typings/interfaces"
import { publicSiteUrl } from "api/settings"

export interface FiscalTexts {
	clauses: Array<ClauseCode>
	introduction: string
	duringTerm: string
	atPayment: string
}

const KEW_URL = "https://www.belastingdienst.nl/wps/wcm/connect/nl/koopwoning/content/belasting-betalen-over-uitkering-kapitaalverzekering-eigen-woning"
const KAP_BHW_BV_URL = "https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/woning/uw_hypotheek_of_lening/sparen_voor_de_aflossing_van_uw_hypotheek/u_hebt_voor_1_januari_2001_een_kapitaalverzekering_afgesloten/u_zet_uw_kapitaalverzekering_niet_om/waarover-betaal-ik-belasting-bij-een-kapitaaluitkering/"
const KAP_BHW_URL1 = "https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/vermogen_en_aanmerkelijk_belang/vermogen/wat_zijn_uw_bezittingen_en_schulden/uw_bezittingen/niet_vrijgesteld_deel_kapitaalverzekeringen"
const KAP_BHW_URL2 = "https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/woning/uw_hypotheek_of_lening/sparen_voor_de_aflossing_van_uw_hypotheek/u_hebt_voor_1_januari_2001_een_kapitaalverzekering_afgesloten/u_zet_uw_kapitaalverzekering_niet_om/waarover-betaal-ik-belasting-bij-een-kapitaaluitkering/"
const LYF_BHW_URL = "https://www.belastingdienst.nl/wps/wcm/connect/nl/aftrek-en-kortingen/content/hulpmiddel-lijfrentepremie-2016-en-daarna"
const LIJFRENTE_LINK = `<a class="tealium-link-click" href="${publicSiteUrl}/lijfrente" target="_blank">asr.nl/lijfrente</a>`

const fiscalTexts: Array<FiscalTexts> = [
	{
		clauses: [ClauseCode.KEW],
		introduction: "De Kapitaalverzekering eigen woning (KEW) is gekoppeld aan uw eigenwoningschuld. De uitkering uit de KEW moet gebruikt worden om (een deel van) de eigenwoningschuld af te lossen. Voor een KEW gelden belastingvrijstellingen.",
		duringTerm: "Tijdens de looptijd valt de KEW in box 1. Dit betekent dat u de waarde niet hoeft op te geven bij de belastingaangifte.",
		atPayment: `Voor de uitkering uit een KEW geldt een belastingvrijstelling. Jaarlijks stelt de Belastingdienst de hoogte van de belastingvrijstelling vast. Als u een fiscale partner hebt, dan geldt een dubbele vrijstelling. Door de vrijstelling betaalt u minder of geen belasting.<br>Er gelden 3 voorwaarden:<ul><li>U gebruikt de hele uitkering om de eigenwoningschuld of de restschuld af te lossen.</li><li>U betaalt tijdens de looptijd ieder jaar premie.</li><li>De hoogste premie die u op jaarbasis betaalt is niet meer dan 10 keer de laagstbetaalde premie op jaarbasis.</li></ul> Als uw uitkering lager is dan de vrijstelling, dan betaalt u geen belasting over de uitkering. Is uw uitkering hoger dan de vrijstelling? Dan betaalt u belasting over het rendement in het deel van de uitkering dat boven de vrijstelling uitkomt. Meer informatie en voorbeeldberekeningen kunt u vinden op de <a href="${KEW_URL}" target="_blank">website van de Belastingdienst</a>.<br>Als de verzekering uitkeert bij overlijden dan is er mogelijk erfbelasting verschuldigd.`
	},
	{
		clauses: [ClauseCode.KAP_pBHW],
		introduction: "Voor uw kapitaalverzekering gelden belastingvrijstellingen. De premie die u betaalt voor de verzekering is niet aftrekbaar bij de aangifte voor de inkomstenbelasting.",
		duringTerm: "Tijdens de looptijd van de verzekering is de waarde vrijgesteld tot maximaal € 123.428. Is de waarde van uw verzekering hoger dan € 123.428? Dan betaalt u belasting over het verschil tussen de hogere waarde en € 123.428 via box 3. Als u een fiscale partner hebt dan geldt er een dubbele vrijstelling.<br>De vrijstelling geldt alleen als uw kapitaalverzekering aan de volgende voorwaarden voldoet:<ul><li>U hebt de looptijd van de verzekering na 15-9-1999 niet gewijzigd.</li><li>U hebt de hoogte van de premie of het verzekerde bedrag na 15-9-1999 niet gewijzigd.</li></ul>",
		atPayment: "Ook als de verzekering uitgekeerd wordt, hebt u recht op een vrijstelling. Als u voldoet aan de onderstaande voorwaarden is het rendement in de uitkering onbelast. Het rendement is het verschil tussen de uitkering en de betaalde premies. De voorwaarden zijn dat u minimaal 12 jaar aaneengesloten premie hebt betaald en tijdens de looptijd van de verzekering is de hoogste premie niet meer dan:<ul><li>5 keer de laagste premie met een looptijd tussen de 12 en 15 jaar;</li><li>10 keer de laagste premie met een looptijd tussen de 15 en 20 jaar;</li><li>15 keer de laagste premie met een looptijd tussen de 20 en 30 jaar;</li><li>20 keer de laagste premie met een looptijd langer dan 30 jaar.</ul>Als de verzekering uitkeert bij overlijden dan is er mogelijk erfbelasting verschuldigd."
	},
	{
		clauses: [ClauseCode.KAP_ERFRNT],
		introduction: "Voor uw erfrente verzekering gelden geen belastingvrijstellingen.",
		duringTerm: "De premie die u tijdens de looptijd van de verzekering betaalt is niet aftrekbaar bij de aangifte voor de inkomstenbelasting en de waarde van de verzekering wordt niet belast.",
		atPayment: "De waarde van de uitkerende erfrente moet de begunstigde opgeven in box 3 bij de aangifte voor de inkomstenbelasting."
	},
	{
		clauses: [ClauseCode.KAP_BOX1],
		introduction: "Voor uw kapitaalverzekering gelden geen belastingvrijstellingen.",
		duringTerm: "De premie die u tijdens de looptijd van de verzekering betaalt is niet aftrekbaar bij de aangifte voor de inkomstenbelasting en de waarde van de verzekering is vrijgesteld.",
		atPayment: "Als de uitkering uit de verzekering hoger is dan de betaalde premies dan betaalt u belasting over het verschil. Er gelden geen belastingvrijstellingen bij de uitkering.<br>Als de verzekering uitkeert bij overlijden dan is er mogelijk erfbelasting verschuldigd."
	},
	{
		clauses: [ClauseCode.KAP_BHW_BV],
		introduction: "Voor uw kapitaalverzekering brede herwaardering gelden belastingvrijstellingen. De premie die u betaalt voor de verzekering is niet aftrekbaar bij de aangifte voor de inkomstenbelasting.",
		duringTerm: "Uw kapitaalverzekering valt onder de brede herwaardering als deze voldoet aan de volgende voorwaarden:<ul><li>U hebt de looptijd van de verzekering na 15-9-1999 niet gewijzigd.</li><li>U hebt de hoogte van de premie of het verzekerde bedrag na 15-9-1999 niet gewijzigd.</li></ul>Tijdens de looptijd van de verzekering is de waarde vrijgesteld tot maximaal € 123.428. Is de waarde van de verzekering hoger dan € 123.428? Dan betaalt u belasting over het verschil tussen de hogere waarde en € 123.428 via box 3. Als u een fiscale partner hebt dan geldt een dubbele vrijstelling.<br>De premie die u betaalt voor de verzekering is niet aftrekbaar bij de aangifte voor de inkomstenbelasting.",
		atPayment: `Ook als de verzekering uitgekeerd wordt, hebt u recht op de vrijstelling van € 123.428,-. U krijgt vrijstelling als u voldoet aan de volgende voorwaarden:<ul><li>U hebt de looptijd van de verzekering na 15-9-1999 niet gewijzigd;</li><li>U hebt de hoogte van de premie of het verzekerde bedrag na 15-9-1999 niet gewijzigd;</li><li>U hebt ieder jaar premies betaald;</li><li>De hoogste premie op jaarbasis is niet meer dan 10 keer de laagste premie op jaarbasis.</li></ul>Als uw kapitaaluitkering lager is dan de vrijstelling, dan betaalt u geen belasting over de uitkering. Is de uitkering hoger dan de vrijstelling? Dan betaalt u belasting over het rendement in het deel van de uitkering dat boven de vrijstelling uitkomt. Meer informatie en een voorbeeldberekening kunt u vinden op de <a href="${KAP_BHW_BV_URL}" target="_blank">website van de Belastingdienst</a>.<br>Hebt u geen vrijstelling? Als uw uitkering hoger is dan de betaalde premies, betaalt u belasting over het verschil.<br>Als de verzekering uitkeert bij overlijden dan is er mogelijk erfbelasting verschuldigd.`
	},
	{
		clauses: [ClauseCode.KAP_BHW],
		introduction: `U moet deze kapitaalverzekering elk jaar opgeven bij de belastingaangifte. Deze verzekering is onderdeel van uw vermogen in box 3. De premie die u betaalt voor de verzekering is niet aftrekbaar bij de aangifte voor de inkomstenbelasting. Bij een overlijdensrisicoverzekering kan het zijn dat er een vrijstelling geldt tijdens de looptijd. U ontvangt dan geen brief met de fiscale waarde. Meer informatie kunt u vinden op de <a href="${KAP_BHW_URL1}" target="_blank">website van de Belastingdienst</a>.`,
		duringTerm: "U moet de waarde van de kapitaalverzekering bij uw belastingaangifte opgeven in box 3. Het bedrag dat u opgeeft, is de 'fiscale waarde'. Dit heet ook wel de “waarde in het economisch verkeer”. De Belastingdienst bepaalt hoe de waarde wordt berekend. We sturen u ieder jaar een brief met daarin de fiscale waarde van de verzekering per 1 januari van het jaar ervoor. We geven de fiscale waarde ook ieder jaar door aan de Belastingdienst.",
		atPayment: `Als de verzekering wordt uitgekeerd, hebt u recht op een vrijstelling van € 123.428. Als u een fiscale partner hebt dan geldt een dubbele vrijstelling. U krijgt een vrijstelling als u voldoet aan de volgende voorwaarden:<ul><li>U hebt ieder jaar premies betaald;</li><li>De hoogste premie op jaarbasis is niet meer dan 10 keer de laagste premie op jaarbasis.</li></ul> Als de kapitaaluitkering lager is dan de vrijstelling dan betaalt u geen belasting over de uitkering. Is de uitkering hoger dan de vrijstelling? Dan betaalt u belasting over het rendement in het deel van de uitkering dat boven de vrijstelling uitkomt. Meer informatie en een voorbeeldberekening kunt u vinden op de <a href="${KAP_BHW_URL2}" target="_blank">website van de Belastingdienst</a>.<br>Als de verzekering uitkeert bij overlijden dan is er mogelijk erfbelasting verschuldigd.`
	},
	{
		clauses: [ClauseCode.KAP_LYF_PU],
		introduction: "Deze verzekering moet u elk jaar opgeven bij de belastingaangifte. Deze verzekering is onderdeel van uw vermogen in box 3.",
		duringTerm: "U moet de waarde van de verzekering bij uw belastingaangifte opgeven in box 3. De Belastingdienst bepaalt hoe de waarde wordt berekend. We sturen u ieder jaar een brief met daarin de fiscale waarde van de verzekering per 1 januari van het jaar ervoor. We geven de waarde ook ieder jaar door aan de Belastingdienst.",
		atPayment: "Voor deze verzekering geldt dat de uitkering op de einddatum niet belast wordt. Alle lijfrentetermijnen uit de verzekering zijn onbelast."
	},
	{
		clauses: [ClauseCode.KAP_Box3],
		introduction: `Deze kapitaalverzekering moet u elk jaar opgeven bij de belastingaangifte. Deze verzekering is onderdeel van uw vermogen in box 3. De premie die u betaalt voor de verzekering is niet aftrekbaar bij de aangifte voor de inkomstenbelasting. Bij een overlijdensrisicoverzekering kan het zijn dat er een vrijstelling geldt tijdens de looptijd. U ontvangt dan geen brief met de fiscale waarde. Meer informatie kunt u vinden op de <a href="${KAP_BHW_URL1}" target="_blank">website van de Belastingdienst</a>.`,
		duringTerm: `U moet de waarde van de kapitaalverzekering bij uw belastingaangifte opgeven in box 3. Het bedrag dat u opgeeft, is de 'fiscale waarde'. Dit heet ook wel de “waarde in het economisch verkeer”. De Belastingdienst bepaalt hoe de waarde wordt berekend. We sturen u ieder jaar een brief met daarin de fiscale waarde van de verzekering per 1 januari van het jaar ervoor. We geven de fiscale waarde ook ieder jaar door aan de Belastingdienst.<br>Meer informatie kunt u vinden op de <a href="${KAP_BHW_URL1}" target="_blank">website van de Belastingdienst</a>.`,
		atPayment: "Voor deze verzekering geldt dat de uitkering op de einddatum niet belast wordt. Als de verzekering uitkeert bij overlijden dan is er mogelijk erfbelasting verschuldigd."
	},
	{
		clauses: [ClauseCode.LYF_INVKND],
		introduction: "U mag de premie of koopsom die u betaalt voor uw lijfrenteverzekering bij uw belastingaangifte in mindering brengen op uw inkomen. De waarde van de verzekering wordt tijdens de looptijd van de verzekering niet belast.",
		duringTerm: "U mag de premie die u betaalt voor de verzekering bij uw belastingaangifte in mindering brengen op uw inkomen. De premie die u betaalt voor de overlijdensuitkering is niet aftrekbaar. De waarde van de verzekering wordt tijdens de looptijd van de verzekering niet belast.",
		atPayment: "Als u de lijfrenteverzekering laat uitkeren, moet u dit bedrag bij de belastingaangifte opgeven als inkomen. U betaalt dan belasting over de uitkering. Alleen een levenslange verzekering op het leven van een meerderjarig invalide (klein) kind is fiscaal toegestaan."
	},
	{
		clauses: [ClauseCode.LYF_BHW, ClauseCode.LYF_IB2001, ClauseCode.ODGRSV_NW, ClauseCode.STW_IB2001],
		introduction: "De premies die u betaalt voor uw lijfrenteverzekering kunt u bij uw belastingaangifte in mindering brengen op uw inkomen. De uitkering(en) uit de verzekering zijn belast.",
		duringTerm: `De waarde van de verzekering wordt tijdens de looptijd van de verzekering niet belast. De premie die u nog betaalt voor de lijfrenteverzekering is aftrekbaar van uw inkomen. Hoeveel u maximaal kunt aftrekken is afhankelijk van uw jaarruimte. U kunt uw <a class="tealium-link-click" href="${LYF_BHW_URL}" target="_blank">jaarruimte berekenen</a> op de website van de Belastingdienst.`,
		atPayment: `Als u de lijfrenteverzekering periodiek laat uitkeren, moet u deze uitkeringen bij de belastingaangifte opgeven als inkomen. U betaalt dan belasting over de uitkeringen. De belastingregels voor uw lijfrenteverzekering vallen onder het nieuwe regime. U hebt meerdere mogelijkheden om het lijfrentekapitaal te laten uitkeren. Bekijk de lijfrentepagina op ${LIJFRENTE_LINK} voor meer informatie.`
	},
	{
		clauses: [ClauseCode.KAP_LYF, ClauseCode.STKG_WINST, ClauseCode.ODGRSV_FOR],
		introduction: "U kon de premie of koopsom die u betaalde voor uw lijfrenteverzekering bij uw belastingaangifte in mindering brengen op uw inkomen.",
		duringTerm: "De waarde van de verzekering wordt tijdens de looptijd van de verzekering niet belast.",
		atPayment: `Als u de lijfrenteverzekering periodiek laat uitkeren, moet u de uitkeringen bij de belastingaangifte opgeven als inkomen. U betaalt dan belasting over de uitkeringen. Lees meer over de verschillende mogelijkheden op de lijfrentepagina op ${LIJFRENTE_LINK}. De belastingregels voor uw lijfrenteverzekering vallen onder het oude regime.`
	},
	{
		clauses: [ClauseCode.GDHDRK_NW, ClauseCode.GDHDRK],
		introduction: "In het verleden hebt u een ontslagvergoeding ontvangen die u destijds belastingvrij hebt gestort voor een gouden handdrukverzekering.",
		duringTerm: "De waarde van de verzekering wordt tijdens de looptijd van de verzekering niet belast.",
		atPayment: `Zodra u een periodieke uitkering ontvangt uit de verzekering moet u belasting betalen over de uitkering. Informatie over de mogelijkheden op de einddatum van uw gouden handdrukverzekering vindt u op ${LIJFRENTE_LINK}.`
	},
	{
		clauses: [ClauseCode.LYF_NETTO],
		introduction: "",
		duringTerm: "De premie die u tijdens de looptijd van de verzekering betaalt is niet aftrekbaar bij de aangifte voor de inkomstenbelasting en de waarde van de verzekering is vrijgesteld.",
		atPayment: "Over de periodieke uitkeringen uit de verzekering betaalt u geen belasting."
	},
	{
		clauses: [ClauseCode.PW],
		introduction: "Uw pensioenverzekering valt onder de Pensioenwet.",
		duringTerm: "",
		atPayment: ""
	},
	{
		clauses: [ClauseCode.LYF_ALIMTT],
		introduction: "De periodieke uitkeringen uit de lijfrenteverzekering moet u bij de belastingaangifte opgeven als inkomen in box 1. U betaalt dan belasting over de uitkering.",
		duringTerm: "",
		atPayment: ""
	},
	{
		clauses: [ClauseCode.LEVENSLOOP],
		introduction: "De fiscale levensloopregeling eindigt per 1 november 2021. Dit is de fiscale einddatum van de levensloopregeling. Uiterlijk op die datum moet belasting worden betaald over het nog resterende levenslooptegoed (de waarde van de levensloopverzekering).<br>Het tegoed wordt eerder belast als u vóór 1 november 2021: <ul><li>de voor u geldende AOW-leeftijd bereikt of;</li><li>de ingangsdatum van uw ouderdomspensioen bereikt</li></ul> De fiscale levensloopregeling eindigt in deze gevallen dus eerder dan 1 november 2021. De opname van levenslooptegoed dat, tot de uiterlijke datum, via de werkgever wordt opgenomen, wordt belast via loonheffing door de werkgever.<br>De belastingheffing op de fiscale einddatum verloopt via loonheffing door a.s.r..",
		duringTerm: "",
		atPayment: ""
	}
]

export default fiscalTexts
