
























































import Vue from "vue"
import Component from "vue-class-component"
import { FileData, FilesToUpload } from "./typings/interfaces"
import { sumBy } from "lodash-es"
import { Prop, Watch } from "vue-property-decorator"
import InformationImage from "leven-shared-components/InformationImage.vue"
import InformationModal from "leven-shared-components/InformationModal.vue"
import eventBus from "lib/vue/eventBus"

interface Document {
	title: string
	documentType?: DocumentType
	helpText?: string
	infoComponent?: string
}

@Component({
	components: {
		InformationModal,
		InformationImage
	}
})
export default class MultipleFileUploader extends Vue {
	@Prop({type: Array, required: true}) requiredDocuments!: Array<Document>
	@Prop({type: Boolean, required: true}) submitted!: boolean
	@Prop({type: Boolean, required: true}) isSelfService!: boolean

	fileSize: string = ""
	fileBase64: string | ArrayBuffer | null = ""
	tempFileBase: string = ""
	fileList: Array<FileData> = []

	allowedTypes = ["pdf", "doc", "tiff", "tif", "jpg", "jpeg", "docx", "msg", "PDF", "DOC", "TIFF", "TIF", "JPG", "JPEG", "DOCX", "MSG"]
	maxFileTotal = 30000000

	hasInvalidFiles = false
	totalFilesToBig = false
	filesRequired = false
	uploadedRequiredFile = false
	uploadedRequiredFileError = "Upload de benodigde stukken a.u.b. als bijlage."
	checkedAllFiles = false
	checkedAllFilesError = "U heeft nog niet alle benodigde stukken aangevinkt."

	checkedDocuments: Array<string> = []
	explainedDocuments: Array<number> = []

	@Watch("requiredDocuments", { deep: true, immediate: true })
	checkRequiredDocuments() {
		this.filesRequired = this.requiredDocuments && this.requiredDocuments.length > 0
		this.validateFiles()
	}

	onDrop(e: DragEvent) {
		e.stopPropagation()
		e.preventDefault()

		if (e.dataTransfer && e.dataTransfer.files) {
			this.addFiles(e.dataTransfer.files)
		}
	}

	onChange(e: Event) {
		const input = e.target as HTMLInputElement
		if (input.files) {
			this.addFiles(input.files)
		}
	}

	addFiles(files: FileList) {
		/* tslint:disable:prefer-for-of */
		for (let i = 0; i < files.length; i++) {
			this.createFile(files[i])
		}
	}

	createFile(file: File) {
		if (this.validFile(file)) {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => (
				this.fileSize = this.formatBytes(Number(file.size), 0),
				this.tempFileBase = reader.result ? reader.result.toString().replace(/^data:.+;base64,/, "") : "",
				this.fileBase64 = this.tempFileBase,
				this.fileList.push({ file, fileBase64: this.fileBase64, valid: true })
			)
		} else {
			this.fileList.push({ file, fileBase64: "", valid: false })
		}
	}

	removeFile(file: FileData) {
		const index = this.fileList.indexOf(file, 0)
		if (index > -1) {
			this.fileList.splice(index, 1)
		}
	}

	emitInformationText(document: Document) {
		eventBus.emit("informationModal", "Toelichting", undefined, document.helpText, document.infoComponent)
	}

	@Watch("fileList", {deep: true, immediate: false})
	changedFileList() {
		this.validateFiles()
	}

	@Watch("checkedDocuments", {deep: true, immediate: false})
	changedCheckedDocuments() {
		this.validateFiles()
	}

	explainCheckedDocument(event: MouseEvent, document: Document, index: number) {
		if (document.title.includes("identiteitsbewijs")) {

			if (!this.explainedDocuments.includes(index)) {
				const infoText = `Voor de afhandeling van uw verzoek hebben wij een duidelijk leesbare kopie van een geldig identiteitsbewijs nodig. In verband met de privacywetgeving vragen wij u om de volgende gegevens af te schermen: het BSN (ook in de cijferreeks onderaan) en de pasfoto. Is het kopie identiteitsbewijs niet afgeschermd, dan nemen wij uw verzoek niet in behandeling.<br><br>Op de <a href="https://www.rijksoverheid.nl/onderwerpen/identiteitsfraude/vraag-en-antwoord/veilige-kopie-identiteitsbewijs" target="_blank">website</a> van de Rijksoverheid leest u hoe u een veilige kopie van het identiteitsbewijs maakt.`

				this.explainedDocuments.push(index)

				eventBus.emit("informationModal", "Graag even uw aandacht", document.title, infoText)
			}
		}
		this.$emit('requiredFileChecked', { event, textContent: document.documentType ?? 'document' })
	}

	validateFiles() {

		this.hasInvalidFiles = this.fileList.filter(file => !file.valid).length > 0

		const validFiles = this.fileList.filter(file => file.valid)

		const total = sumBy(validFiles, file => file.file.size)
		this.totalFilesToBig = total > this.maxFileTotal

		const filesToUpload: FilesToUpload = {
			listOfDocuments: []
		}

		this.uploadedRequiredFile = this.filesRequired ? validFiles.length > 0 : true
		this.checkedAllFiles = this.filesRequired ? this.requiredDocuments.length === this.checkedDocuments.length : true

		const filesValid = !this.hasInvalidFiles && !this.totalFilesToBig && this.uploadedRequiredFile && this.checkedAllFiles

		for (const file of this.fileList) {
			filesToUpload.listOfDocuments.push({ fileName: file.file.name, contents: file.fileBase64 })
		}

		this.$emit("changedFiles", { filesToUpload, filesValid, checkedDocuments: this.checkedDocuments })
	}

	emitError(errorMessage: string) {
		this.$emit('error', { componentUid: 'benodigde stukken', errorMessage })
	}

	validFile(file: File) {
		const fileExtension = file.name.split(".").pop()
		return this.allowedTypes.includes(fileExtension!)
	}

	formatBytes(bytes: number, decimals = 2) {
		if (bytes === 0) { return "0 Bytes" }

		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

		const i = Math.floor(Math.log(bytes) / Math.log(k))

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}

	fileInputClicked() {
		this.resetFileInput()
		this.$emit('focus', { label: "Upload bijlage", isRequired: this.filesRequired })
	}

	resetFileInput() {
		const fileInput = this.$refs.fileInput as HTMLInputElement
		if (fileInput) {
		 	fileInput.value = ""
		}
	}

	@Watch("submitted")
	@Watch("uploadedRequiredFile")
	@Watch("checkedAllFiles")
	checkErrors() {
		if (this.submitted) {
			if (!this.uploadedRequiredFile) {
				this.emitError(this.uploadedRequiredFileError)
			}
			if (!this.checkedAllFiles) {
				this.emitError(this.checkedAllFilesError)
			}
		}
	}
}
