import RequestError from "lib/request/RequestError"
import {Parameters} from "lib/types/request"
import ProviderIdentification from "./ProviderIdentification"
import RequestStrategy from "lib/request/RequestStrategy"
import {AuthorizationStrategy, JwtToken} from "lib/types/security"
import {AxonToken} from "api/types"
import {storageOptions} from "lib/storage/session"

const axonKey = "Axon"
export default class AxonIdentification extends ProviderIdentification {
	private userIdentifier: string | undefined

	constructor(
		gateway: RequestStrategy,
		tokenEndpoint: string, // Endpoint to swap the iWelcome token for a JWT token.
		private readonly ssoAuthorization: AuthorizationStrategy, // Authorization for the ssoCredentials call.
		private readonly ssoCredentialsEndpoint: string, // Endpoint for Axon ssoCredentials.
		private readonly loginEndpoint: string, // Endpoint for Axon login.
		introSpectEndpoint: string, // Endpoint to check iWelcome session.
		private readonly customerEnvironmentExtId: string
	) {
		super(gateway, tokenEndpoint, introSpectEndpoint)

		const state = storageOptions.storage.retrieve(axonKey)
		if (state) {
			ssoAuthorization.authorize(state.token)
			if (state.identified) {
				this.identifier = state.identifier
			}
		}

	}

	get identifier() {
		return this.userIdentifier
	}

	set identifier(identifier: string | undefined) {
		this.userIdentifier = identifier
	}

	get isIdentified(): boolean {
		return !!this.userIdentifier
	}

	async unidentify(): Promise<boolean> {
		this.userIdentifier = undefined
		return super.unidentify()
	}

	async axonToken(token: JwtToken): Promise<AxonToken> {
		if (!this.ssoAuthorization.authorize(token)) {
			throw new Error("Invalid token")
		}
		return this.fetchSsoCredentials(token)
	}

	async fetchSsoCredentials(token?: JwtToken): Promise<AxonToken> {
		const jwtToken: JwtToken = token || storageOptions.storage.retrieve(axonKey).token
		if (!jwtToken) {
			throw new Error("Invalid token")
		}
		// Fetch the sso token from our service.
		const ssoResponse = await this.ssoAuthorization.request("POST", this.ssoCredentialsEndpoint)
		if (!ssoResponse.ok) {
			throw new RequestError(ssoResponse)
		}
		const ssoCredentials = await ssoResponse.text()
		const loginResponse = await this.post(this.loginEndpoint, "application/x-www-form-urlencoded", {
			ssoCredentials,
			customerEnvironmentExtId: this.customerEnvironmentExtId
		})
		const authorization = await loginResponse.json()
		this.userIdentifier = authorization.tokenData.userIdentifier

		storageOptions.storage.store(axonKey, {
			token: jwtToken,
			identifier: this.userIdentifier
		})

		return {
			xsrfToken: authorization.tokenData.xsrfToken,
			clientIdentifier: "test",
			customerExtId: authorization.customerExtId
		}
	}

	protected async extractToken(response: Response): Promise<AxonToken> {
		// The provider identification superclass has received the token from iWelcome and has called our token endpoint to get a token of our own.
		const token = await super.extractToken(response)
		return this.axonToken(token)
	}

	async post(input: string, contentType: string, data?: Parameters): Promise<Response> {
		let body: URLSearchParams | undefined
		if (data) {
			body = new URLSearchParams()
			for (const [key, value] of Object.entries(data)) {
				body.append(key, value)
			}
		}

		const response = await fetch(input, {
			method: "POST",
			mode: "cors",
			credentials: "include",
			headers: {
				"Content-Type": contentType
			},
			body
		})

		if (!response.ok) {
			throw new RequestError(response)
		}

		return response
	}

}
