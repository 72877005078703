import {AllocationKeyList, Policy, Counselor, Claim, ClaimDocument, Fund, UnfinishedClaim,
	PolicyOnClaim, ClaimStatusStep, Premium, Beneficiary, BeneficiaryKind} from "./types"
import {convertCoverages} from "./coverages"
import {ConversionMap, ConversionFunction} from "lib/types/import"
import {one, many} from "lib/import/extract"
import {alphanumeric, numeric, bool} from "lib/import/convert"
import {isNull, isString, isUndefined} from "lodash-es"
import {epochDate, productCode, cleanFrequency, defineClause, status} from "leven-shared-components/typings/convert"

const address: ConversionFunction<ReadonlyArray<string>> = value => isString(value) ? value.split("\n") : undefined

const containsSpace = (value: string) => /\s/.test(value) ? true : false
const cleanPolicyIdentifier = (value: string) => value.split(" ")[0]

const policyPackageNumberClean: ConversionFunction<Claim["policies"]> = claimPolicies => claimPolicies ? claimPolicies.map(
	(claimPolicy: any) => containsSpace(claimPolicy.policyIdentifier) ?
	cleanPolicyIdentifier(claimPolicy.policyIdentifier) : claimPolicy.policyIdentifier
) : null

const definePledgedPolicy = (pledgeHolderList: Array<any> | null) => pledgeHolderList?.filter(
	pledgeHolder => pledgeHolder.pledgeHolder.assignmentStatus === "Definitief"
).map(
	({ pledgeHolder }) => ({
		name: pledgeHolder.lastName,
		externalLendingNumber: pledgeHolder.mortgageDetailsVO?.externalLendingNumber,
		interestPercentage: pledgeHolder.mortgageDetailsVO?.interestPercentage
	})
) || []

const beneficiaryKind: ConversionFunction<BeneficiaryKind> = value => value
const decimal: ConversionFunction<number> = value => !isNull(value) && !isUndefined(value) ? Number(value.toFixed(2)) : undefined

const defineEmployerId = (policyInstanceFieldList: Array<any> | null) => policyInstanceFieldList?.find(
	field => field.externalIdentifier === "LAL_RELATIENUMMER_WERKGEVER"
)?.value.value ?? null

const premiumIncreaseNotAllowed = (policyInstanceFieldList: Array<any> | null) => ["no", "nee"].includes(policyInstanceFieldList?.find(
	field => field.externalIdentifier === "MUT_VERHOGEN"
)?.value.value.toLowerCase()) ? true : false

const extensionNotAllowed = (policyInstanceFieldList: Array<any> | null) => ["no", "nee"].includes(policyInstanceFieldList?.find(
	field => field.externalIdentifier === "MUT_VERLENGEN"
)?.value.value.toLowerCase()) ? true : false

const hasWisselDatumClause = (policyInstanceFieldList: Array<any> | null) => policyInstanceFieldList?.find(
	field => field.externalIdentifier === "WISSELDATUM"
)?.value.value ? true : false

const isPolicyWithSavingsValue = (allocationKeyList: Array<AllocationKeyList> | null): boolean => allocationKeyList?.find(
	allocationKey => allocationKey.allocationFactorList.find(
		fundKey => fundKey.allocationKeyFundKey.includes("SPH01") || fundKey.allocationKeyFundKey.includes("SPH03")
	)
) ? true : false

const defineSourceSysteem = (policyInstanceFieldList: Array<any> | null) => policyInstanceFieldList?.find(
	field => field.externalIdentifier === "BRONSYSTEEM"
)?.value.value ?? null

const defineCostAndProvisionVariant = (policyInstanceFieldList: Array<any> | null) => policyInstanceFieldList?.find(
	field => field.labelText === "Kosten en provisievariant"
)?.value.value ?? null

const definePolicyHolderMortalityBase = (policyInstanceObjectList: Array<any> | null) => policyInstanceObjectList?.find(
	policyInstanceObject => policyInstanceObject.policyInstanceObjectFieldList[0].labelText === "Sterftegrondslag verzekerde"
)?.policyInstanceObjectFieldList[0].value.value ?? null

const defineCoPolicyHolderMortalityBase = (policyInstanceObjectList: Array<any> | null) => policyInstanceObjectList?.find(
	policyInstanceObject => policyInstanceObject.policyInstanceObjectFieldList[0].labelText === "Sterftegrondslag medeverzekerde"
)?.policyInstanceObjectFieldList[0].value.value ?? null

const required = true
const optional = false

const counselor: ConversionMap<Counselor> = {
	name: ["name", alphanumeric, required],
	phoneNumber: ["telephoneNumber", alphanumeric, optional],
	emailAddress: ["emailAddress", alphanumeric, optional],
	address: ["formattedNameAndAddress", address, required]
}

const fund: ConversionMap<Fund> = {
	identifier: ["productFund.externalIdentifier", alphanumeric, required],
	name: ["productFund.name", alphanumeric, required],
	units: ["units", numeric, required],
	balance: ["balance", decimal, required],
	guaranteeValue: ["guaranteeValue", decimal, optional]
}

const premium: ConversionMap<Premium> = {
	amount: ["amount", numeric, required],
	paymentFrequency: ["paymentFrequency", cleanFrequency, optional],
	dateStart: ["dateStart", epochDate, optional],
	dateEnd: ["dateEnd", epochDate, optional]
}

const beneficiary: ConversionMap<Beneficiary> = {
	sequence: ["sequenceNr", numeric, required],
	name: ["beneficiaryName", alphanumeric, optional],
	type: ["beneficiaryType", alphanumeric, optional],
	kind: ["beneficiaryKind", beneficiaryKind, required],
	percentage: ["percentage", numeric, required],
	customerExternalId: ["customerExtId", alphanumeric, optional]
}

const policy: ConversionMap<Policy> = {
	policyIdentifier: ["externalIdentifier", alphanumeric, required],
	policyVersionExtId: ["policyVersionExtId", alphanumeric, required],
	packageNumber: ["myPackageDataValueObject.policyExtId", alphanumeric, optional],
	product: ["productName", alphanumeric, required],
	productId: ["productExtId", productCode, required],
	status: ["policyStatusName", status, required],
	statusIdentifier: ["policyStatusIdentifier", alphanumeric, required],
	startDate: ["dateStart", epochDate, required],
	startDatePolicyVersion: ["dateStartPolicyVersion", epochDate, required],
	endDate: ["dateEnd", epochDate , optional],
	counselor: ["myIntermediaryValueObject", one(counselor), required],
	targetCapital: ["targetCapital", numeric, optional],
	maturityBenefit: ["maturityBenefit", numeric, optional],
	calculatedMaturityBenefit: ["calculatedMaturityBenefit", numeric, optional],
	interestPercentage: ["interestPercentage", numeric, optional],
	funds: ["fundOverviewValueObject.fundAccountList", many(fund), required],
	claim: ["nonexistent", () => undefined, optional],
	fixedRateFundAccountTotal: ["fundOverviewValueObject.fixedRateFundAccountTotal", decimal, optional],
	packagePolicy: ["isPartOfPackage", bool, required],
	clause: ["myPolicyDetailsValueObject.conditionsAndClauses.clauseList[0].name", defineClause, optional],
	premiums: ["myPolicyDetailsValueObject.premiumList", many(premium), optional],
	policyHolderExtId: ["policyHolderExtId", alphanumeric, required],
	policyHolderFullName: ["policyHolderFullName", alphanumeric, required],
	coPolicyHolderExtId: ["coPolicyHolderExtId", alphanumeric, optional],
	coPolicyHolderFullName: ["coPolicyHolderFullName", alphanumeric, optional],
	currentUserIsCoPolicyHolder: ["nonexistent", () => false, required],
	insuredExtId: ["insuredExtId", alphanumeric, required],
	insuredFullName: ["insuredFullName", alphanumeric, required],
	insuredDateOfBirth: ["insured.dateOfBirth", epochDate, required],
	coInsuredExtId: ["coInsuredExtId", alphanumeric, optional],
	coInsuredFullName: ["coInsuredFullName", alphanumeric, optional],
	coInsuredDateOfBirth: ["coInsured.dateOfBirth", epochDate, optional],
	beneficiaries: ["myPolicyDetailsValueObject.deviantBeneficiariesList", many(beneficiary), required],
	coverages: ["myPolicyDetailsValueObject.policyInstanceCoverageList", convertCoverages, optional],
	pledgeHolders: ["myPolicyDetailsValueObject.policyInstancePledgeHolderList", definePledgedPolicy, required],
	employerId: ["myPolicyDetailsValueObject.policyInstanceFieldList", defineEmployerId, optional],
	premiumIncreaseNotAllowed: ["myPolicyDetailsValueObject.policyInstanceFieldList", premiumIncreaseNotAllowed, required],
	extensionNotAllowed: ["myPolicyDetailsValueObject.policyInstanceFieldList", extensionNotAllowed, required],
	hasWisselDatumClause: ["myPolicyDetailsValueObject.policyInstanceFieldList", hasWisselDatumClause, required],
	isPolicyWithSavingsValue: ["fundOverviewValueObject.allocationKeyList", isPolicyWithSavingsValue, required],
	sourceSystem: ["myPolicyDetailsValueObject.policyInstanceFieldList", defineSourceSysteem, optional],
	costAndProvisionVariant: ["myPolicyDetailsValueObject.policyInstanceFieldList", defineCostAndProvisionVariant, optional],
	policyHolderMortalityBase: ["myPolicyDetailsValueObject.policyInstanceObjectList", definePolicyHolderMortalityBase, optional],
	coPolicyHolderMortalityBase: ["myPolicyDetailsValueObject.policyInstanceObjectList", defineCoPolicyHolderMortalityBase, optional]
}

export const convertPolicies = many(policy)
export const convertPolicy = one(policy)

const policyOnClaim: ConversionMap<PolicyOnClaim> = {
	productName: ["productName", alphanumeric, required],
	policyIdentifier: ["policyIdentifier", alphanumeric, required],
	packagePolicyIdentifier: ["packagePolicyIdentifier", alphanumeric, optional],
	damageCause: ["damageCause", alphanumeric, required]
}

const claim: ConversionMap<Claim> = {
	claimNumber: ["externalIdentifier", alphanumeric, required],
	policies: ["listOfMyPolicies", policyPackageNumberClean, optional],
	listOfMyPolicies: ["listOfMyPolicies", many(policyOnClaim), required],
	damageDate: ["dateDamage", epochDate, required],
	statusId: ["claimStatusId", numeric, required],
	mustBeCompleted: ["mustBeCompleted", bool, required],
	identifierForCompletion: ["identifierForCompletion", alphanumeric, optional],
	requestedInformation: ["", () => [], required],
	externalStatusName: ["externalStatusName", alphanumeric, optional],
	hasBeenCancelled: ["hasBeenCancelled", bool, optional],
	hasOutstandingRequestedInformation: ["hasOutstandingRequestedInformation", bool, optional]
}

export const convertClaims = many(claim)
export const convertClaim = one(claim)

const unfinishedClaim: ConversionMap<UnfinishedClaim> = {
	nasmId: ["claimIncompleteDialogueOverview.nasmId", alphanumeric, required],
	damageDate: ["claimIncompleteDialogueOverview.dateDamage", epochDate, required],
	policyIdentifier: ["claimIncompleteDialogueOverview.policyIdentifier", alphanumeric, required],
	externalIdentifier: ["claimIncompleteDialogueOverview.externalIdentifier", alphanumeric, required]
}

const unfinishedClaims = many(unfinishedClaim)
export const convertUnfinishedClaims = (value: any) => value && value.rows ? unfinishedClaims(value.rows) : undefined

const claimStatusStep: ConversionMap<ClaimStatusStep> = {
	stepStatusExternalIdentifier: ["stepStatusExternalIdentifier", alphanumeric, required],
	externalStatusName: ["externalStatusName", alphanumeric, required],
	claimExternalStatusExternalIdentifier: ["claimExternalStatusExternalIdentifier", alphanumeric, required],
	actionCode: ["actionCode", alphanumeric, required]
}

export const convertStatusOverview = many(claimStatusStep)

const claimDocument: ConversionMap<ClaimDocument> = {
	identifier: ["externalIdentifier", alphanumeric, required],
	name: ["description", alphanumeric, optional],
	statusName: ["documentStatus", alphanumeric, optional],
	statusId: ["documentStatusExtId", alphanumeric, optional],
	typeExternalIdentifier: ["typeExternalIdentifier", alphanumeric, optional],
	changeToken: ["changeToken", alphanumeric, optional]
}

const claimDocuments = many(claimDocument)
export const convertDocuments = (value: any) => value && value.searchResult.rows ? claimDocuments(value.searchResult.rows) : undefined
