















import Vue from "vue"
import Component from "vue-class-component"
import eventBus from "lib/vue/eventBus"
import Modal from "components/modal/Modal.vue"
import InformationModalBankStatement from "leven-shared-components/InformationModalBankStatement.vue"

@Component({
	components: {
		InformationModalBankStatement,
		Modal
	}
})
export default class InformationModal extends Vue {
	event = "informationModal"
	modalHeader: any | null = null
	modalTitle: any | null = null
	modalText: any | null = null
	modalComponent: any | null = null

	InformationModalBankStatement = InformationModalBankStatement

	beforeMount() {
		eventBus.on(this.event, (header?: any, title?: any, text?: any, component?: any) => {
			this.modalHeader = header
			this.modalTitle = title
			this.modalText = text
			this.modalComponent = component
		})
	}
}
