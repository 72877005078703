






















































































































































































































































import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { FormType, Application, MutationType, RequestType, OfferFase } from "./typings/enums"
import { ProductCode } from "./typings/products"
import { PolicyData,
			IntermediaryContactDetails,
			CustomerContactDetails,
			FormContentTexts,
			FilesToUpload,
			Mutation, MutationPostData, RequiredDocument, FormTexts, ContactFormSubject, 
			DocumentType} from "./typings/interfaces"
import { TaskType } from "./typings/tasktypes"
import { getContactFormSubjects, getMutation } from "./data/formcontent"
import helpText from "./data/documenthelptexts"
import { mutationPostData as initialMutationPostData } from "./data/defaults"
import RequestLoader from "leven-shared-components/RequestLoader.vue"
import MultipleFileUploader from "leven-shared-components/MultipleFileUploader.vue"
import MessageBox from "leven-shared-components/MessageBox.vue"
import ProtectedButton from "leven-shared-components/ProtectedButton.vue"
import IntermediaryContact from "leven-shared-components/IntermediaryContact.vue"
import CustomerContact from "leven-shared-components/CustomerContact.vue"
import ExtraDeposit from "leven-shared-components/mutations/ExtraDeposit.vue"
import DeleteInsured from "leven-shared-components/mutations/DeleteInsured.vue"
import CollectionViaAsr from "leven-shared-components/mutations/CollectionViaAsr.vue"
import PaymentTerm from "leven-shared-components/mutations/PaymentTerm.vue"
import PostPoneEndDate from "leven-shared-components/mutations/PostPoneEndDate.vue"
import MortgageRate from "leven-shared-components/mutations/MortgageRate.vue"
import PremiumAmount from "leven-shared-components/mutations/PremiumAmount.vue"
import { coPolicyHolderIsNonSmoker, policyHolderIsNonSmoker, policyHolderIsSmoker, showNonSmokerDiscountMutation } from "leven-shared-components/typings/sharedrules"
import { isString } from "lodash-es"

type Callback = (event: Event) => void
type CallbackTrigger = (toTrigger: string, event: Event) => void

interface Document {
	title: string
	documentType?: DocumentType
	helpText?: string
	infoComponent?: string
}

@Component({
	components: {
		RequestLoader,
		MultipleFileUploader,
		MessageBox,
		ProtectedButton,
		IntermediaryContact,
		CustomerContact,
		ExtraDeposit,
		DeleteInsured,
		CollectionViaAsr,
		PaymentTerm,
		PostPoneEndDate,
		MortgageRate,
		PremiumAmount
	}
})
export default class MutationFormHolder extends Vue {
	@Prop({type: Object, required: true}) selectedMutation!: Mutation
	@Prop({type: Boolean, required: true}) requestSend!: boolean
	@Prop({type: Boolean, required: true}) dataLoaded!: boolean
	@Prop({type: Object, required: true}) formContentTexts!: FormContentTexts
	@Prop({type: String, required: true}) formtype!: FormType
	@Prop({type: String, required: true}) application!: Application
	@Prop({type: Object, required: false}) intermediaryContactDetails!: IntermediaryContactDetails
	@Prop({type: Object, required: false}) customerContactDetails!: CustomerContactDetails
	@Prop({type: Object, required: true}) policyData!: PolicyData
	@Prop({type: Array, required: false}) policyList!: Array<any>
	@Prop({type: Boolean, required: false, default: false}) axonImpersonation!: boolean
	@Prop({type: String, required: false}) apiErrorMessage!: string | null

	MutationType = MutationType
	RequestType = RequestType
	FormType = FormType
	OfferFase = OfferFase

	submitted = false
	showUploadForm = false
	offerFase: OfferFase | null = null

	offerFaseTexts = {
		[OfferFase.REQUEST]: "Vraag offerte aan",
		[OfferFase.APPROVED]: "Upload ondertekende offerte"
	}

	subject = ""
	message = ""
	mutation = ""
	contactDetailsString = ""
	extrafield?: string = ""

	selectedPolicyNumber = ""

	filesToUpload: FilesToUpload = {
		listOfDocuments: []
	}
	requiredDocuments: Array<Document> = []
	checkedDocuments: Array<string> = []

	filesValid = true
	messageValid = true
	mutationValid = true
	contactDetailsValid = false
	showProtectedText = false
	samePremiumContributor: boolean | null = null

	showMessagePremiumIncreaseNotAllowed = false
	showMessageExtensionNotAllowed = false
	showMessageHasWisselDatumClause = false
	showMessageNonSmokerDiscountAlreadyApplied = false
	showMessageNonSmokerDiscountNotApplicable = false

	contactFormSubjects: Array<ContactFormSubject> | null = null

	applicationTexts = {
		offerAgreed: "",
		preset: ""
	}

	get isValidSubject() {
		return this.subject && this.subject !== ""
	}

	get isSelfService() {
		return this.application === Application.SELFSERVICE
	}

	get formTexts() {

		const texts: FormTexts = {
			title: "",
			intro: "",
			introList: [],
			step1: this.selectedMutation[this.application].step1 || "",
			paperForm: this.selectedMutation[this.application].paperForm || undefined,
			paperFormUploadOnly: this.selectedMutation[this.application].paperFormUploadOnly ?? false
		}

		if (this.offerFase) {
			texts.title = `${this.selectedMutation.title} - ${this.offerFaseTexts[this.offerFase]}`
			texts.intro = this.selectedMutation[this.application][this.offerFase]!.intro
			texts.introList = this.selectedMutation[this.application][this.offerFase]!.introList
		} else {
			texts.title = this.selectedMutation.title
			texts.intro = this.selectedMutation[this.application].intro
			texts.introList = this.selectedMutation[this.application]!.introList
		}

		return texts
	}

	get showForm() {
		return this.selectedMutation.requestType === RequestType.DIGITAL
		|| (this.selectedMutation.requestType === RequestType.PDFUPLOAD && this.showUploadForm)
		|| this.offerFase
		|| this.selectedMutation.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT
	}

	get selectedMutationBlocked() {
		this.showMessagePremiumIncreaseNotAllowed = false
		this.showMessageExtensionNotAllowed = false
		this.showMessageHasWisselDatumClause = false
		this.showMessageNonSmokerDiscountAlreadyApplied = false
		this.showMessageNonSmokerDiscountNotApplicable = false

		if (this.selectedMutation.mutationType === MutationType.EXTRA_DEPOSIT &&
			this.policyData.productCode !== ProductCode.SPH && this.policyData.premiumIncreaseNotAllowed) {
			this.showMessagePremiumIncreaseNotAllowed = true
		}

		if (this.selectedMutation.mutationType === MutationType.POSTPONE_ENDDATE) {

			if (this.policyData.extensionNotAllowed) {
				this.showMessageExtensionNotAllowed = true
			}

			if (this.policyData.hasWisselDatumClause) {
				this.showMessageHasWisselDatumClause = true
			}
		}

		if (this.selectedMutation.mutationType === MutationType.NON_SMOKER_DISCOUNT) {

			if (!showNonSmokerDiscountMutation(this.policyData)) {

				if (policyHolderIsNonSmoker(this.policyData) &&
					(this.policyData.coInsured.mortalityBase === undefined || coPolicyHolderIsNonSmoker(this.policyData))) {
					this.showMessageNonSmokerDiscountAlreadyApplied = true
				}

				if (!policyHolderIsSmoker(this.policyData) && !policyHolderIsNonSmoker(this.policyData)) {
					this.showMessageNonSmokerDiscountNotApplicable = true
				}
			}
		}

		return this.showMessagePremiumIncreaseNotAllowed ||
			this.showMessageExtensionNotAllowed ||
			this.showMessageHasWisselDatumClause ||
			this.showMessageNonSmokerDiscountAlreadyApplied ||
			this.showMessageNonSmokerDiscountNotApplicable
	}

	@Watch("policyList", { immediate: true })
	setDefaultPolicy() {
		if (this.policyList && this.policyList.length) {
			this.selectedPolicyNumber = this.policyList[0].policyIdentifier
		}
	}

	changeOfferFase(event: MouseEvent, offerFase: OfferFase) {
		this.offerFase = offerFase
		this.tealiumPageViewEvent(event, offerFase)
	}

	downloadFormButtonClicked(event: MouseEvent) {
		this.$emit("downloadFormButtonClicked", event)
	}

	downloadFormLinkClicked(event: MouseEvent) {
		this.$emit("downloadFormLinkClicked", event)
	}

	visitUploadForm(event: MouseEvent) {
		this.showUploadForm = true
		this.$emit("uploadFormClicked", event)
		this.tealiumPageViewEvent(event)
	}

	@Watch("offerFase")
	changedOfferFase() {
		if (this.offerFase && this.offerFase === OfferFase.REQUEST) {
			this.requiredDocuments = []
		} else {
			if (this.selectedMutation.requiredDocuments) {
				this.populateRequiredDocuments(this.selectedMutation.requiredDocuments)
			}
		}
	}

	populateRequiredDocuments(requiredDocuments: Array<RequiredDocument>) {

		const documents: Array<Document> = []

		if (this.policyData.coPolicyHolderExists) {
			if (this.isSelfService) {
				if (this.policyData.isPolicyHolderLoggedIn) {
					requiredDocuments = requiredDocuments.filter(document => document.documentCondition !== "COPOLICYHOLDER_LOGGEDIN")
				} else {
					requiredDocuments = requiredDocuments.filter(document => document.documentCondition !== "POLICYHOLDER_LOGGEDIN")
				}
			}
		} else {
			requiredDocuments = requiredDocuments.filter(document => document.documentCondition !== "POLICYHOLDER_LOGGEDIN")

			if (this.isSelfService) {
				requiredDocuments = requiredDocuments.filter(document => document.documentCondition !== "COPOLICYHOLDER_LOGGEDIN")
			}
		}

		if (this.isSelfService) {
			requiredDocuments = requiredDocuments.filter(document => document.documentEnvironment !== Application.EXTRANET)
		} else {
			requiredDocuments = requiredDocuments.filter(document => document.documentEnvironment !== Application.SELFSERVICE)
		}

		for (const requiredDocument of requiredDocuments) {

			const document: Document = {
				title: "",
				documentType: requiredDocument.documentType,
				helpText: undefined,
				infoComponent: undefined
			}

			if (this.policyData.coPolicyHolderExists) {
				if (requiredDocument.documentTitleMultiple) {
					document.title = requiredDocument.documentTitleMultiple
				} else {
					document.title = requiredDocument.documentTitle.replace("{policyHolderName}", this.policyData.policyHolder.fullName!)
										.replace("{coPolicyHolderName}", this.policyData.coPolicyHolder.fullName!)
				}
			} else {
				document.title = requiredDocument.documentTitle.replace("{policyHolderName}", this.policyData.policyHolder.fullName!)
			}

			if (document.title !== "") {
				document.helpText = requiredDocument.helpTextId ? helpText(requiredDocument.helpTextId)?.text : undefined
				document.infoComponent = requiredDocument.helpTextId ? helpText(requiredDocument.helpTextId)?.infoComponent : undefined
				documents.push(document)
			}
		}

		this.requiredDocuments = documents
	}

	@Watch("selectedMutation", {deep: true, immediate: true})
	checkRequiredDocuments() {
		if (this.selectedMutation) {
			const requiredDocuments = this.selectedMutation.requiredDocuments

			if (requiredDocuments && this.selectedMutation.requestType !== RequestType.OFFER) {
				this.populateRequiredDocuments(requiredDocuments)
			}
		}
	}

	retrieveParamOrCookie(searchParams: URLSearchParams, queryKey: string, sessionKey: string): string {
		const queryItem = searchParams.get(queryKey)
		if(queryItem) {
			return queryItem
		} else {
			const sessionItem = sessionStorage.getItem(sessionKey)
			if(sessionItem) {
				sessionStorage.removeItem(sessionKey)
				return sessionItem
			}
		}
		return ""
	}

	created() {
		const params = new URLSearchParams(window.location.search.substring(1))
		
		this.selectedPolicyNumber = this.retrieveParamOrCookie(params, "policyNumber", "mutation_policyNumber")
		this.subject = this.retrieveParamOrCookie(params, "subject", "mutation_subject")
	}

	async mounted() {
		await Vue.nextTick()
		this.contactFormSubjects = getContactFormSubjects(this.application)

		this.createListener("#linkToOtherMutation", this.otherMutation)
		this.createListener("#linkToContactForm", this.goToContactForm)
		this.createListener("#linkToWidget", this.toWidget)
		this.createListener("#linkToPremiumContributorForm", (event: Event) => this.downloadFormButtonClicked(event as MouseEvent))

		if (this.isSelfService) {
			this.createListener("#linkToProfilePage", this.toProfileTab)
			this.createListener("#linkToChangeLogin", this.triggerEmit, "goToChangeLogin")
			this.createListener("#linkToRelativesDesk", this.triggerEmit, "goToRelativesDesk")
			this.createListener("#mailBankAccountForm", this.emailBankAccountForm)

			this.applicationTexts.offerAgreed = "Gaat u akkoord?"
			this.applicationTexts.preset = "uw"
		} else {
			this.applicationTexts.offerAgreed = "Akkoord?"
			this.applicationTexts.preset = "het"
		}
	}

	createListener(linkID: string, callBack: Callback | CallbackTrigger, toTrigger?: string) {
		const link = this.$refs.introText ? (this.$refs.introText as HTMLElement).querySelector(linkID) as HTMLElement : undefined

		if (toTrigger) {
			link?.addEventListener("click", callBack.bind(this, toTrigger), false)
		} else {
			link?.addEventListener("click", callBack.bind(this), false)
		}

	}

	tealiumPageViewEvent(event: MouseEvent | string, offerFase?: OfferFase) {
		if (this.isSelfService) {
			let subTabName = isString(event) ? event : (event.target! as Element).textContent
			if (offerFase === OfferFase.APPROVED) {
				subTabName = 'Upload offerte'
			}
			this.$emit('tealiumPageView', { mutation: this.selectedMutation, subTabName, offerFase })
		}
	}

	otherMutation(event: Event) {
		event.preventDefault()
		this.changeMutation(getMutation(MutationType.OTHER)!)
	}

	goToContactForm(event: Event) {
		this.$emit('goToContactForm', event)
	}

	toProfileTab(event: Event) {
		event.preventDefault()
		const profilePageTab = (event.target as HTMLLinkElement).href.split("#")[1]
		this.$emit("setProfilePageTab", profilePageTab)
		this.$emit('goToProfilePage', event)
	}

	toWidget(event: Event) {
		event.preventDefault()
		this.$emit("goToWidget", event)
	}

	triggerEmit(toTrigger: string, event: Event) {
		event.preventDefault()
		this.$emit(toTrigger, event)
	}

	emailBankAccountForm(event: Event) {
		this.checkAxonImpersonation(event)
		this.$emit('emailBankAccountFormClicked', event)
	}
	
	checkAxonImpersonation(event: Event) {
		if (this.axonImpersonation) {
			event.preventDefault()
			this.showProtectedText = true
		}
	}

	changeMutation(mutation: Mutation) {
		this.submitted = false
		this.$emit("selectMutation", mutation)
	}

	changedFiles(fileData: {filesToUpload: FilesToUpload, filesValid: boolean, checkedDocuments: Array<string> }) {
		this.filesToUpload = fileData.filesToUpload
		this.filesValid = fileData.filesValid
		this.checkedDocuments = fileData.checkedDocuments
	}

	changedMessage(messageData: {message: string, messageValid: boolean}) {
		this.message = messageData.message
		this.messageValid = messageData.messageValid
	}

	changedMutationFields(mutationData: {mutation: string, mutationValid: boolean}) {
		this.mutation = mutationData.mutation
		this.mutationValid = mutationData.mutationValid
	}

	changedContactDetails(contactData: {contactDetailsString: string, contactDetailsValid: boolean, customerContactDetails?: CustomerContactDetails}) {
		this.contactDetailsString = contactData.contactDetailsString
		this.contactDetailsValid = contactData.contactDetailsValid

		if (contactData.contactDetailsValid && contactData.customerContactDetails) {
			this.$emit("updateCustomerBasicData", contactData.customerContactDetails)
		}
	}

	changedRequiredDocuments(documentData: Array<Document>) {
		this.requiredDocuments = documentData
	}

	get validForm() {
		return this.messageValid && this.filesValid && this.contactDetailsValid && this.mutationValid
	}

	getSubject() {
		if (this.formtype === FormType.CONTACT && this.contactFormSubjects) {
			return this.contactFormSubjects.find(item => item.value === this.subject)!.label
		} else {
			if (this.selectedMutation.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT) {
				return "Uw levensverzekering bereikt de einddatum"
			} else {
				return this.formTexts.title!
			}
		}
	}

	getTaskType(): TaskType | undefined {
		if (this.formtype === FormType.CONTACT) {
			return this.contactFormSubjects!.find(item => item.value === this.subject)!.taskTypeRequest
		} else {
			if (this.selectedMutation.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT) {
				return TaskType.INFO_EXPIRATIE
			} else {
				if (this.offerFase) {
					if (this.offerFase === OfferFase.REQUEST) {
						return this.selectedMutation.taskTypeRequest
					} else {
						return this.selectedMutation.taskTypeApproved
					}
				} else {
					return this.selectedMutation.taskTypeRequest
				}
			}
		}
	}

	getMessage() {
		if (this.message !== "") {
			return `${this.formContentTexts!.messageLabel}:\n${this.message}\n\n`
		} else {
			return ""
		}
	}

	getMutation() {
		if (this.mutation !== "") {
			return `${this.mutation}\n\n`
		} else {
			return ""
		}
	}

	getCheckedDocuments() {
		let documentString = ""

		if (this.checkedDocuments.length) {
			for (const document of this.checkedDocuments) {
				documentString = `${documentString}${document}\n`
			}
			return `Bijgevoegde documenten:\n${documentString}\n`
		} else if (this.filesToUpload.listOfDocuments.length) {
			const listOfAllDocumentNames = this.filesToUpload.listOfDocuments.map(doc => doc.fileName)
			for (const fileName of listOfAllDocumentNames) {
				documentString = `${documentString}${fileName}\n`
			}
			return `Bijgevoegde documenten:\n${documentString}\n`
		} else {
			return ""
		}
	}

	changeBeneficiary(event: MouseEvent) {
		const targetLink = `mutatieform.html?mutationType=${MutationType.PREMIUM_CONTRIBUTOR}&returnUrl=mutatieform.html`
		this.$emit('changeBeneficiaryClicked', { event, targetLink })
		location.href = targetLink
	}

	submit(event: MouseEvent) {
		this.submitted = true

		if (this.validForm) {

			const subject = this.getSubject()
			const messageText = this.getMessage()
			const mutationText = this.getMutation()
			const documentText = this.getCheckedDocuments()
			const taskType = this.getTaskType()

			let mutationPostData: MutationPostData = {
				subject,
				message: `${mutationText}${messageText}${documentText}${this.contactDetailsString}`,
				policyId: this.selectedPolicyNumber !== "" ? this.selectedPolicyNumber : this.policyData.policyIdentifier,
				listOfDocuments: this.filesToUpload.listOfDocuments.length ? this.filesToUpload : null,
				taskType
			}

			this.$emit("sendFormData", mutationPostData)

			this.submitted = false
			mutationPostData = initialMutationPostData

			this.$emit('submitClicked', { event, eventType: 'primary' })
		}
	}
}
