import { Application } from "../typings/enums"
import { DocumentType, RequiredDocument } from "../typings/interfaces"

export interface Documents {
	identificationPolicyHolder: RequiredDocument
	identificationCoPolicyHolder: RequiredDocument
	identificationNewPolicyHolder: RequiredDocument
	bankStatementPolicyHolder: RequiredDocument
	bankStatementCoPolicyHolder: RequiredDocument
	bankStatementNewPolicyHolder: RequiredDocument
	signedOffer: RequiredDocument
	policyHolderChangeForm: RequiredDocument
	beneficiaryChangeForm: RequiredDocument
	investmentChoiceForm: RequiredDocument
	signedRequestExtranet: RequiredDocument
	nonSmokersDiscountForm: RequiredDocument
	payoutBeneficiaryConsent: RequiredDocument
	identificationBeneficiary: RequiredDocument
	bankStatementBeneficiary: RequiredDocument
	determineTaxStatus: RequiredDocument
}

export const documents: Documents = {
	identificationPolicyHolder: {
		documentTitle: "Kopie identiteitsbewijs {policyHolderName}",
		documentCondition: "COPOLICYHOLDER_LOGGEDIN",
		documentType: DocumentType.KOPIE_ID_BEWIJS,
		helpTextId: "kopieIDBewijs"
	},
	identificationCoPolicyHolder: {
		documentTitle: "Kopie identiteitsbewijs {coPolicyHolderName}",
		documentCondition: "POLICYHOLDER_LOGGEDIN",
		documentType: DocumentType.KOPIE_ID_BEWIJS,
		helpTextId: "kopieIDBewijs"
	},
	identificationNewPolicyHolder: {
		documentTitle: "Kopie identiteitsbewijs nieuwe verzekeringnemer",
		documentType: DocumentType.KOPIE_ID_BEWIJS,
		helpTextId: "kopieIDBewijs"
	},
	bankStatementPolicyHolder: {
		documentTitle: "Kopie bankafschrift {policyHolderName}",
		documentCondition: "COPOLICYHOLDER_LOGGEDIN",
		documentType: DocumentType.KOPIE_BANKAFSCHRIFT,
		helpTextId: "kopieBankafschriftVzn1"
	},
	bankStatementCoPolicyHolder: {
		documentTitle: "Kopie bankafschrift {coPolicyHolderName}",
		documentCondition: "POLICYHOLDER_LOGGEDIN",
		documentType: DocumentType.KOPIE_BANKAFSCHRIFT,
		helpTextId: "kopieBankafschriftVzn1"
	},
	bankStatementNewPolicyHolder: {
		documentTitle: "Kopie bankafschrift nieuwe verzekeringnemer",
		documentType: DocumentType.KOPIE_BANKAFSCHRIFT,
		helpTextId: "kopieBankafschriftVzn1"
	},
	signedOffer: { documentTitle: "Getekende offerte" },
	policyHolderChangeForm: {
		documentTitle: "Ingevuld formulier Wijziging verzekeringnemer"
	},
	beneficiaryChangeForm: {
		documentTitle: "Ingevuld formulier Aanvraag wijziging begunstiging"
	},
	investmentChoiceForm: {
		documentTitle: "Ingevuld formulier Switch en premieherbestemming fondsen"
	},
	signedRequestExtranet: {
		documentTitle: "Getekend verzoek verzekeringnemer",
		documentTitleMultiple: "Getekend verzoek verzekeringnemers",
		documentEnvironment: Application.EXTRANET
	},
	nonSmokersDiscountForm: {
		documentTitle: "Ingevuld formulier Niet-rokerstarief"
	},
	payoutBeneficiaryConsent: {
		documentTitle: "Ingevuld(e) uitkeringsformulier(en)",
		helpTextId: "UitkeringsformulierBegunstigde"
	},
	identificationBeneficiary: {
		documentTitle: "Kopie identiteitsbewijs van iedere begunstigde",
		documentType: DocumentType.KOPIE_ID_BEWIJS,
		helpTextId: "kopieIDBewijs"
	},
	bankStatementBeneficiary: {
		documentTitle: "Kopie bankafschrift van iedere begunstigde",
		documentType: DocumentType.KOPIE_BANKAFSCHRIFT,
		helpTextId: "kopieBankafschriftVzn1"
	},
	determineTaxStatus: {
		documentTitle: "Ingevuld formulier Bepalen belastingstatus",
	}
}
