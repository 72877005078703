































import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Application, MutationGroup, MutationType} from "./typings/enums"
import { PolicyData, Mutation } from "./typings/interfaces"
import RequestLoader from "leven-shared-components/RequestLoader.vue"

@Component({
	components: {
		RequestLoader
	}
})
export default class MutationOverview extends Vue {
	@Prop({type: Boolean, required: true}) dataLoaded!: boolean
	@Prop({type: String, required: true}) application!: Application
	@Prop({type: Array, required: true}) mutationList!: Array<Mutation>
	@Prop({type: Object, required: true}) policyData!: PolicyData

	introText = ""
	titleText = ""

	get administrationMutations() {
		return this.mutationList.filter(mutation => mutation.mutationGroup === MutationGroup.ADMINISTRATION)
	}

	get insuranceMutations() {
		if (this.mutationList) {

			let filteredMutationList = this.mutationList.filter(mutation => mutation.mutationGroup === MutationGroup.INSURANCE)

			if (!this.policyData.multipleInsured) {
				filteredMutationList = filteredMutationList.filter(mutation => mutation.mutationType !== MutationType.DELETE_INSURED)
			}

			if (!this.policyData.redeemable) {
				filteredMutationList = filteredMutationList.filter(mutation => mutation.mutationType !== MutationType.END_INSURANCE)
			}

			if (this.policyData.isRecentlyExpiredWithAllowedProduct) {
				filteredMutationList = filteredMutationList.filter(mutation => mutation.mutationType === MutationType.POSTPONE_ENDDATE)
			}

			return filteredMutationList
		}
		return []
	}

	mounted() {
		if (this.application === Application.SELFSERVICE) {
			this.introText = "Hieronder kunt u uw persoonlijke gegevens wijzigen. Of een verzoek indienen om uw verzekering aan te passen. Vervolgens beoordelen wij of uw gewenste wijziging mogelijk is."
			this.titleText = "Wijzig uw gegevens"
		} else {
			this.introText = "<strong>Let op:</strong> Nadat u de wijziging instuurt, beoordelen wij eerst of de gewenste wijziging mogelijk is."
			this.titleText = "Persoonlijke gegevens wijzigen"
		}
	}

	changeMutation(mutation: Mutation) {
		if (mutation.mutationType === MutationType.END_INSURANCE && this.policyData.hasPackageAccrualPolicyToRedeem) {
			this.$emit("showRedeemPackagePolicyModal", mutation)
		} else {
			this.$emit("selectMutation", mutation)
		}
	}

}
