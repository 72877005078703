import {ConversionMap} from "lib/types/import"
import {one, mandatory} from "lib/import/extract"
import {alphanumeric} from "lib/import/convert"
import {gender} from "store/import/convert"
import {epochDate} from "leven-shared-components/typings/convert"
import {Customer} from "api/models/customer"

const required = true
const optional = false

const customer: ConversionMap<Customer> = {
	emailAddress: ["customer.emailAddress", alphanumeric, optional],
	externalIdentifier: ["customer.externalIdentifier", alphanumeric, required],
	gender: ["customer.gender", gender, required],
	lastNameWithInsertion: ["customer.lastNameWithInsertion", alphanumeric, optional],
	nameForDisplay: ["customer.nameForDisplay", alphanumeric, required],
	previousLogin: ["datePreviousLogon", epochDate, optional],
	userIdentifierExternalSystem: ["userIdentifierExternalSystem", alphanumeric, optional],
	onlineCommunication: ["nonexistent", () => undefined, optional],
	communicationPreferenceChangeDate: ["nonexistent", () => undefined, optional]
}

export const convert = mandatory(one(customer))
