











import Vue from "vue"
import Component from "vue-class-component"
import Modal from "components/modal/Modal.vue"
import { Prop } from "vue-property-decorator"
import { Policy } from "store/policies/types"

@Component({
	components: {
		Modal
	}
})
export default class RedeemPackagePolicyModal extends Vue {
	@Prop({type: Object, required: true}) policyToRedeem!: Policy

	event = "redeemPackagePolicyModal"

	startWidgetWithRedemptionOfPackagePolicy() {
		this.$emit("startWidgetWithRedemptionOfPackagePolicy")
	}
}
