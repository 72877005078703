import { tealiumConfig } from 'api/settings'
import { Function2 } from 'lib/types/function'
import event from 'lib/vue/event'
import eventBus from 'lib/vue/eventBus'

type Tealium = any
declare const utag: any
declare const utag_data: {
    page_type: string
    site_business_line: string
    product_name: string
    site_funnel_name?: string
    site_funnel_type?: string
}

const loadTealium = new Promise<any>(resolve => {
    if (!process.env.SERVER) {
        if (typeof utag === 'undefined') {
            const script = document.createElement('script')
            script.src = `//tags.tiqcdn.com/utag/${tealiumConfig.account}/${tealiumConfig.profile}/${tealiumConfig.env}/utag.js`
            script.async = false
            script.onload = () => {
                resolve(utag)
            }
            document.body.appendChild(script)
        } else {
            resolve(utag)
        }
    }
})

export type TealiumEventKey =
    | 'tealium-pageview'
    | 'tealium-non-interaction'
    | 'tealium-interaction-clickable-link'
    | 'tealium-interaction-clickable-button'
    | 'tealium-interaction-clickable-radio-button'
    | 'tealium-interaction-clickable-download'
    | 'tealium-interaction-editable-dropdown'
    | 'tealium-interaction-editable-input-field'
    | 'tealium-interaction-accordion'
    | 'tealium-interaction-checkbox'
    | 'tealium-interaction-tooltip'

export default <T extends TealiumEvent.Params>(eventType: TealiumEventKey, handler: Function2<Tealium, T, void>): void => {
    // We use a promise instead of async/await, so that the caller does not have to handle the promise.
    // First wait until Tealium is loaded and the expected event has occurred. We don't know in which order this
    // will happen. After this, subscribe to the event bus for subsequent events.
    Promise.all([loadTealium, event<T>(eventType)])
        .then(([tealium, payload]) => {
            handler(tealium, payload)
            return tealium
        })
        .then(tealium => {
            eventBus.on<T>(eventType, payload => {
                handler(tealium, payload)
            })
        })
        .catch(reason => {
            // tslint:disable-next-line: no-console
            console.warn('Tracking failed', reason)
        })
}

const transformPageNameToPath = (name: string): string => {
    return `/${name.toLowerCase().replace(/\s/g, '-')}`
}

const constructPagePathByInfoSection = (section: TealiumEvent.PageInfoSection): string | undefined => {
    if (section.path) {
        return section.path
    } else if (section.name) {
        return transformPageNameToPath(section.name)
    }
    return undefined
}

/**
 * The path is constructed with given path segements, or by transforming the given name
 */
const constructPagePath = (pageInfo?: TealiumEvent.PageInfo): string => {
    const url = new URL(location.href)
    let path = url.origin + url.pathname
    if (pageInfo) {
        const { virtualPage, virtualTab, virtualSubTab } = pageInfo
        if (virtualPage) {
            path = constructPagePathByInfoSection(virtualPage) ?? path
        }
        if (virtualTab) {
            path = [path, constructPagePathByInfoSection(virtualTab)].join('')
            if (virtualSubTab) {
                path = [path, constructPagePathByInfoSection(virtualSubTab)].join('')
            }
        }
    }
    return path
}

/**
 * The page title is constructed by appending given names with a separator
 */
const constructPageTitle = (pageInfo?: TealiumEvent.PageInfo): string => {
    let title = document.title
    if (pageInfo) {
        const { virtualPage, virtualTab, virtualSubTab } = pageInfo
        if (virtualPage?.name) {
            title += ` - ${virtualPage?.name}`
            if (virtualTab?.name) {
                title += ` - ${virtualTab?.name}`
                if (virtualSubTab?.name) {
                    title += ` - ${virtualSubTab?.name}`
                }
            }
        }
    }
    return title
}

/**
 * Clean text content
 */
const cleanTextContent = (value: string): string => {
    return value.toLowerCase().trim()
}

/**
 * Create consistent URLs
 */
const generateUrlWithPrefix = (value: string): string => {
    if (!value.startsWith(location.origin)) {
        if (value.startsWith('/')) {
            return location.origin + value
        } else if (!value.startsWith('http')) {
            return location.origin + '/' + value
        }
    }
    return value
}

export namespace TealiumEvent {
    export type TealiumEvent = PageView.TealiumEvent | NonInteraction.TealiumEvent | Interaction.TealiumEvent
    export type Action = PageView.Action | NonInteraction.Action | Interaction.Action
    export type EventName = PageView.EventName | NonInteraction.EventName | Interaction.EventName
    export type EventType = NonInteraction.EventType | Interaction.EventType
    export type InputOptional = Interaction.InputOptional

    export interface Generic {
        [index: string]: any
        ga_version: '4'
        site_business_line: string
        product_name: string
        page_type: string
        page_path: string
        page_title: string
        tealium_event: TealiumEvent
        action: Action
        event_name: EventName
        type?: EventType
        text_content?: string
        label?: string
        target_link?: string
        input_optional?: InputOptional
        error_message?: string
        component_uid?: string
        site_funnel_name?: string
        site_funnel_step?: string
        site_funnel_index?: string
        site_funnel_type?: string
    }

    export interface PageInfo {
        virtualPage?: PageInfoSection
        virtualTab?: PageInfoSection
        virtualSubTab?: PageInfoSection
        siteFunnelInfo?: {
            siteFunnelStep: string
            siteFunnelIndex: string
        }
    }

    export interface PageInfoSection {
        path?: string
        name?: string
    }

    export interface Params {
        pageInfo: PageInfo
        siteFunnelStep?: string
        siteFunnelIndex?: string
    }

    export namespace PageView {
        export type TealiumEvent = 'pageView'
        export type Action = 'view'
        export type EventName = 'page_view' | 'funnel'

        export interface Generic extends TealiumEvent.Generic {
            tealium_event: TealiumEvent
            action: Action
            event_name: EventName
        }

        export interface Params extends TealiumEvent.Params {
            eventName: EventName
        }

        export const defaultValues = ({ pageInfo, eventName }: Params): Generic => ({
            ga_version: '4',
            site_business_line: utag_data.site_business_line,
            product_name: utag_data.product_name,
            page_type: utag_data.page_type,
            page_path: constructPagePath(pageInfo),
            page_title: constructPageTitle(pageInfo),
            tealium_event: 'pageView',
            action: 'view',
            event_name: eventName,
            site_funnel_name: utag_data.site_funnel_name,
            site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
            site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
            site_funnel_type: utag_data.site_funnel_type
        })
    }

    export namespace NonInteraction {
        export type TealiumEvent = 'non_interaction'
        export type Action = 'view'
        export type EventName = 'site_error'
        export type EventType = 'validation'

        export interface Generic extends TealiumEvent.Generic {
            tealium_event: TealiumEvent
            action: Action
            event_name: EventName
            type: EventType
            error_message: string
            component_uid: string
        }

        export interface Params extends TealiumEvent.Params {
            errorMessage: string
            componentUid: string
        }

        export const defaultValues = ({ pageInfo, errorMessage, componentUid }: Params): Generic => ({
            ga_version: '4',
            site_business_line: utag_data.site_business_line,
            product_name: utag_data.product_name,
            page_type: utag_data.page_type,
            page_path: constructPagePath(pageInfo),
            page_title: constructPageTitle(pageInfo),
            tealium_event: 'non_interaction',
            action: 'view',
            event_name: 'site_error',
            type: 'validation',
            error_message: cleanTextContent(errorMessage),
            component_uid: cleanTextContent(componentUid),
            site_funnel_name: utag_data.site_funnel_name,
            site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
            site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
            site_funnel_type: utag_data.site_funnel_type
        })
    }

    export namespace Interaction {
        export type TealiumEvent = 'interaction'
        export type Action = Clickable.Action | Editable.Action | Other.Accordion.Action | Other.Tooltip.Action | Other.Checkbox.Action
        export type EventName =
            | Clickable.EventName
            | Editable.EventName
            | Other.Accordion.EventName
            | Other.Tooltip.EventName
            | Other.Checkbox.EventName
        export type EventType = Clickable.EventType
        export type InputOptional = Editable.InputOptional

        export interface Generic extends TealiumEvent.Generic {
            tealium_event: TealiumEvent
            action: Action
            event_name: EventName
            type?: EventType
            label?: string
            target_link?: string
            input_optional?: InputOptional
        }

        export namespace Clickable {
            export type Action = 'click'
            export type EventName = Button.EventName | Download.EventName | Link.EventName | RadioButton.EventName
            export type EventType = Button.EventType | Download.EventType | Link.EventType

            export interface Generic extends Interaction.Generic {
                action: Action
                event_name: EventName
                event_type?: EventType
            }

            export namespace Link {
                export type EventName = 'link'
                export type EventType = 'link' | 'internal'

                export interface Generic extends Clickable.Generic {
                    event_name: EventName
                    type: EventType
                    text_content: string
                    target_link: string
                }

                export interface Params extends TealiumEvent.Params {
                    eventType: EventType
                    textContent: string
                    targetLink: string
                    componentUid?: string
                }

                export const defaultValues = ({ pageInfo, eventType, textContent, targetLink, componentUid }: Params): Generic => ({
                    ga_version: '4',
                    site_business_line: utag_data.site_business_line,
                    product_name: utag_data.product_name,
                    page_type: utag_data.page_type,
                    page_path: constructPagePath(pageInfo),
                    page_title: constructPageTitle(pageInfo),
                    tealium_event: 'interaction',
                    action: 'click',
                    event_name: 'link',
                    type: eventType,
                    text_content: cleanTextContent(textContent),
                    target_link: generateUrlWithPrefix(targetLink),
                    component_uid: componentUid ? cleanTextContent(componentUid) : undefined,
                    site_funnel_name: utag_data.site_funnel_name,
                    site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
                    site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
                    site_funnel_type: utag_data.site_funnel_type
                })
            }

            export namespace Button {
                export type EventName = 'button'
                export type EventType = 'primary' | 'secondary'

                export interface Generic extends Clickable.Generic {
                    event_name: EventName
                    type: EventType
                    text_content: string
                }

                export interface Params extends TealiumEvent.Params {
                    eventType: EventType
                    textContent: string
                    componentUid?: string
                }

                export const defaultValues = ({ pageInfo, eventType, textContent, componentUid }: Params): Generic => ({
                    ga_version: '4',
                    site_business_line: utag_data.site_business_line,
                    product_name: utag_data.product_name,
                    page_type: utag_data.page_type,
                    page_path: constructPagePath(pageInfo),
                    page_title: constructPageTitle(pageInfo),
                    tealium_event: 'interaction',
                    action: 'click',
                    event_name: 'button',
                    type: eventType,
                    text_content: cleanTextContent(textContent),
                    component_uid: componentUid ? cleanTextContent(componentUid) : undefined,
                    site_funnel_name: utag_data.site_funnel_name,
                    site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
                    site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
                    site_funnel_type: utag_data.site_funnel_type
                })
            }

            export namespace RadioButton {
                export type EventName = 'radio_button'

                export interface Generic extends Clickable.Generic {
                    event_name: EventName
                    label: string
                    text_content: string
                }

                export interface Params extends TealiumEvent.Params {
                    label: string
                    textContent: string
                }

                export const defaultValues = ({ pageInfo, label, textContent }: Params): Generic => ({
                    ga_version: '4',
                    site_business_line: utag_data.site_business_line,
                    product_name: utag_data.product_name,
                    page_type: utag_data.page_type,
                    page_path: constructPagePath(pageInfo),
                    page_title: constructPageTitle(pageInfo),
                    tealium_event: 'interaction',
                    action: 'click',
                    event_name: 'radio_button',
                    label: cleanTextContent(label),
                    text_content: cleanTextContent(textContent),
                    site_funnel_name: utag_data.site_funnel_name,
                    site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
                    site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
                    site_funnel_type: utag_data.site_funnel_type
                })
            }

            export namespace Download {
                export type EventName = 'download'
                export type EventType = 'pdf'

                export interface Generic extends Clickable.Generic {
                    event_name: EventName
                    type: EventType
                    text_content: string
                }

                export interface Params extends TealiumEvent.Params {
                    textContent: string
                }

                export const defaultValues = ({ pageInfo, textContent }: Params): Generic => ({
                    ga_version: '4',
                    site_business_line: utag_data.site_business_line,
                    product_name: utag_data.product_name,
                    page_type: utag_data.page_type,
                    page_path: constructPagePath(pageInfo),
                    page_title: constructPageTitle(pageInfo),
                    tealium_event: 'interaction',
                    action: 'click',
                    event_name: 'download',
                    type: 'pdf',
                    text_content: cleanTextContent(textContent),
                    site_funnel_name: utag_data.site_funnel_name,
                    site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
                    site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
                    site_funnel_type: utag_data.site_funnel_type
                })
            }
        }

        export namespace Editable {
            export type Action = Dropdown.Action | InputField.Action
            export type EventName = Dropdown.EventName | InputField.EventName
            export type InputOptional = InputField.InputOptional

            export interface Generic extends Interaction.Generic {
                action: Action
                label: string
            }

            export namespace Dropdown {
                export type Action = 'click' | 'open'
                export type EventName = 'dropdown'

                export interface Generic extends Editable.Generic {
                    action: Action
                    event_name: EventName
                    text_content: string
                }

                export interface Params extends TealiumEvent.Params {
                    action: Action
                    label: string
                    textContent: string
                }

                export const defaultValues = ({ pageInfo, action, label, textContent }: Params): Generic => ({
                    ga_version: '4',
                    site_business_line: utag_data.site_business_line,
                    product_name: utag_data.product_name,
                    page_type: utag_data.page_type,
                    page_path: constructPagePath(pageInfo),
                    page_title: constructPageTitle(pageInfo),
                    tealium_event: 'interaction',
                    action,
                    event_name: 'dropdown',
                    label: cleanTextContent(label),
                    text_content: cleanTextContent(textContent),
                    site_funnel_name: utag_data.site_funnel_name,
                    site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
                    site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
                    site_funnel_type: utag_data.site_funnel_type
                })
            }

            export namespace InputField {
                export type Action = 'focus'
                export type EventName = 'input_field'
                export type InputOptional = 'true' | 'false'

                export interface Generic extends Editable.Generic {
                    action: Action
                    event_name: EventName
                    input_optional: InputOptional
                }

                export interface Params extends TealiumEvent.Params {
                    label: string
                    inputOptional: boolean
                }

                export const defaultValues = ({ pageInfo, label, inputOptional }: Params): Generic => ({
                    ga_version: '4',
                    site_business_line: utag_data.site_business_line,
                    product_name: utag_data.product_name,
                    page_type: utag_data.page_type,
                    page_path: constructPagePath(pageInfo),
                    page_title: constructPageTitle(pageInfo),
                    tealium_event: 'interaction',
                    action: 'focus',
                    event_name: 'input_field',
                    label: cleanTextContent(label),
                    input_optional: inputOptional ? 'true' : 'false',
                    site_funnel_name: utag_data.site_funnel_name,
                    site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
                    site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
                    site_funnel_type: utag_data.site_funnel_type
                })
            }
        }

        export namespace Other {
            export namespace Accordion {
                export type Action = 'open' | 'close'
                export type EventName = 'accordion'

                export interface Generic extends Interaction.Generic {
                    action: Action
                    event_name: EventName
                    text_content: string
                }

                export interface Params extends TealiumEvent.Params {
                    isExpanded: boolean
                    textContent: string
                }

                export const defaultValues = ({ pageInfo, isExpanded, textContent }: Params): Generic => ({
                    ga_version: '4',
                    site_business_line: utag_data.site_business_line,
                    product_name: utag_data.product_name,
                    page_type: utag_data.page_type,
                    page_path: constructPagePath(pageInfo),
                    page_title: constructPageTitle(pageInfo),
                    tealium_event: 'interaction',
                    action: isExpanded ? 'open' : 'close',
                    event_name: 'accordion',
                    text_content: cleanTextContent(textContent),
                    site_funnel_name: utag_data.site_funnel_name,
                    site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
                    site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
                    site_funnel_type: utag_data.site_funnel_type
                })
            }

            export namespace Tooltip {
                export type Action = 'open'
                export type EventName = 'tooltip'

                export interface Generic extends Interaction.Generic {
                    action: Action
                    event_name: EventName
                    text_content: string
                }

                export interface Params extends TealiumEvent.Params {
                    textContent: string
                    componentUid?: string
                }

                export const defaultValues = ({ pageInfo, textContent, componentUid }: Params): Generic => ({
                    ga_version: '4',
                    site_business_line: utag_data.site_business_line,
                    product_name: utag_data.product_name,
                    page_type: utag_data.page_type,
                    page_path: constructPagePath(pageInfo),
                    page_title: constructPageTitle(pageInfo),
                    tealium_event: 'interaction',
                    action: 'open',
                    event_name: 'tooltip',
                    text_content: cleanTextContent(textContent),
                    component_uid: componentUid ? cleanTextContent(componentUid) : undefined,
                    site_funnel_name: utag_data.site_funnel_name,
                    site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
                    site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
                    site_funnel_type: utag_data.site_funnel_type
                })
            }

            export namespace Checkbox {
                export type Action = 'checked' | 'unchecked'
                export type EventName = 'checkbox'

                export interface Generic extends Interaction.Generic {
                    action: Action
                    event_name: EventName
                    text_content: string
                }

                export interface Params extends TealiumEvent.Params {
                    isChecked: boolean
                    textContent: string
                }

                export const defaultValues = ({ pageInfo, isChecked, textContent }: Params): Generic => ({
                    ga_version: '4',
                    site_business_line: utag_data.site_business_line,
                    product_name: utag_data.product_name,
                    page_type: utag_data.page_type,
                    page_path: constructPagePath(pageInfo),
                    page_title: constructPageTitle(pageInfo),
                    tealium_event: 'interaction',
                    action: isChecked ? 'checked' : 'unchecked',
                    event_name: 'checkbox',
                    text_content: cleanTextContent(textContent),
                    site_funnel_name: utag_data.site_funnel_name,
                    site_funnel_step: pageInfo.siteFunnelInfo?.siteFunnelStep,
                    site_funnel_index: pageInfo.siteFunnelInfo?.siteFunnelIndex,
                    site_funnel_type: utag_data.site_funnel_type
                })
            }
        }
    }
}
