











import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import capitalize from "lib/string/capitalize"

@Component
export default class SelectInput extends Vue {
	@Prop({type: String, required: false}) value!: string
	@Prop({type: Array, required: true}) options!: Array<string>
	@Prop({type: Boolean, required: true}) submitted!: boolean

	capitalize = capitalize
	defaultOption = "Maak een keuze"
	errorMessage = "Maak a.u.b. een keuze."

	get selected() {
		return this.value
	}

	set selected(select: string) {
		this.$emit("input", select)
	}

	get validSelected() {
		return this.selected !== null
	}

	update(type: 'open' | 'click', textContent: string) {
		this.$emit(type, { textContent })
	}

	@Watch("selected")
	changeSelectedPaymentTerm() {
		this.update("click", this.selected)
	}

	@Watch("submitted")
	@Watch("validSelected")
	checkErrors() {
		if (this.submitted && !this.validSelected) {
			this.$emit('error', { errorMessage: this.errorMessage })
		}
	}
}
