



























































import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {AccordionData} from "store/policies/types"
import {Clause, ClauseCode} from "leven-shared-components/typings/interfaces"
import fiscalTexts, {FiscalTexts} from "store/policies/fiscaltexts"
import AccordionItem from "custom/AccordionItem.vue"
import PolicyDetailItem from "custom/PolicyDetailItem.vue"
import InformationLink from "custom/InformationLink.vue"

interface Tab {
	id: "duringTerm" | "atPayment",
	title: string
}

@Component({
	components: {
		AccordionItem,
		PolicyDetailItem,
		InformationLink
	}
})
export default class PolicyTaxRules extends Vue {
	@Prop({type: Object, required: true}) clause!: Clause

	accordionData: AccordionData = {
		id: "policy-tax-rules",
		title: "Belastingregels voor uw verzekering"
	}

	texts: FiscalTexts | null | undefined = null

	tabs: Array<Tab> = [
		{
			id: "duringTerm",
			title: "Tijdens de looptijd"
		},
		{
			id: "atPayment",
			title: "Bij de uitkering"
		}
	]

	selected: Tab["id"] = "duringTerm"

	async mounted() {
		await Vue.nextTick()
		this.setTexts(this.clause.code)
	}

	selectTab(tab: Tab, event: MouseEvent) {
		this.selected = tab.id
		this.$emit('tabClicked', { eventType: 'internal', event, componentUid: this.accordionData.title })
	}

	setTexts(clauseCode: ClauseCode) {
		this.texts = fiscalTexts.find(text => text.clauses.includes(clauseCode))
	}

	get showFiscalValue() {
		const clauses: Array<ClauseCode> = [ClauseCode.KAP_Box3,
											ClauseCode.KAP_pBHW,
											ClauseCode.KAP_BHW_BV,
											ClauseCode.KAP_BHW,
											ClauseCode.KAP_LYF_PU,
											ClauseCode.KAP_ERFRNT]

		return clauses.find(clauseCode => clauseCode === this.clause.code)
	}

}
