



























import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { NumberType } from "leven-shared-components/typings/enums"
import DecimalInput from "leven-shared-components/DecimalInput.vue"

interface InterestRate {
	label: string
	value: string | null
}

@Component({
	components: {
		DecimalInput
	}
})
export default class MortgageRate extends Vue {
	@Prop({type: Boolean, required: true}) submitted!: boolean

	NumberType = NumberType
	validNumber = false
	minimumAmount = 0
	missingInterestRate = ""
	editingReadonly = false

	interestRates: Array<InterestRate> = [
		{ label: "Hypotheekrente 1", value: "" },
		{ label: "Hypotheekrente 2", value: null },
		{ label: "Hypotheekrente 3", value: null }
	]

	mounted() {
		this.interestRates[0].value = null
		this.changeFields("", false)
	}

	@Watch("interestRates", {deep: true})
	changedInterestRates() {

		let mutation = ""

		if (this.validInterestRates) {
			for (const interestRate of this.filledInterestRates) {
				mutation = `${mutation}\n${interestRate.label}: ${interestRate.value}%`
			}
		}

		this.changeFields(mutation, this.validInterestRates)

	}

	changeFields(mutation: string, mutationValid: boolean) {
		this.$emit("changedMutationFields", { mutation, mutationValid })
	}

	get filledInterestRates() {
		return this.interestRates.filter(interestRate => interestRate.value !== null && interestRate.value !== "")
	}

	get validInterestRates() {
		return this.filledInterestRates.length > 0
	}

	validateInterestRate(value: InterestRate["value"]) {
		return value === null || value === ""
	}

	defineReadOnly(interestRate: InterestRate) {

		const noValueFirst = this.validateInterestRate(this.interestRates[0].value)
		const noValueSecond = this.validateInterestRate(this.interestRates[1].value)

		noValueFirst ? this.missingInterestRate = "1" : noValueSecond ? this.missingInterestRate = "2" : this.missingInterestRate = ""

		return interestRate.label === "Hypotheekrente 2" && noValueFirst || interestRate.label === "Hypotheekrente 3" && noValueFirst
				|| interestRate.label === "Hypotheekrente 3" && noValueSecond || false

	}

	focusDecimalInput({isReadOnly, isRequired}: { isReadOnly: boolean, isRequired: boolean }) {
		this.focusReadOnly(isReadOnly)
		this.$emit('focus', { isRequired })
	}

	focusReadOnly(readOnly: boolean) {
		this.editingReadonly = readOnly
	}
}
