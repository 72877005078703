import { restApiRootUrl, customerEnvironmentExtId } from "api/settings"
import { getJson, post } from "api/request"
import { ClaimsResponse, Claim, ClaimDocumentsResponse } from "api/models/claim"
import { queryString } from "lib/request/queryParameters"
import { UploadInfo, ClaimDocument } from "store/policies/types"

export default class ClaimController {

	async getMany(): Promise<Array<Claim>> {
		const body: ClaimsResponse = await getJson(`${restApiRootUrl}/my-claims`)
		return body.myClaims
	}

	async getOne(claimExtId: string): Promise<Claim> {
		return getJson(`${restApiRootUrl}/my-claims/${claimExtId}`)
	}

	async getUnfinishedClaims(): Promise<any> {
		const parameters = {
			policyExtId: null
		}
		return getJson(`${restApiRootUrl}/claims/read-unfinished-claim-reports`, parameters)
	}

	async getClaimStatusOverview(claimExtId: string): Promise<any> {
		return getJson(`${restApiRootUrl}/my-claim-external-status/${claimExtId}`)
	}

	// showAllRequestedInformation = false currently always false
	// applyFilter = false Gives statusinfo about all documents
	async getDocuments(claimExtId: string, showAllRequestedInformation: boolean = false, applyFilter: boolean = false): Promise<ClaimDocumentsResponse> {
		const parameters = {
			showAllRequestedInformation,
			applyFilter
		}
		return getJson(`${restApiRootUrl}/my-claims/${claimExtId}/communications/requested-informations/search/overview/`, parameters)
	}

	async getDocument(claimExtId: string, documentExtId: string): Promise<any> {
		return getJson(`${restApiRootUrl}/my-claims/${claimExtId}/documents/${documentExtId}/show`)
	}

	async getDocumentContent(documentExtId: string): Promise<any> {
		return getJson(`${restApiRootUrl}/my-claims/documents/${documentExtId}/show-content`)
	}

	async uploadDocuments(uploadInfo: UploadInfo): Promise<any> {
		const url = `${restApiRootUrl}/my-claims/${uploadInfo.claimID}/create-with-attachments`

		const parameters = {
			customerExtId: uploadInfo.customerExtId,
			customerEnvironmentExtId
		}

		const uploadDocArray: Array<{ externalIdentifier: string }> = []
		const descriptionDocArray: Array<string> = []

		uploadInfo.toUpload.forEach((document: ClaimDocument) => {
			uploadDocArray.push({ externalIdentifier: document.identifier })
			if (document.name) {
				descriptionDocArray.push(document.name)
			}
		})

		const descriptionDocString = descriptionDocArray && descriptionDocArray.length > 0 ? descriptionDocArray.join(", ") : uploadInfo.fileInfo.file.name

		const body = [{
			description: descriptionDocString,
			fileName: uploadInfo.fileInfo.file.name,
			contents: uploadInfo.fileInfo.fileBase64,
			requestedInformationBlockAtAddDocument: {
				selectedRequestedInformationList: uploadDocArray
			}
		}]

		return post(url + queryString(parameters), body)
	}

}
