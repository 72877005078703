import {locale, plural} from "lib/i18n/locale/nl-NL"
import {Options} from "lib/types/i18n"

const options: Options = {
	locale,
	plural: {
		...plural,
		attachments: ["Bijlage", "Bijlagen"]
	},
	texts: {}
}

export default options
