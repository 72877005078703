









import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import eventBus from "lib/vue/eventBus"
import InformationImage from "leven-shared-components/InformationImage.vue"

@Component({
	components: {
		InformationImage
	}
})
export default class InformationLink extends Vue {
	@Prop({type: String, required: true}) label!: string
	@Prop({type: String, required: true}) text!: string
	@Prop({type: String, required: false}) infoComponent!: string

	showInformationText() {
		eventBus.emit("informationModal", "Toelichting", undefined, this.text, this.infoComponent)

		this.$emit('click')
	}
}
