import { cloneDeep, isString, omit } from "lodash-es"
import { storageOptions } from "lib/storage/session"
import persist from "lib/vuex/plugins/persist"
import Vue from "vue"

import Vuex, { MutationTree, Store } from "vuex"

import eventBus from "lib/vue/eventBus"
import event from "lib/vue/event"
import { ACCESS_DENIED, ACCESS_REVOKED, IDENTIFICATION_FAILED, AUTHORIZATION_FAILED, ACCESS_GRANTED, ACCESS_RESTORED } from "lib/vue/events"

import communication from "./communication"
import policies from "./policies"
import routing from "./routing"
import customer from "./customer"
import { convert as convertCustomer } from "./customer/conversion"
import { RootState } from "./types"

import { customer as customerApi, policy as policyApi, claim as claimApi, communication as communicationApi } from "api/gateway"
import { convertPolicies, convertPolicy, convertClaims, convertDocuments, convertUnfinishedClaims } from "./policies/conversion"
import { convertCustomerCommunication, convertPolicyCommunication, convertClaimCommunication, convertMessages, convertFAQs } from "./communication/conversion"
import { session, identification, jwtAuthorization, postJsonICS } from "api/request"
import { iWelcomeLoginUrl, iWelcomeLogoutUrl, impersonateTokenUrl } from "api/settings"
import { JwtToken } from "lib/types/security"

Vue.use(Vuex)

const communicationPreference = (preference: string) => preference === "Email" || false

const rootState: RootState = {
	language: "nl",
	timestamp: 0,
	lastLogin: null,
	logout: false,
	userIdentifier: undefined,
	impersonation: false,
	axonImpersonation: false
}

const mutations: MutationTree<RootState> = {
	timestamp(state) {
		state.timestamp = Date.now()
	},
	logout(state) {
		state.logout = true
	},
	userIdentifier(state, identifier: string) {
		state.userIdentifier = identifier
	},
	impersonation(state) {
		state.impersonation = true
	},
	axonImpersonation(state) {
		state.axonImpersonation = true
	}
}

const plugins = process.env.SERVER ? [] : [
	persist({
		...storageOptions,
		blacklist: ["text"],
		deactivate: s => s.state.logout
	}),
	// tslint:disable-next-line: no-shadowed-variable
	async (store: Store<RootState>) => {
		if (!process.env.SERVER) {
			eventBus.on(IDENTIFICATION_FAILED, () => {
				store.commit("logout")

				sessionStorage.setItem("deeplink", location.href)
				location.href = iWelcomeLoginUrl
			})
			eventBus.on(ACCESS_DENIED, () => {
				// One source of this event is when an empty username is returned from iWelcome.
				store.commit("logout")
				location.href = "./unknownuser.html"
			})
			eventBus.on(ACCESS_REVOKED, () => {
				store.commit("logout")
				location.href = iWelcomeLogoutUrl
			})
			eventBus.on(AUTHORIZATION_FAILED, async () => {
				await session.login(null)
			})
			eventBus.on("impersonate", async (token: JwtToken) => {

				let jwtToken: JwtToken
				if (isString(token)) {
					// This is a temporary token provided by an external party. Use it to fetch a proper token.
					const externalToken = {
						username: "",
						roles: [],
						access_token: token,
						refresh_token: "",
						token_type: "Bearer",
						expires_in: 3600
					}
					jwtAuthorization.authorize(externalToken)
					jwtToken = await postJsonICS(impersonateTokenUrl)
					jwtAuthorization.unauthorize()
				} else {
					jwtToken = token
				}
				const axonToken = await identification.axonToken(jwtToken)

				if (session.impersonate(identification.identifier, axonToken)) {
					store.commit("impersonation")
					eventBus.emit(ACCESS_GRANTED, identification.identifier)
				} else {
					eventBus.emit(ACCESS_DENIED)
				}
			})
			eventBus.on("axonImpersonation", () => {
				store.commit("axonImpersonation")
			})

			const userIdentifier = await event([ACCESS_GRANTED, ACCESS_RESTORED])

			if (userIdentifier) {
				store.commit("userIdentifier", userIdentifier)
			}
			if (store.state.userIdentifier) {
				const params = new URLSearchParams(window.location.search)
				const policyNumber = params.get("policy")?.toString()
				if (policyNumber) {
					const policyData = await policyApi.getOne(policyNumber)
					const policyConvert = convertPolicy(policyData)
					const policyAction = params.get("action")
					if (policyAction === "redemption") {
						store.commit("routing/selectPolicy", policyConvert)
						store.commit("routing/selectWidget", "addAfkoop")
						location.href = "payoutprocess.html"
					} else if (policyAction === "completeClaim") {
						store.commit("routing/selectPolicy", policyConvert)
						store.commit("routing/selectWidget", "complete")
						location.href = "payoutprocess.html"
					} else if (policyAction === "policyDocuments") {
						store.commit("routing/selectPolicy", policyConvert)
						location.href = "payoutprocess.html"
					} else if (policyAction === "mutatePolicy") {
						store.commit("routing/selectPolicy", policyConvert)
						location.href = "payoutprocess.html"
					}
			}

				const deeplinkPage = sessionStorage.getItem("deeplink")
				if (deeplinkPage) {
					location.href = deeplinkPage
					sessionStorage.removeItem("deeplink")
					return
				}

				if (!store.state.customer!.loaded) {
					const customerData = await customerApi.getOne(store.state.userIdentifier)
					store.commit("customer/hydrate", convertCustomer(customerData))
				}

				if (!store.state.customer!.communicationPreferenceLoaded) {
					const communicationData = await customerApi.getCommunicationPreferences(store.state.customer!.externalIdentifier)
					const preference = communicationPreference(communicationData.communicationTypeForClaimDocumentsRef.externalIdentifier)
					const changeDate = new Date(communicationData.changeToken)
					store.commit("customer/setOnlineCommunication", preference)
					store.commit("customer/setCommunicationPreferenceChangeDate", changeDate)
				}

				if (!store.state.communication!.generalFaqsLoaded) {
					const generalFaqs = await communicationApi.getGeneralFAQs()
					store.commit("communication/generalfaqs", convertFAQs(generalFaqs))
				}

				if (!store.state.communication!.messagesLoaded) {
					const messages = await communicationApi.getMessages()
					store.commit("communication/messages", convertMessages(messages))
				}

				if (!store.state.communication!.policyLoaded) {
					const data = await communicationApi.getManyPolicy()
					const documents = convertPolicyCommunication(data)
					if (documents) {
						store.commit("communication/policy", documents)
					}
				}
				if (!store.state.communication!.claimLoaded) {
					const data = await communicationApi.getManyClaim()
					const documents = convertClaimCommunication(data)
					if (documents) {
						store.commit("communication/claim", documents)
					}
				}
				if (!store.state.communication!.customerLoaded) {
					const data = await communicationApi.getManyCustomer()
					const documents = convertCustomerCommunication(data)
					if (documents) {
						store.commit("communication/customer", documents)
					}
				}

				if (!store.state.policies!.policiesLoaded) {
					// TODO: doesn't getOverview give us all we need?
					const data = await policyApi.getMany()
					// tslint:disable-next-line: no-shadowed-variable
					const policies = convertPolicies(data)
					if (policies) {
						store.commit("policies/hydrate", policies)
					}
				}

				if (!store.state.policies!.claimsLoaded) {
					const data = await claimApi.getMany()
					const claims = convertClaims(data)
					const unfinishedClaimsData = await claimApi.getUnfinishedClaims()
					const unfinishedClaims = convertUnfinishedClaims(unfinishedClaimsData)

					// tslint:disable-next-line: no-shadowed-variable
					const policies = store.state.policies!.policies

					for (const policy of policies) {
						if (claims) {
							const policyClaims = claims.filter(item => item.policies && item.policies.includes(policy.policyIdentifier)).sort(
								(a, b) => a.damageDate > b.damageDate || a.claimNumber > b.claimNumber ? -1 : 1
							)
							let claim = null
							if (!policyClaims[0] || policyClaims[0] && policyClaims[0].statusId !== -2) {
								const unFinishedClaimsByPolicy = unfinishedClaims ? unfinishedClaims.filter(item => item.policyIdentifier
									=== policy.policyIdentifier).sort((a, b) => {
																				if (a.damageDate.getTime() === b.damageDate.getTime()) {
																					return a.externalIdentifier > b.externalIdentifier ? -1 : 1
																				}
																				return a.damageDate > b.damageDate ? -1 : 1
																				}) : null

								if (unFinishedClaimsByPolicy && unFinishedClaimsByPolicy.length > 0) {
									claim = claims.filter(item => item.identifierForCompletion === unFinishedClaimsByPolicy[0].nasmId)[0]
								} else {
									claim = policyClaims[0] && omit(policyClaims[0], "policies")
								}
							} else {
								claim = policyClaims[0] && omit(policyClaims[0], "policies")
							}

							if (claim) {
								const claimDocuments = await claimApi.getDocuments(claim.claimNumber)
								if (claimDocuments) {
									claim.requestedInformation = convertDocuments(claimDocuments)!
								}
								store.commit("policies/claim", {policy, claim})
								store.commit("policies/claimsOverview", claim)
							}
							const claimsToAdd = [...claims.filter(c => c.statusId === -4)]
							store.commit("policies/claimsOverview", claimsToAdd)
						}
						if (store.state.customer?.externalIdentifier === policy.coPolicyHolderExtId) {
							store.commit("policies/currentUserIsCoPolicyholder", policy.policyIdentifier)
						}
					}
					store.commit("policies/claimsLoaded")
				}
			}
		}
	}
]

const store = new Store<RootState>({
	modules: {
		communication,
		policies,
		routing,
		customer
	},
	mutations,
	plugins,
	state: cloneDeep(rootState),
	strict: process.env.NODE_ENV !== "production"
})

export default store
