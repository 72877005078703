const generalInstruction = "<p>Een duidelijk leesbare kopie of schermafdruk van een recent bankafschrift als extra controle van de identiteit. Het bankafschrift moet van een betaalrekening zijn en mag niet ouder zijn dan drie maanden. Deze gegevens moeten goed leesbaar zijn:<ul><li>de naam van de rekeninghouder</li><li>het adres (straatnaam, postcode en woonplaats) van de rekeninghouder</li><li>het IBAN rekeningnummer</li><li>de datum (dd-mm-jjjj) van het bankafschrift</li></ul>Bij welke bank hebt u een betaalrekening?</p>"

const bankStatementsHelpTexts = {
	generalInstruction: `${generalInstruction}`,
	specificInstructionPerBank: [
		{
			value: "",
			label: "Kies hier uw bank"
		},
		{
			value: "ABN_AMRO",
			label: "ABN AMRO",
			url: "https://www.abnamro.nl/nl/prive/betalen/bij-en-afschrijvingen/downloaden.html",
			instructionText: `Op de website van de <a href="https://www.abnamro.nl/nl/prive/betalen/bij-en-afschrijvingen/downloaden.html" target="_blank">ABN Amro Bank</a> staat hoe u een digitaal bankafschrift kunt downloaden.`
		},
		{
			value: "ASN_BANK",
			label: "ASN Bank",
			url: "https://www.asnbank.nl/veelgestelde-vragen.html",
			instructionText: `Bij de <a href="https://www.asnbank.nl/veelgestelde-vragen.html" target="_blank">veelgestelde vragen</a> op de website van ASN Bank staat hoe u een digitaal bankafschrift (transactieoverzicht) kunt downloaden. U vindt dit onder 'Hoe kan ik een rekeningafschrift opvragen?'.`
		},
		{
			value: "BUNQ",
			label: "Bunq",
			url: "https://together.bunq.com/d/3207-how-do-i-export-a-bank-statement",
			instructionText: `Op de website van <a href="https://together.bunq.com/d/3207-how-do-i-export-a-bank-statement" target="_blank">Bunq</a> staat hoe u een digitaal bankafschrift (bank statement) kunt downloaden.`
		},
		{
			value: "ING",
			label: "ING",
			url: "https://www.ing.nl/particulier/digitaal-bankieren/internetbankieren/zo-werkt-mijn-ing/afschriften-downloaden/index.html",
			instructionText: `Op de website van de <a href="https://www.ing.nl/particulier/digitaal-bankieren/internetbankieren/zo-werkt-mijn-ing/afschriften-downloaden/index.html" target="_blank">ING Bank</a> staat hoe u een digitaal bankafschrift kunt downloaden.`
		},
		{
			value: "KNAB",
			label: "KNAB",
			url: "https://www.knab.nl/download-bankafschrift#Knab",
			instructionText: `Op de website van <a href="https://www.knab.nl/download-bankafschrift#Knab" target="_blank">KNAB</a> staat hoe u een digitaal bankafschrift kunt downloaden.`
		},
		{
			value: "RABOBANK",
			label: "Rabobank",
			url: "https://www.rabobank.nl/particulieren/service/financiele-overzichten/overzicht-betaalrekening-ontvangen",
			instructionText: `Op de website van de <a href="https://www.rabobank.nl/particulieren/service/financiele-overzichten/overzicht-betaalrekening-ontvangen" target="_blank">Rabobank</a> staat hoe u een digitaal bankafschrift kunt downloaden.`
		},
		{
			value: "REGIOBANK",
			label: "Regiobank",
			url: "https://www.regiobank.nl/service/online-bankieren/rekeningafschriften-downloaden.html",
			instructionText: `Op de website van de <a href="https://www.regiobank.nl/service/online-bankieren/rekeningafschriften-downloaden.html" target="_blank">Regiobank</a> staat hoe u een digitaal bankafschrift kunt downloaden.`
		},
		{
			value: "SNSBANK",
			label: "SNS Bank",
			url: "https://www.snsbank.nl/particulier/betalen/service/rekeningafschriften.html",
			instructionText: `Op de website van de <a href="https://www.snsbank.nl/particulier/betalen/service/rekeningafschriften.html" target="_blank">SNS Bank</a> staat hoe u een digitaal bankafschrift kunt downloaden.`
		},
		{
			value: "TRIODOSBANK",
			label: "Triodos Bank",
			url: "https://www.triodos.nl/veelgestelde-vragen/hoe-download-ik-mijn-afschriften?id=5ff7f90de4b1",
			instructionText: `Op de website van de <a href="https://www.triodos.nl/veelgestelde-vragen/hoe-download-ik-mijn-afschriften?id=5ff7f90de4b1" target="_blank">Triodos Bank</a> staat hoe u een digitaal bankafschrift kunt downloaden.`
		},
		{
			value: "VANLANSCHOTBANK",
			label: "Van Lanschot",
			url: "https://www.vanlanschot.nl/contact/faq",
			instructionText: `Bij de <a href="https://www.vanlanschot.nl/contact/faq" target="_blank">veelgestelde vragen</a> op de website van Van Lanschot staat hoe u een digitaal bankafschrift kunt downloaden. U vindt dit onder 'Hoe download ik mijn rekeningdocumenten?'.`
		},
		{
			value: "OVERIG",
			label: "Overige banken",
			instructionText: "Staat uw bank er niet tussen? Informeer dan zelf bij uw bank hoe u een digitaal bankafschrift kunt downloaden."
		}
	]
}

export default bankStatementsHelpTexts
