import {ConversionFunction} from "lib/types/import"
import {Gender, MaritalStatus, PhoneType, Status} from "lib/types/personal/personal"

export const gender: ConversionFunction<Gender> = value => {
	switch (value) {
		case "F": return "FEMALE"
		case "M": return "MALE"
		case "X": return "THIRD"
		case "U": return "UNKNOWN"
		default: return undefined
	}
}

export const maritalStatus: ConversionFunction<MaritalStatus> = value => {
	switch (value) {
		case "divorced": return "Divorced"
		case "legalcohabitation": return "LegalCohabitation"
		case "livingtogether": return "LivingTogether"
		case "married": return "Married"
		case "single": return "Single"
		case "unknown": return "Unknown"
		default: return undefined
	}
}

export const phoneType: ConversionFunction<PhoneType> = value => {
	switch (value) {
		case "phoneMobile": return "Mobile"
		case "phonePrivate": return "Landline"
		case "phone": return "Other"
		case "unknown": return "Unknown"
		default: return undefined
	}
}

export const status: ConversionFunction<Status> = value => {
	switch (value) {
		case "1": return "Active"
		case "2": return "Inactive"
		case "3": return "Pensioner"
		default: return undefined
	}
}
