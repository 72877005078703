import {isUndefined} from "lodash-es"
import {AuthorizationStrategy, JwtToken, isJwtToken} from "lib/types/security"
import {RequestMethod, Parameters} from "lib/types/request"
import {queryString, encodeParameters} from "lib/request/queryParameters"
import HttpStatus from "lib/request/status"
import eventBus from "lib/vue/eventBus"
import {AUTHORIZATION_FAILED, ACCESS_DENIED} from "lib/vue/events"

export default class JwtAuthorization implements AuthorizationStrategy {
	private token: JwtToken | null = null

	constructor(private readonly refreshEndpoint?: string) {}

	authorize(data: any): boolean {
		if (isJwtToken(data)) {
			this.token = data
			return true
		}
		return false
	}

	unauthorize(): boolean {
		if (this.isAuthorized) {
			this.token = null
			return true
		}
		return false
	}

	get isAuthorized(): boolean {
		return !!this.token
	}

	isAuthorizedAll(roles: ReadonlyArray<string>): boolean {
		return this.isAuthorized && roles.every(role => this.token!.roles.includes(role))
	}

	isAuthorizedAny(roles: ReadonlyArray<string>): boolean {
		return this.isAuthorized && roles.some(role => this.token!.roles.includes(role))
	}

	async request(method: RequestMethod, input: string, data?: Parameters): Promise<Response> {
		const query = (method === "GET" || method === "HEAD") && data ? queryString(data) : ""
		const response = await this.fetch(method, input + query, data)

		switch (response.status) {
			case HttpStatus.UNAUTHORIZED: {
				const refreshed = await this.refreshToken()
				if (refreshed) {
					const retry = await this.fetch(method, input + query, data)
					if (retry.ok) {
						return retry
					}
				}
				eventBus.emit(AUTHORIZATION_FAILED)
				break
			}
			case HttpStatus.FORBIDDEN: {
				eventBus.emit(ACCESS_DENIED)
				break
			}
		}

		return response
	}

	private async refreshToken(): Promise<boolean> {
		if (!this.isAuthorized || !this.refreshEndpoint) {
			return false
		}

		const formData = {
			grant_type: "refresh_token",
			refresh_token: this.token!.refresh_token
		}
		const response = await fetch(this.refreshEndpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Cache-Control": "no-cache"
			},
			credentials: "omit",
			body: encodeParameters(formData)
		})

		if (response.ok) {
			const data = await response.json()
			const token = {
				...this.token,
				...data
			}
			this.authorize(token)
			return true
		}

		return false
	}

	private fetch(method: RequestMethod, input: string, data?: Parameters, options?: RequestInit): Promise<Response> {
		const headers = new Headers()
		if (this.isAuthorized) {
			headers.append("Authorization", `${this.token!.token_type} ${this.token!.access_token}`)
		}
		// For file uploads, the content-type header must be omitted. For now, we recognize this case by the use of a FormData object.
		const isFileUpload = data && data instanceof FormData
		if (!isFileUpload) {
			headers.append("Content-Type", "application/json")
		}

		return fetch(input, {
			method,
			headers,
			mode: "cors",
			credentials: "omit",
			body: method === "GET" || method === "HEAD" || isUndefined(data) ? undefined :
				data instanceof FormData ? data :
				JSON.stringify(data),
			...options
		})
	}

}
