import eventBus from 'lib/vue/eventBus'
import track, { TealiumEvent, TealiumEventKey } from './tealium_v4'

if (!process.env.SERVER) {
    track<TealiumEvent.PageView.Params>('tealium-pageview', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.PageView.defaultValues(tealiumParams)
        tealium.view(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.NonInteraction.Params>('tealium-non-interaction', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.NonInteraction.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.Interaction.Clickable.Link.Params>('tealium-interaction-clickable-link', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.Interaction.Clickable.Link.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.Interaction.Clickable.Button.Params>('tealium-interaction-clickable-button', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.Interaction.Clickable.Button.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.Interaction.Clickable.RadioButton.Params>('tealium-interaction-clickable-radio-button', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.Interaction.Clickable.RadioButton.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.Interaction.Clickable.Download.Params>('tealium-interaction-clickable-download', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.Interaction.Clickable.Download.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.Interaction.Editable.Dropdown.Params>('tealium-interaction-editable-dropdown', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.Interaction.Editable.Dropdown.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.Interaction.Editable.InputField.Params>('tealium-interaction-editable-input-field', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.Interaction.Editable.InputField.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.Interaction.Other.Accordion.Params>('tealium-interaction-accordion', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.Interaction.Other.Accordion.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.Interaction.Other.Checkbox.Params>('tealium-interaction-checkbox', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.Interaction.Other.Checkbox.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })

    track<TealiumEvent.Interaction.Other.Tooltip.Params>('tealium-interaction-tooltip', (tealium, tealiumParams) => {
        const tealiumData = TealiumEvent.Interaction.Other.Tooltip.defaultValues(tealiumParams)
        tealium.link(cleanupTealiumData(tealiumData))
    })
}

const cleanupTealiumData = <T extends TealiumEvent.Generic>(tealiumData: T) => {
    Object.keys(tealiumData).forEach(key => {
        if (tealiumData[key] === undefined) {
            delete tealiumData[key]
        }
    })
    return tealiumData
}

const tealiumEvent = <T extends TealiumEvent.Params>(eventKey: TealiumEventKey, eventData: T) => {
    eventBus.emit(eventKey, eventData)
}

export const tealiumPageView = (pageInfo: TealiumEvent.PageInfo, eventName?: TealiumEvent.PageView.EventName) => {
    tealiumEvent('tealium-pageview', { pageInfo, eventName: eventName ?? 'page_view' })
}

export const tealiumNonInteraction = (pageInfo: TealiumEvent.PageInfo, errorMessage: string, componentUid: string) => {
    tealiumEvent('tealium-non-interaction', {
        pageInfo,
        errorMessage,
        componentUid
    })
}

export const tealiumInteractionClickableLink = (
    pageInfo: TealiumEvent.PageInfo,
    eventType: TealiumEvent.Interaction.Clickable.Link.EventType,
    event?: MouseEvent,
    targetLink?: string,
    textContent?: string,
    componentUid?: string
) => {
    const element = event?.target as HTMLLinkElement | undefined
    tealiumEvent('tealium-interaction-clickable-link', {
        pageInfo,
        eventType,
        textContent: textContent ?? element?.textContent ?? '',
        targetLink: targetLink ?? element?.href ?? '',
        componentUid
    })
}

export const tealiumInteractionClickableButton = (
    pageInfo: TealiumEvent.PageInfo,
    eventType: TealiumEvent.Interaction.Clickable.Button.EventType,
    event: MouseEvent,
    textContent?: string,
    componentUid?: string
) => {
    const element = event.target as HTMLElement
    tealiumEvent('tealium-interaction-clickable-button', {
        pageInfo,
        eventType,
        textContent: textContent ?? element.textContent ?? '',
        componentUid
    })
}

export const tealiumInteractionClickableRadioButton = (pageInfo: TealiumEvent.PageInfo, event: MouseEvent, label: string) => {
    const element = event.target as HTMLInputElement
    tealiumEvent('tealium-interaction-clickable-radio-button', {
        pageInfo,
        textContent: element?.labels?.item(0)?.textContent ?? '',
        label
    })
}

export const tealiumInteractionClickableDownload = (pageInfo: TealiumEvent.PageInfo, event: MouseEvent) => {
    const element = event.target as HTMLElement
    tealiumEvent('tealium-interaction-clickable-download', {
        pageInfo,
        textContent: element.textContent ?? ''
    })
}

export const tealiumInteractionEditableDropdown = (
    pageInfo: TealiumEvent.PageInfo,
    action: TealiumEvent.Interaction.Editable.Dropdown.Action,
    label: string,
    textContent: string
) => {
    tealiumEvent('tealium-interaction-editable-dropdown', {
        pageInfo,
        action,
        label,
        textContent
    })
}

export const tealiumInteractionEditableInputField = (
    pageInfo: TealiumEvent.PageInfo,
    label: string,
    isRequired: boolean
) => {
    tealiumEvent('tealium-interaction-editable-input-field', {
        pageInfo,
        label,
        inputOptional: !isRequired
    })
}

export const tealiumInteractionOtherAccordion = (pageInfo: TealiumEvent.PageInfo, isExpanded: boolean, textContent: string) => {
    tealiumEvent('tealium-interaction-accordion', {
        pageInfo,
        isExpanded,
        textContent
    })
}

export const tealiumInteractionOtherCheckbox = (pageInfo: TealiumEvent.PageInfo, event: MouseEvent, textContent?: string) => {
    const element = event.target as HTMLInputElement
    tealiumEvent('tealium-interaction-checkbox', {
        pageInfo,
        isChecked: element?.checked ?? false,
        textContent: textContent ?? element?.textContent ?? ''
    })
}

export const tealiumInteractionOtherTooltip = (pageInfo: TealiumEvent.PageInfo, textContent: string, componentUid?: string) => {
    tealiumEvent('tealium-interaction-tooltip', {
        pageInfo,
        textContent,
        componentUid
    })
}
