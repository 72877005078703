import RequestStrategy from "./RequestStrategy"
import {RequestMethod, Parameters} from "lib/types/request"
import {isUndefined} from "lodash-es"
import {queryString} from "./queryParameters"

/**
 * A `RequestStrategy` that is a simple wrapper around `fetch`.
 */
export default class FetchGateway implements RequestStrategy {

	async request(method: RequestMethod, input: string, data?: Parameters): Promise<Response> {
		const query = (method === "GET" || method === "HEAD") && data ? queryString(data) : ""
		const response = await fetch(input + query, {
			method,
			headers: {
				"Content-Type": "application/json"
			},
			mode: "cors",
			credentials: "include",
			body: method === "GET" || method === "HEAD" || isUndefined(data) ? undefined : JSON.stringify(data)
		})

		return response
	}

}
