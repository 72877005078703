










































import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {namespace} from "vuex-class"

import {StoreMutation} from "lib/types/vuex"
import {Routing} from "store/routing/types"

import PageHeader from "custom/PageHeader.vue"
import PageFooter from "custom/PageFooter.vue"
import LinkButton from "components/button/LinkButton.vue"
import {IconArrow} from "custom/icons"

const routing = namespace("routing")

@Component({
	components: {
		PageHeader,
		PageFooter,
		LinkButton,
		IconArrow
	}
})
export default class Page extends Vue {
	@Prop({type: String, required: false }) contentPage!: string
	@Prop({type: String, required: false }) backLink!: string

	@routing.State selectedDocumentId!: Routing["selectedDocumentId"]
	@routing.State selectedProfileItem!: Routing["selectedProfileItem"]
	@routing.Mutation selectDocumentId!: StoreMutation<string | undefined>
	@routing.Mutation selectProfileItem!: StoreMutation<string | undefined>

	hasTitle = false
	hasSubTitle = false
	hasNavigation = false
	hasHeading = false

	mounted() {
		this.hasTitle = !!this.$slots.title
		this.hasSubTitle = !!this.$slots.subtitle
		this.hasNavigation = !!this.$slots.navigation
		this.hasHeading = !!this.$slots.title || !!this.$slots.subtitle
	}

	resetSelection() {
		if (this.selectedDocumentId || this.selectedProfileItem) {
			this.selectProfileItem(undefined)
			this.selectDocumentId(undefined)
		}
	}
}
