























import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { email } from "lib/validation/formats"
import { CustomerContactDetails } from "leven-shared-components/typings/interfaces"

@Component
export default class IntermediaryContact extends Vue {
	@Prop({type: Boolean, required: true}) submitted!: boolean
	@Prop({type: Object, required: true}) customerContactDetails!: CustomerContactDetails

	placeHolderText = "Voor als wij nog vragen hebben"
	emailAddressError = "Vul a.u.b een geldig e-mailadres in."
	mobilePhoneNumberError = "Vul a.u.b een geldig mobiel telefoonnummer in."
	mounted() {
		this.changeContactDetails()
	}

	@Watch("customerContactDetails", { deep: true })
	changeContactDetails() {
		const contactDetailsValid = this.validCustomerEmailAddress && this.validMobilePhoneNumber

		const contactDetailsString = `Uw contactgegevens:\nE-mailadres: ${this.customerContactDetails.emailAddress1}\nTelefoonnummer: ${this.customerContactDetails.telephoneNumberMobile}`

		this.$emit("changedContactDetails", { contactDetailsString, contactDetailsValid, customerContactDetails: this.customerContactDetails })
	}

	get validCustomerEmailAddress() {
		if (this.customerContactDetails.emailAddress1) {
			this.customerContactDetails.emailAddress1 = this.customerContactDetails.emailAddress1.replace(/\s/g, "")
			return email(this.customerContactDetails.emailAddress1)
		}
		return false
	}

	get validMobilePhoneNumber() {
		if (this.customerContactDetails.telephoneNumberMobile) {
			return /^[+]?[0-9]{1,4}[\s\0-9]*$/.test(this.customerContactDetails.telephoneNumberMobile)
		}
		return false
	}

	@Watch("submitted")
	@Watch("validCustomerEmailAddress")
	@Watch("validMobilePhoneNumber")
	checkErrors() {
		if (this.submitted) {
			if (!this.validCustomerEmailAddress) {
				this.$emit('error', { componentUid: 'e-mailadres', errorMessage: this.emailAddressError })
			}
			if (!this.validMobilePhoneNumber) {
				this.$emit('error', { componentUid: 'mobiel nummer', errorMessage: this.mobilePhoneNumberError })
			}
		}
	}
}
