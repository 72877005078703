import { Application } from "../typings/enums"
import { ContactFormSubject } from "../typings/interfaces"
import { TaskType } from "../typings/tasktypes"

type ContactFormSubjects = {
	[key in keyof typeof Application]: Array<ContactFormSubject>
}

export const contactFormSubjects: ContactFormSubjects = {
	[Application.SELFSERVICE]: [
	{
		value: "",
		label: "Kies een onderwerp",
		taskTypeRequest: TaskType.INFO_ALGEMEEN
	},
	{
		value: "stoppen",
		label: "Stoppen met uw levensverzekering",
		taskTypeRequest: TaskType.INFO_AFKOOP
	},
	{
		value: "einddatum",
		label: "Uw levensverzekering bereikt de einddatum",
		taskTypeRequest: TaskType.INFO_EXPIRATIE
	},
	{
		value: "overlijden",
		label: "Overlijden",
		taskTypeRequest: TaskType.INFO_OVERLIJDEN
	},
	{
		value: "wijzigen",
		label: "Uw levensverzekering wijzigen",
		taskTypeRequest: TaskType.INFO_WIJZIGING
	},
	{
		value: "vraag",
		label: "Een andere vraag over uw levensverzekering",
		taskTypeRequest: TaskType.INFO_ALGEMEEN
	}
	],
	[Application.EXTRANET]: [
	{
		value: "",
		label: "Kies een onderwerp",
		taskTypeRequest: TaskType.INFO_ALGEMEEN
	},
	{
		value: "afkoop",
		label: "Stoppen van een levensverzekering",
		taskTypeRequest: TaskType.INFO_AFKOOP
	},
	{
		value: "expiratie",
		label: "Levensverzekering bereikt de einddatum",
		taskTypeRequest: TaskType.INFO_EXPIRATIE
	},
	{
		value: "overlijden",
		label: "Overlijden",
		taskTypeRequest: TaskType.INFO_OVERLIJDEN
	},
	{
		value: "wijziging",
		label: "Wijzigen van een levensverzekering",
		taskTypeRequest: TaskType.WIJZ_OVERIG
	},
	{
		value: "overig",
		label: "Een andere vraag over een levensverzekering",
		taskTypeRequest: TaskType.INFO_ALGEMEEN
	}
	]
}
