import { restApiRootUrl, contentUrl } from "api/settings"
import { getJson, getJsonICS, getBlob, postJsonICS } from "api/request"
import { Communication, ClaimCommunicationHolder, BigCommunication, Message, FAQ, FAQPolicyData, Maintenance } from "api/models/communications"

export default class CommunicationController {

	async getManyPolicy(): Promise<any> {
		return getJson(`${restApiRootUrl}/my-communications/my-policy-communications`)
	}

	async getManyCustomer(): Promise<Array<Communication>> {
		return getJson(`${restApiRootUrl}/my-communications/my-customer-communications`)
	}

	async getManyClaim(): Promise<Array<ClaimCommunicationHolder>> {
		return getJson(`${restApiRootUrl}/my-communications/my-claim-communications`)
	}

	async getOne(communicationExtId: string): Promise<BigCommunication> {
		return getJson(`${restApiRootUrl}/my-communications/${communicationExtId}`)
	}

	async getOnePdf(communicationExtId: string, attachmentExtId: string): Promise<Blob> {
		return getBlob(`${restApiRootUrl}/my-communications/${communicationExtId}/communication-document/${attachmentExtId}/pdf`)
	}

	async getDocumentContent(documentExtId: string): Promise<any> {
		return getJson(`${restApiRootUrl}/my-communications/${documentExtId}`)
	}

	async getAttachment(communicationExtId: string, attachmentExtId: string): Promise<BigCommunication> {
		return getJson(`${restApiRootUrl}/my-communications/${communicationExtId}/communication-document/${attachmentExtId}/pdf`)
	}

	async getMessages(): Promise<Array<Message> | unknown> {
		const data = getJsonICS(`${contentUrl}/messages/active`, undefined)
		// also return an empty array when something fails. It's better to proceed and do all other calls so the customer has a dashboard.
		// And it is also nice to load this data as early as possible because the ICS backend will be much faster than Axon.
		// By calling it early a customer can see this information while Axon data is loading
		return await data.then(() => data).catch(() => [])
	}

	async getGeneralFAQs(): Promise<Array<FAQ> | unknown> {
		const data = getJsonICS(`${contentUrl}/faqs/general`, undefined)
		// also return an empty array when something fails. It's better to proceed and do all other calls so the customer has a dashboard.
		// And it is also nice to load this data as early as possible because the ICS backend will be much faster than Axon.
		// By calling it early a customer can see this information while Axon data is loading
		return await data.then(() => data).catch(() => [])
	}

	async getPolicyFAQs(faqPolicyData: FAQPolicyData): Promise<Array<FAQ> | unknown> {
		const data = postJsonICS(`${contentUrl}/faqs/policydata`, faqPolicyData)
		// also return an empty array when something fails. It's better to proceed and do all other calls so the customer has a dashboard.
		// And it is also nice to load this data as early as possible because the ICS backend will be much faster than Axon.
		// By calling it early a customer can see this information while Axon data is loading
		return await data.then(() => data).catch(() => [])
	}

	async getMaintenance(): Promise<Maintenance | unknown> {
		const data = getJsonICS(`${contentUrl}/maintenance/active`, undefined)
		return await data.then(() => data).catch(() => undefined)
	}

}
