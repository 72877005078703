import {HelpText} from "leven-shared-components/typings/interfaces"

const correspondenceText = 'Deze vindt u op de home pagina onder <a href="communicationdetail.html">Uw correspondentie</a>. Het kan enkele minuten duren voordat het uitkeringsdocument in <a href="communicationdetail.html">Uw correspondentie</a> zichtbaar is.'

const helpTexts: Array<HelpText> = [
	{
		id: "formulierStopzetten",
		text: `U vindt het formulier stopzetten in het uitkeringsdocument. ${correspondenceText}`
	},
	{
		id: "uitkeringsformulier",
		text: `U vindt het uitkeringsformulier in het uitkeringsdocument. ${correspondenceText}`
	},
	{
		id: "verklaringInLeven",
		text: "Vraag de verklaring van in leven zijn aan bij uw gemeente."
	},
	{
		id: "kopieIDBewijs",
		text: "Een duidelijk leesbare kopie van een geldig identiteitsbewijs. In verband met de privacywetgeving vragen wij u om de volgende gegevens af te schermen: het BSN (ook in de cijferreeks onderaan) en de pasfoto."
	},
	{
		id: "akkoordGeldverstrekker",
		text: `U wilt uw verzekering stopzetten en de waarde van de verzekering gebruiken als extra aflossing op uw (hypotheek)lening. U moet daarvoor eerst toestemming vragen aan de geldverstrekker. De geldverstrekker wil meestal weten hoe hoog de waarde van de verzekering is. Daarvoor hebt u het uitkeringsdocument nodig. U vindt het uitkeringsdocument bij 'Uw correspondentie' op het beginscherm (dashboard) in Mijn a.s.r.<br><br>Krijgt u toestemming? Dan stuurt de geldverstrekker dat bericht rechtstreeks naar a.s.r.`
	},
	{
		id: "kopieBankafschriftVzn1",
		text: "Duidelijk leesbare kopie of een schermafdruk van een recent bankafschrift. Het bankafschrift moet van een betaalrekening zijn en mag niet ouder zijn dan drie maanden. De volledige tenaamstelling, het rekeningnummer en de datum moet goed leesbaar zijn.<br><br>Het bankafschrift dient ter verificatie van het identiteitsbewijs en/of als controle van de tenaamstelling.",
		infoComponent: "InformationModalBankStatement"
	},
	{
		id: "UitkeringsformulierBegunstigde",
		text: "Laat de begunstigde het uitkeringsformulier invullen en ondertekenen. Zijn er meerdere begunstigden? Dan ontvangen wij graag van elke begunstigde een eigen formulier."
	}
]

const helpText = (typeExternalIdentifier: HelpText["id"] | undefined): HelpText | undefined =>
	helpTexts.find(text => text.id === typeExternalIdentifier)

export default helpText
