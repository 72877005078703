

























import Vue from "vue"
import Component from "vue-class-component"

import {IconUpload} from "custom/icons"
import {IconClose} from "custom/icons"

@Component({
	components: {
		IconUpload,
		IconClose
	}
})

export default class DropzoneHolder extends Vue {
	file: string | File | null = ""
	fileSize: string = ""
	isValid: boolean = false
	fileBase64: string | ArrayBuffer | null = ""
	tempFileBase: string = ""

	allowedTypes = ["pdf", "doc", "tiff", "tif", "jpg", "jpeg", "docx", "msg", "PDF", "DOC", "TIFF", "TIF", "JPG", "JPEG", "DOCX", "MSG"]
	maxFileSize = 30000000

	onDrop(e: DragEvent) {
		e.stopPropagation()
		e.preventDefault()

		if (e.dataTransfer && e.dataTransfer.files) {
			this.createFile(e.dataTransfer.files[0])
		}
	}

	onChange(e: Event) {
		const input = e.target as HTMLInputElement
		if (input.files) {
			this.createFile(input.files[0])
		}
	}

	createFile(file: File) {
		if (this.validFile(file)) {

			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => (
				this.file = file,
				this.isValid = true,
				this.fileSize = this.formatBytes(Number(file.size), 0),
				this.tempFileBase = reader.result ? reader.result.toString().replace(/^data:.+;base64,/, "") : "",
				this.fileBase64 = this.tempFileBase,
				this.$emit("addedFile", { file: this.file, fileBase64: this.fileBase64 })
			)
		} else {
			this.file = null
			this.isValid = false
			this.$emit("errorUpload")
		}
	}

	validFile(file: File) {
		const fileExtension = file.name.split(".").pop()

		const hasAllowedType = this.allowedTypes.includes(fileExtension!)

		if (hasAllowedType && file.size <= this.maxFileSize) {
			return true
		} else {
			return false
		}
	}

	removeFile() {
		this.file = ""
		this.$emit("removedFile")
	}

	formatBytes(bytes: number, decimals = 2) {
		if (bytes === 0) { return "0 Bytes" }

		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

		const i = Math.floor(Math.log(bytes) / Math.log(k))

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}
}
