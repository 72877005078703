import { restApiRootUrl, restApiAnonymousUrl, customerEnvironmentExtId } from "api/settings"
import { getJson, postWithResponse, put } from "api/request"
import { CustomerResponse, AddressResponse } from "api/models/customer"
import { queryString } from "lib/request/queryParameters"
import { FormType } from "leven-shared-components/typings/enums"
import { MutationPostData } from "leven-shared-components/typings/interfaces"

interface AxonPostResponse {
	message: string | null
}

export default class CustomerController {
	async getOne(customerId: string): Promise<CustomerResponse> {
		return getJson(`${restApiRootUrl}/remote-user/${customerId}`)
	}

	async getCustomerBasicdata(customerExtId: string): Promise<any> {
		const url = `${restApiRootUrl}/mycustomer/${customerExtId}/basicdata`
		const parameters = {
			customerExtId,
			customerEnvironmentExtId
		}
		return getJson(url, parameters)
	}

	async getDocumentContent(documentExtId: string): Promise<any> {
		return getJson(`${restApiRootUrl}/my-communications/${documentExtId}`)
	}

	async getCustomerAddress(customerExtId: string): Promise<any> {
		const url = `${restApiRootUrl}/mycustomer/${customerExtId}/address`
		const parameters = {
			customerExtId,
			customerEnvironmentExtId
		}
		return getJson(url, parameters)
	}

	async getCommunicationPreferences(customerExtId: string): Promise<any> {
		const url = `${restApiRootUrl}/mycustomer/${customerExtId}/communicationpreferences`
		const parameters = {
			customerExtId,
			customerEnvironmentExtId
		}
		return getJson(url, parameters)
	}

	async getAddress(postalCode: string, houseNumber: number): Promise<AddressResponse> {
		const url = `${restApiAnonymousUrl}/postalcode/nl`
		const parameters = {
			postalCode,
			houseNumber
		}
		return getJson(url, parameters)
	}

	async getCountries(): Promise<any> {
		const url = `${restApiAnonymousUrl}/countries/search/minimal`
		return getJson(url)
	}

	async postCustomerBasicData(customerExtId: string, payload: any): Promise<any> {
		const url = `${restApiRootUrl}/mycustomer/${customerExtId}/basicdata`
		const parameters = {
			customerExtId,
			customerEnvironmentExtId
		}
		return put(url + queryString(parameters), payload)
	}

	async postCustomerAddressData(customerExtId: string, payload: any): Promise<any> {
		const url = `${restApiRootUrl}/mycustomer/${customerExtId}/address`
		const parameters = {
			customerExtId,
			customerEnvironmentExtId
		}
		return put(url + queryString(parameters), payload)
	}

	async postCommunicationPreferences(customerExtId: string, payload: any): Promise<any> {
		const url = `${restApiRootUrl}/mycustomer/${customerExtId}/communicationpreferences`
		const parameters = {
			customerExtId,
			customerEnvironmentExtId
		}
		return put(url + queryString(parameters), payload)
	}

	async postForm(customerExtId: string, payload: MutationPostData, formType: FormType): Promise<AxonPostResponse> {

		const body = {
			requestType: formType,
			subject: payload.subject,
			policyRef: { externalIdentifier: payload.policyId },
			message: payload.message
		}

		if (payload.listOfDocuments) {
			Object.assign(body, payload.listOfDocuments)
		}

		if (payload.taskType) {
			Object.defineProperty(body, "taskType", {value: payload.taskType, enumerable: true})
		}

		const url = `${restApiRootUrl}/my-communications/create-and-send-contact-communication`
		const parameters = {
			customerExtId,
			customerEnvironmentExtId
		}
		return postWithResponse(url + queryString(parameters), body)
	}
}
