import {communication as communicationApi} from "api/gateway"
import {Maintenance} from "api/models/communications"
import {convertMaintenance} from "./communication/conversion"

export const checkMaintenance = async (): Promise<Maintenance | undefined> => {
	if (!process.env.SERVER) {
		if (sessionStorage.getItem("impersonation") !== "true") {
			const maintenanceData = convertMaintenance(await communicationApi.getMaintenance())
			return maintenanceData
		}
	}

	return undefined

}
