























import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { NumberType } from "./typings/enums"
import { allowDecimals, formatDecimals, addZeros } from "./utils/validations"
import { toNumber } from "lodash-es"

@Component
export default class DecimalInput extends Vue {
	@Prop({type: String, required: false}) value!: string
	@Prop({type: Number, required: true}) beforeComma!: number
	@Prop({type: Number, required: true}) afterComma!: number
	@Prop({type: Number, required: true}) minimumNumber!: number
	@Prop({type: String, required: true}) numberType!: NumberType
	@Prop({type: Boolean, required: true}) doValidate!: boolean
	@Prop({type: Boolean, required: true}) submitted!: boolean
	@Prop({type: String, required: true}) placeholder!: string
	@Prop({type: Boolean, required: false, default: false}) readonly?: boolean

	allowDecimals = allowDecimals
	formatDecimals = formatDecimals
	addZeros = addZeros

	moneyErrorMessage = `Vul a.u.b. minimaal een bedrag van &euro; ${this.minimumNumber},- in.`
	percentageErrorMessage = `Vul a.u.b. een percentage hoger dan ${this.minimumNumber}% in.`

	helpMessage = ""
	validNumber = false

	@Watch("doValidate", {immediate: true})
	changeValidate() {
		this.validate(this.value)
	}

	get isMoney() {
		return this.numberType === NumberType.MONEY
	}

	validate(value: string | undefined) {
		if (this.doValidate) {
			if (value) {
				const a = value.replace(",", ".")
				const amount = toNumber(a)
				if (amount < this.minimumNumber) {
					this.changeValid(false)
					return false
				}
			} else {
				this.changeValid(false)
				return false
			}
		}
		this.changeValid(true)
		return false
	}

	changeValid(valid: boolean) {
		this.validNumber = valid
		this.$emit("defineValid", valid)
	}

	@Watch("submitted")
	@Watch("validNumber")
	checkErrors() {
		if (this.submitted && !this.validNumber) {
			this.$emit('error', { errorMessage: this.isMoney ? this.moneyErrorMessage : this.percentageErrorMessage })
		}
	}
}
