













import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { MutationType } from "./typings/enums"
import { FormContentTexts } from "./typings/interfaces"

@Component
export default class MessageBox extends Vue {
	@Prop({type: Object, required: true}) messageTexts!: FormContentTexts
	@Prop({type: Boolean, required: true}) submitted!: boolean
	@Prop({type: String, required: true}) mutationType!: MutationType

	message: string = ""
	isRequired = true

	messageLabel = "Uw wijziging"
	messagePlaceholder = "Uw wijziging kunt u hier kwijt."
	errorMessage = `Vul a.u.b. ${this.messageLabel.toLowerCase()} in.`

	mounted() {
		this.defineRequired()
		this.defineTexts()
		this.changeMessage(this.message, this.isValidMessage)
	}

	@Watch("message")
	changeMessageData() {
		this.changeMessage(this.message, this.isValidMessage)
	}

	changeMessage(message: string, messageValid: boolean) {
		this.$emit("changedMessage", { message, messageValid })
	}

	defineTexts() {
		if (this.mutationType !== MutationType.OTHER) {
			this.messageLabel = this.messageTexts.messageLabel
			this.messagePlaceholder = this.messageTexts.placeHolder
		}
	}

	defineRequired() {
		this.isRequired = this.mutationType === MutationType.CONTACTFORM || this.mutationType === MutationType.OTHER
	}

	get isValidMessage() {
		return this.isRequired ? this.message !== "" : true
	}

	@Watch("submitted")
	@Watch("isValidMessage")
	checkErrors() {
		if (this.submitted && !this.isValidMessage) {
			this.$emit('error', { errorMessage: this.errorMessage, componentUid: "uw wijziging" })
		}
	}
}
