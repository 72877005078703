











import Vue from "vue"
import Component from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"

@Component
export default class ProtectedButton extends Vue {
	@Prop({type: Boolean, required: true}) protect!: boolean
	@Prop({type: String, required: true}) title!: string
	@Prop({type: Boolean, required: false, default: false}) showProtectedText!: boolean
	@Prop({type: String, required: false}) childClass!: string

	protectedText = false

	@Watch("showProtectedText")
	setProtectedText() {
		this.protectedText = this.showProtectedText
	}

	showAndClearProtectedText() {
		this.protectedText = true
		setTimeout(() => this.protectedText = false, 3000)
	}
}
