import { ProductCode } from "leven-shared-components/typings/products"

export enum AmountField {
	EXPECTED_ENDCAPITAL = "Verwacht eindkapitaal",
	CURRENT_VALUE = "Waarde",
	ENDCAPITAL = "Eindkapitaal",
	INSTALLMENT_AMOUNT = "Uitkering",
	EXPIRATION_CAPITAL = "Expiratiekapitaal"
}

export interface PolicyAmount {
	field: AmountField
	value: number | string
}

export interface AmountConfig {
	products: Array<ProductCode>
	amountFields: Array<AmountField>
}

export const amountConfig: Array<AmountConfig> = [
	{
		products: [
			ProductCode.UL,
			ProductCode.ULAB,
			ProductCode.ULLL,
			ProductCode.HYB,
			ProductCode.ULVV,
			ProductCode.VG
		],
		amountFields: [
			AmountField.EXPECTED_ENDCAPITAL,
			AmountField.CURRENT_VALUE
		]
	},
	{
		products: [
			ProductCode.DIL
		],
		amountFields: [
			AmountField.INSTALLMENT_AMOUNT
		]
	},
	{
		products: [
			ProductCode.EXP
		],
		amountFields: [
			AmountField.EXPIRATION_CAPITAL
		]
	},
	{
		products: [
			ProductCode.NOM,
			ProductCode.SPH
		],
		amountFields: [
			AmountField.ENDCAPITAL
		]
	},
	{
		products: [
			ProductCode.NOMWT
		],
		amountFields: [
			AmountField.EXPECTED_ENDCAPITAL
		]
	}
]
