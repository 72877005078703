



























import Vue from "vue"
import Component from "vue-class-component"
import { namespace, State } from "vuex-class"
import { Prop } from "vue-property-decorator"
import { ClaimDocument, Policy, UploadInfo } from "store/policies/types"
import helpText from "leven-shared-components/data/documenthelptexts"

import { CustomerState } from "store/customer/types"
import { StoreAction } from "lib/types/vuex"

import LinkButton from "components/button/LinkButton.vue"
import InformationModal from "leven-shared-components/InformationModal.vue"
import InformationLink from "custom/InformationLink.vue"
import DropzoneHolder from "./DropzoneHolder.vue"
import List from "components/generic/List.vue"
import ActionButton from "components/button/ActionButton.vue"
import ProtectedButton from "leven-shared-components/ProtectedButton.vue"
import UsabillaFeedback from "./UsabillaFeedback.vue"
import eventBus from "lib/vue/eventBus"

const routing = namespace("routing")
const policies = namespace("policies")

@Component({
	components: {
		DropzoneHolder,
		List,
		LinkButton,
		ActionButton,
		ProtectedButton,
		UsabillaFeedback,
		InformationModal,
		InformationLink
	}
})

export default class SingleFileUploader extends Vue {
	@Prop({type: Boolean, default: false}) inPage!: boolean
	@Prop({type: String, required: true }) documentId!: string
	@Prop({type: Object, required: false}) activePackageRiskPolicy!: Policy

	@routing.Getter documentsToUpload!: Array<ClaimDocument>
	@routing.Getter("selectedPolicy") policy!: Policy

	@policies.Action uploadDocs!: StoreAction<UploadInfo>
	@policies.Action getOneClaimDocuments!: StoreAction<Policy>

	@State customer!: CustomerState
	@State axonImpersonation!: boolean
	@State impersonation!: boolean

	hasFile = false
	errorInvalidFile = false
	errorSelect = false
	errorUpload = false
	pendingUpload = false

	selectedDocuments = []
	explainedDocuments: Array<number> = []

	uploadInfo: UploadInfo = {
		toUpload: [],
		claimID: "",
		policyID: "",
		fileInfo: {},
		customerExtId: ""
	}

	helpText = helpText

	get documentToUpload(): ClaimDocument | undefined {
		return this.documentsToUpload.find(doc => doc.identifier === this.documentId)
	}

	addedFile(file: any) {
		this.hasFile = true
		this.errorUpload = false
		this.errorInvalidFile = false
		this.uploadInfo.fileInfo = file
	}

	removedFile() {
		this.hasFile = false
	}

	invalidFile() {
		this.errorInvalidFile = true
	}

	explainCheckedDocument(document: ClaimDocument, index: number) {
		this.$emit("selectedDocuments", this.selectedDocuments)
		if (document.name && document.name.includes("ID-bewijs")) {

			if (!this.explainedDocuments.includes(index)) {
				const infoText = `Voor de afhandeling van uw verzoek hebben wij een duidelijk leesbare kopie van een geldig identiteitsbewijs nodig. In verband met de privacywetgeving vragen wij u om de volgende gegevens af te schermen: het BSN (ook in de cijferreeks onderaan) en de pasfoto. Is het kopie identiteitsbewijs niet afgeschermd, dan nemen wij uw verzoek niet in behandeling.<br><br>Op de <a href="https://www.rijksoverheid.nl/onderwerpen/identiteitsfraude/vraag-en-antwoord/veilige-kopie-identiteitsbewijs" target="_blank">website</a> van de Rijksoverheid leest u hoe u een veilige kopie van het identiteitsbewijs maakt.`

				this.explainedDocuments.push(index)

				eventBus.emit("informationModal", "Graag even uw aandacht", document.name, infoText)
			}
		}
	}

	backToDocumentOverview() {
		this.$emit("showDocumentsTable")
	}

	async uploadFile() {
		this.pendingUpload = true

		if (this.hasFile) {
			this.errorUpload = false
			this.selectedDocuments.length <= 0 ? this.errorSelect = true : this.errorSelect = false
			if (this.policy && this.policy.claim) {
				this.uploadInfo.claimID = this.policy.claim.claimNumber,
				this.uploadInfo.policyID = this.policy.policyIdentifier,
				this.uploadInfo.toUpload = this.selectedDocuments
			}
		} else {
			this.errorUpload = true
		}

		this.uploadInfo.customerExtId = this.customer.externalIdentifier

		await this.uploadDocs(this.uploadInfo)
		await this.getOneClaimDocuments(this.policy)
		this.selectedDocuments = []
		this.pendingUpload = false

		const dropZoneHolder = this.$refs.dropzoneholder as DropzoneHolder
		if (dropZoneHolder) {
			dropZoneHolder.removeFile()
		}

		if (this.inPage) {
			if (this.documentsToUpload.length) {
				location.href = "documents.html"
			}
		} else {
			if (this.documentsToUpload.length) {
				this.$emit("showDocumentsTable")
			} else {
				this.$emit("uploadFinished")
			}
		}
	}
}
