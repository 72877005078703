export enum Application {
	EXTRANET = "EXTRANET",
	SELFSERVICE = "SELFSERVICE"
}

export enum FormType {
	CONTACT = "CONTACT",
	POLICY_CHANGE = "POLICY_CHANGE"
}

export enum MutationGroup {
	ADMINISTRATION = "ADMINISTRATION",
	INSURANCE = "INSURANCE",
	CONTACTFORM = "CONTACTFORM"
}

export enum MutationType {
	ADDRESS = "ADDRESS",
	COMMUNICATION_PREFERENCE = "COMMUNICATION_PREFERENCE",
	CONTACTDETAILS = "CONTACTDETAILS",
	COLLECTION_VIA_ASR = "COLLECTION_VIA_ASR",
	CREDENTIALS_MYASR = "CREDENTIALS_MYASR",
	CUSTOMERNAME = "CUSTOMERNAME",
	DECEASE = "DECEASE",
	BANKACCOUNT = "BANKACCOUNT",
	POSTPONE_ENDDATE = "POSTPONE_ENDDATE",
	EXTRA_DEPOSIT = "EXTRA_DEPOSIT",
	MORTGAGE_RATE = "MORTGAGE_RATE",
	PAYMENT_TERM = "PAYMENT_TERM",
	NON_SMOKER_DISCOUNT = "NON_SMOKERS_DISCOUNT",
	PREMIUM_AMOUNT = "PREMIUM_AMOUNT",
	END_PREMIUM = "END_PREMIUM",
	DELETE_INSURED = "DELETE_INSURED",
	POLICYHOLDER = "POLICYHOLDER",
	BENEFICIARY = "BENEFICIARY",
	INVESTMENT_CHOICE = "INVESTMENT_CHOICE",
	END_INSURANCE = "END_INSURANCE",
	OTHER = "OTHER",
	CONTACTFORM = "CONTACTFORM",
	PAYOUT_BENEFICIARY_CONSENT = "PAYOUT_BENEFICIARY_CONSENT",
	PREMIUM_CONTRIBUTOR = "PREMIUM_CONTRIBUTOR",
	DETERMINE_TAX_STATUS = "DETERMINE_TAX_STATUS"
}

export enum RequestType {
	DIGITAL = "DIGITAL",
	PDFUPLOAD = "PDFUPLOAD",
	OFFER = "OFFER",
	OTHERPAGE = "OTHERPAGE"
}

export enum OfferFase {
	REQUEST = "REQUEST",
	APPROVED = "APPROVED"
}

export const enum Term {
	MONTHLY = "per maand",
	QUARTERLY = "per kwartaal",
	HALFYEARLY = "per halfjaar",
	YEARLY = "per jaar"
}

export enum NumberType {
	MONEY = "bedrag",
	PERCENTAGE = "percentage"
}

export enum YesNo {
	YES = "Ja",
	NO = "Nee"
}

export enum HTMLEvent {
	INPUT = "input",
	CHANGE = "change",
	CLICK = "click"
}

export enum EventAction {
	ACC_OPEN = "accordion open",
	ACC_CLOSE = "accordion close",
	NAV_CLICK = "navigation click",
	VIEW_MESSAGE = "bekijk bericht",
	CTA_PRIMAIR = "cta primair",
	CTA_SECUNDAIR = "cta secundair",
	TKST_LNK = "tekstlink",
	DOWNLOAD = "download",
	DROPDOWN_CLICK = "dropdown click",
	LINK_CLICK = "link click",
	CORRESP = "correspondentie",
	DIRECT_ACTION = "directe actie",
	VIEW_POLICY = "bekijk polis click",
	PAYOUT_EXPIRATION = "uitkeren op einddatum click",
	PAYOUT_REDEMPTION = "voortijdig stopzetten click",
	CHANGE_POLICY = "verzekering wijzigen click",
	BTN_CLICK = "button click",
	TAB_CLICK = "tab click",
	RBTN_CLICK = "radio button click",
	PDF = "pdf download",
	CLAIM_CLICK = "Claims click",
	BACK_CLICK = "back click",
	SUBJ_CLICK = "onderwerp click",
	POL_CLICK = "Polissen click",
	TOOLTIP_CLICK = "tooltip click"
}

export enum MortalityBaseSmoker {
	GBMV_00_05_R_POS_0_0_NEG_3_3 = "GBMV 00-05 R pos 0/0 neg -3/-3",
	GBMV_0005_R = "GBMV 0005 R",
	SMOKER = "SMOKER",
	SMOKER_2015 = "Smoker_2015",
	SMOKER_2018 = "Smoker_2018",
	GLI_GBM8590_R = "GLI GBM8590 R",
	GLI_GBM9500_R = "GLI GBM9500 R"
}

export enum MortalityBaseNonSmoker {
	GBMV_00_05_NR_POS_0_0_NEG_3_3 = "GBMV 00-05 NR pos 0/0 neg -3/-3",
	GBMV_0005_NR = "GBMV 0005 NR",
	NON_SMOKER = "NON-SMOKER",
	NSMOKER_2015 = "NSmoker_2015",
	NSMOKER_2018 = "NSmoker_2018",
	GLI_GBM8590_NR = "GLI GBM8590 NR",
	GLI_GBM9500_NR = "GLI GBM9500 NR"
}
