























































import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { isNull } from "lodash-es"
import { paymentTerms } from "leven-shared-components/data/defaults"
import { Term, NumberType } from "leven-shared-components/typings/enums"
import { PolicyData } from "leven-shared-components/typings/interfaces"
import DecimalInput from "leven-shared-components/DecimalInput.vue"
import SelectInput from "leven-shared-components/SelectInput.vue"
import capitalize from "lib/string/capitalize"
import { convertMoney } from "leven-shared-components/typings/convert"
import { addZeros } from "leven-shared-components/utils/validations"

enum FieldLabels {
	NEW_PREMIUM = "Nieuwe premie",
	NEW_PAYMENTTERM = "Nieuwe betaaltermijn"
}

interface PremiumAmountData {
	newPremiumAmount: string | null
	selectedPaymentTerm: Term | null
}

@Component({
	components: {
		DecimalInput,
		SelectInput
	}
})
export default class PremiumAmount extends Vue {
	@Prop({type: Boolean, required: true}) submitted!: boolean
	@Prop({type: Object, required: true}) policyData!: PolicyData

	capitalize = capitalize

	NumberType = NumberType
	FieldLabels = FieldLabels
	paymentTerms = paymentTerms

	minimumAmount = 1
	validNumber = false
	showValidationEqual = false
	showValidationPremiumIncrease = false

	premiumAmountData: PremiumAmountData = {
		newPremiumAmount: null,
		selectedPaymentTerm: null
	}

	async mounted() {
		await Vue.nextTick()
		this.premiumAmountData.selectedPaymentTerm = this.policyData.currentPremiumFrequency || null
		this.changeFields("", false)
	}

	@Watch("premiumAmountData", {deep: true})
	changeSelectedPremiumAmount() {
		let mutation = ""
		let mutationValid = false

		if (this.validSelectedPaymentTerm && this.validNewPremiumAmount) {
			if (this.policyData.currentPremiumTotal) {
				if (this.premiumAmountData.newPremiumAmount === addZeros(this.policyData.currentPremiumTotal.toString().replace(".", ","), 2)) {
						mutationValid = false
						this.showValidationEqual = true
				} else {
						mutationValid = true
						this.showValidationEqual = false
				}
				if (this.policyData.premiumIncreaseNotAllowed &&
					this.premiumAmountData.newPremiumAmount &&
					parseFloat(this.premiumAmountData.newPremiumAmount.replace(",", ".")) > this.policyData.currentPremiumTotal) {
						mutationValid = false
						this.showValidationPremiumIncrease = true
				} else {
						mutationValid = true
						this.showValidationPremiumIncrease = false
				}
			} else {
				mutationValid = true
				this.showValidationEqual = false
				this.showValidationPremiumIncrease = false
			}
		}

		if (mutationValid) {
			mutation = `${FieldLabels.NEW_PREMIUM}: € ${this.premiumAmountData.newPremiumAmount}\n${FieldLabels.NEW_PAYMENTTERM}: ${this.premiumAmountData.selectedPaymentTerm}`
		}
		this.changeFields(mutation, mutationValid)
	}

	changeFields(mutation: string, mutationValid: boolean) {
		this.$emit("changedMutationFields", { mutation, mutationValid })
	}

	get validSelectedPaymentTerm() {
		return !isNull(this.premiumAmountData.selectedPaymentTerm)
	}

	get validNewPremiumAmount() {
		return this.validNumber
	}

	get currentPremium() {
		return this.policyData.currentPremiumTotal ? convertMoney(this.policyData.currentPremiumTotal) : undefined
	}

}
