



























import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { isNull } from "lodash-es"
import { YesNo } from "leven-shared-components/typings/enums"
import { yesNoOptions } from "leven-shared-components/typings/interfaces"
import RadioInput from "leven-shared-components/RadioInput.vue"
import DateInput from "leven-shared-components/DateInput.vue"

enum FieldLabels {
	PAYMENT_ARREARS = "Premieachterstand",
	PREMIUM_DUEDATE = "Premievervaldag achterstand"
}

interface CollectionData {
	paymentArrears: YesNo
	premiumDueDate: string | null
}

@Component({
	components: {
		RadioInput,
		DateInput
	}
})
export default class CollectionViaAsr extends Vue {
	@Prop({type: Boolean, required: true}) submitted!: boolean
	@Prop({type: Array, required: true}) requiredDocuments!: Array<string>

	YesNo = YesNo
	yesNoOptions = yesNoOptions
	FieldLabels = FieldLabels

	collectionData: CollectionData = {
		paymentArrears: YesNo.NO,
		premiumDueDate: null
	}

	requiredDocumentsLocal: Array<string> = []
	validPremiumDueDate = false

	mounted() {
		this.defineCollectionData()
	}

	@Watch("requiredDocuments", { deep: true, immediate: true })
	setRequiredDocuments() {
		if (this.requiredDocuments.length) {
			this.requiredDocumentsLocal = this.requiredDocuments
		}
	}

	@Watch("collectionData", { deep: true })
	changeCollectionData() {
		this.defineCollectionData()
	}

	defineCollectionData() {

		if (this.paymentArrears) {
			this.$emit("changedRequiredDocuments", [])
		} else {
			this.$emit("changedRequiredDocuments", this.requiredDocumentsLocal)
		}

		const mutationValid = this.validPremiumDueDate || !this.paymentArrears

		const premiumDueDate = !isNull(this.collectionData.premiumDueDate) ? `\n${FieldLabels.PREMIUM_DUEDATE}: ${this.collectionData.premiumDueDate}` : ""
		const mutation = `${FieldLabels.PAYMENT_ARREARS}: ${this.collectionData.paymentArrears} ${premiumDueDate}`

		this.$emit("changedMutationFields", { mutation, mutationValid })

	}

	get paymentArrears() {
		return this.collectionData.paymentArrears === YesNo.YES
	}
}
