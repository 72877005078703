export enum ProductCode {
	DIL = "DIL",
	EXP = "EXP",
	HYB = "HYB",
	NOM = "NOM",
	NOMWT = "NOMWT",
	ORV = "ORV",
	ORV1 = "ORV1",
	SPH = "SPH",
	UL = "UL",
	ULAB = "ULAB",
	ULLL = "ULLL",
	ULVV = "ULVV",
	VG = "VG"
}

export const products: Array<{code: ProductCode, name: string}> = [
	{
		code: ProductCode.DIL,
		name: "Direct Ingaande Lijfrente"
	},
	{
		code: ProductCode.EXP,
		name: "Expiratieverzekering"
	},
	{
		code: ProductCode.HYB,
		name: "Hybride verzekering"
	},
	{
		code: ProductCode.NOM,
		name: "Kapitaalverzekering"
	},
	{
		code: ProductCode.NOMWT,
		name: "Kapitaalverzekering winsttransmissie"
	},
	{
		code: ProductCode.ORV,
		name: "Overlijdensrisicoverzekering"
	},
	{
		code: ProductCode.ORV1,
		name: "Overlijdensrisicoverzekering eenjarige premie"
	},
	{
		code: ProductCode.SPH,
		name: "Spaarhypotheekverzekering"
	},
	{
		code: ProductCode.UL,
		name: "Beleggingsverzekering"
	},
	{
		code: ProductCode.ULAB,
		name: "Beleggingsverzekering AB Units"
	},
	{
		code: ProductCode.ULLL,
		name: "Levensloop"
	},
	{
		code: ProductCode.ULVV,
		name: "Kapitaalverzekering Vreemde Valuta"
	},
	{
		code: ProductCode.VG,
		name: "VermogenGarant"
	}
]
