import {Triple} from "./tuple"
import {Function1} from "./function"

/**
 * Takes a value and returns a `T` if conversion is possible. Otherwise returns `undefined`.
 */
export type ConversionFunction<T> = Function1<any, T | undefined>

type Required<T> = T extends undefined ? false : true

/**
 * Maps all fields of an incoming object to an object of type `T`.
 *
 * Fields:
 * 1. The path to the field in the incoming data object. In most cases this is just the field name.
 * 2. The function to convert the incoming value to its proper type.
 * 3. Whether the field is required. Optional fields can be undefined.
 */
export type ConversionMap<T> = {
	// FIXME: when required, the type is now properly checked, but the optional case returns true | false (= boolean).
	[P in keyof T]-?: Triple<string, ConversionFunction<T[P]>, Required<T[P]>>
}

// Helper booleans for defining conversion maps in a more readable way.
export const required = true
export const optional = false
