



















import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {Beneficiaries} from "store/policies/types"

@Component
export default class PolicyPersons extends Vue {
	@Prop({type: String, required: true}) text!: string
	@Prop({type: Map, required: true}) beneficiaries!: Beneficiaries
}
