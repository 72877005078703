




































import Vue from "vue"
import Component from "vue-class-component"
import { State } from "vuex-class"

import { IconAsrLogo } from "custom/icons"
import AccordionList from "custom/AccordionList.vue"
import AccordionItem from "custom/AccordionItem.vue"

import { CustomerState } from "store/customer/types"

import { iWelcomeLogoutUrl, myAccountUrl } from "api/settings"
import { session } from "api/request"

import ProtectedButton from "leven-shared-components/ProtectedButton.vue"

@Component({
	components: {
		IconAsrLogo,
		AccordionList,
		AccordionItem,
		ProtectedButton
	}
})
export default class PageHeader extends Vue {
	@State customer!: CustomerState
	@State impersonation!: boolean

	logoutUrl = iWelcomeLogoutUrl
	myAccountUrl = myAccountUrl

	async logout() {
		await session.logout()
	}

	closeWindow() {
		window.close()
	}

	goToMyAccount() {
		window.location.href = myAccountUrl
	}
}
