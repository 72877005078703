import diff, {Diff} from "lib/date/diff"
import { isNull, toNumber } from "lodash-es"

const isDateFormat = (txtDate: string): boolean => {
	const currVal = txtDate
	if (currVal === "") {
			return false
	}

	const rxDatePattern = /^(\d{1,2})(\-)(\d{1,2})(\-)(\d{4})$/
	const dtArray = currVal.match(rxDatePattern)
	if (dtArray === null) {
			return false
	}

	let dtDay = 0
	let dtMonth = 0
	let dtYear = 0

	dtDay = Number(dtArray[1])
	dtMonth = Number(dtArray[3])
	dtYear = Number(dtArray[5])

	if (dtMonth < 1 || dtMonth > 12) {
			return false
	} else if (dtDay < 1 || dtDay > 31) {
			return false
	} else if ((dtMonth === 4 || dtMonth === 6 || dtMonth === 9 || dtMonth === 11) && dtDay === 31) {
			return false
	} else if (dtMonth === 2) {
		const isleap = (dtYear % 4 === 0 && (dtYear % 100 !== 0 || dtYear % 400 === 0))
		if (dtDay > 29 || (dtDay === 29 && !isleap)) {
				return false
		}
	}
	return true
}

const reverseDate = (date: string): string => {
	return date.split("-").reverse().join("-")
}

interface Evaluation {
	valid: boolean
	message: string
}

const isToday = (today: Date, date: Date) => date.getDate() === today.getDate() && date.getMonth()
=== today.getMonth() && date.getFullYear() === today.getFullYear()

export const formatDate = (date: string) => {
	if (date.length === 8 && isFinite(toNumber(date))) {
		return `${date.slice(0, 2)}-${date.slice(2, 4)}-${date.slice(4, 8)}`
	} else {
		return date
	}
}

export const isNumber = (key: string) => /[0-9\/]+/.test(key)

enum HelpMessage {
	NUMBER_OR_COMMA = "U kunt alleen getallen of een komma invullen.",
	ONE_COMMA = "U kunt slechts 1 komma invullen.",
	MAX_CHARS = "U kunt geen getallen meer invullen.",
	MAX_BEFORE = "U kunt geen extra getallen voor de komma meer invullen.",
	MAX_DECIMALS = "U kunt geen extra decimalen meer invullen."
}

const defineHelpMessage = (event: KeyboardEvent, helpMessage: HelpMessage) => {
	event.preventDefault()
	return helpMessage
}

export const allowDecimals = (event: KeyboardEvent, beforeComma: number, afterComma: number) => {

	const input = event.target as HTMLInputElement
	const value = input.value
	const position = input.selectionStart || 0
	const key = event.key

	if (!isNumber(key) && key !== ",") {
		return defineHelpMessage(event, HelpMessage.NUMBER_OR_COMMA)
	}

	if (value) {
		const [ before, after ] = value.split(",")
		const afterEntered = after || ""
		const beforeReached = before.length >= beforeComma
		const afterReached = afterEntered.length >= afterComma
		const commaPosition = value.indexOf(",")

		if (key === "," && value.match(/,/g) || [].length === 1) {
			return defineHelpMessage(event, HelpMessage.ONE_COMMA)
		}

		if (beforeReached && afterReached) {
			return defineHelpMessage(event, HelpMessage.MAX_CHARS)
		}

		if (beforeReached && isNumber(key) && (commaPosition === -1 || position <= commaPosition)) {
			return defineHelpMessage(event, HelpMessage.MAX_BEFORE)
		}

		if (afterReached && position > commaPosition) {
			return defineHelpMessage(event, HelpMessage.MAX_DECIMALS)
		}
	}

	return ""
}

export const formatDecimals = (value: string, beforeComma: number, afterComma: number) => {
	if (value) {
		let [ before, after ] = value.split(",")

		const beforeReached = before.length >= beforeComma
		const lengthAfter = after ? after.length : 0

		const comma = beforeReached && lengthAfter === 0 || lengthAfter > 0 ? "," : ""

		before = beforeReached ? before.slice(0, beforeComma) : before
		after = lengthAfter === afterComma ? after.slice(0, afterComma) : after || ""

		return `${before}${comma}${after}`
	}

	return ""
}

export const addZeros = (value: string | null, afterComma: number) => {
	if (value) {
		const [ before, after ] = value.split(",")
		const afterEntered = after || ""
		const afterWithZeros = `${afterEntered}${"0".repeat(afterComma - afterEntered.length)}`
		return `${before},${afterWithZeros}`
	}
	return ""
}

export const validateDate = (
	date: string | null, validateFuture: boolean, todayAllowed: boolean, dateToPass?: string | null, dateNotToCross?: string | null): Evaluation => {

	const evaluation: Evaluation = {
		valid: false,
		message: ""
	}

	if (date) {
		const isDate = isDateFormat(date)

		if (isDate) {
			const today = new Date()
			const reversedDate = new Date(reverseDate(date))
			const years = diff(Diff.YEARS, today, reversedDate)
			const months = diff(Diff.MONTHS, today, reversedDate)

			if (dateToPass && !isNull(dateToPass)) {
				const reversedDateToPass = new Date(reverseDate(dateToPass))
				const days = diff(Diff.DAYS, reversedDateToPass, reversedDate)

				if (days > 0) {
					evaluation.valid = true
				} else {
					evaluation.valid = false
					evaluation.message = "Vul a.u.b. een datum voorbij de huidige datum in."
				}
			} else if (validateFuture && (years < 0 || months < 0 || (isToday(today, reversedDate)) && !todayAllowed)) {
				evaluation.valid = false
				evaluation.message = "Vul a.u.b. een datum in de toekomst in."
			} else {
				evaluation.valid = true
			}

			if (dateNotToCross && !isNull(dateNotToCross)) {
				const reversedDateNotToCross = new Date(reverseDate(dateNotToCross))
				const days = diff(Diff.DAYS, reversedDateNotToCross, reversedDate)

				if (days <= 0) {
					evaluation.valid = true
				} else {
					evaluation.valid = false
					evaluation.message = `Vul a.u.b. een datum in niet later dan ${dateNotToCross}`
				}
			}
		} else {
			evaluation.valid = false
			evaluation.message = "Vul a.u.b. een geldige datum in. Een datum is geldig in het volgende formaat: dd-mm-jjjj."
		}
	} else {
		evaluation.valid = false
		evaluation.message = "Vul a.u.b. een datum in."
	}

	return evaluation

}
