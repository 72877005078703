











































































































	import { StoreMutation } from "lib/types/vuex"
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import { namespace, State } from "vuex-class"

	import LinkButton from "components/button/LinkButton.vue"
	import { IconArrow } from "custom/icons"
	import RedeemPackagePolicyModal from "custom/modal/RedeemPackagePolicyModal.vue"
	import UsabillaFeedback from "custom/UsabillaFeedback.vue"
	import { contactFormSubjects } from "leven-shared-components/data/contactformsubjects"
	import { policyData } from "leven-shared-components/data/defaults"
	import { getFormContentTexts, getMutation, getMutationList } from "leven-shared-components/data/formcontent"
	import MutationFormHolder from "leven-shared-components/MutationFormHolder.vue"
	import MutationOverview from "leven-shared-components/MutationOverview.vue"
	import { Application, FormType, MutationType, OfferFase } from "leven-shared-components/typings/enums"
	import { ContactFormSubject, CustomerContactDetails, FormContentTexts, Mutation, MutationPostData } from "leven-shared-components/typings/interfaces"

	import { customer as customerApi } from "api/gateway"
	import { isNil, isUndefined } from "lodash-es"
	import { CustomerState } from "store/customer/types"
	import { Policy, Premiums } from "store/policies/types"

	import { myLoginCredentialsUrl, publicSiteUrl } from "api/settings"

	import eventBus from "lib/vue/eventBus"

	import * as rules from "store/rules"
	import { 
		tealiumInteractionClickableButton, 
		tealiumInteractionClickableDownload, 
		tealiumInteractionClickableLink, 
		tealiumInteractionClickableRadioButton, 
		tealiumInteractionEditableDropdown, 
		tealiumInteractionEditableInputField, 
		tealiumInteractionOtherCheckbox, 
		tealiumNonInteraction, 
		tealiumPageView 
	} from "tracking/events_v4"
	import { TealiumEvent } from "tracking/tealium_v4"
	import { TaskType } from "leven-shared-components/typings/tasktypes"
	
	const policies = namespace("policies")
	const routing = namespace("routing")

	@Component({
		components: {
			MutationOverview,
			MutationFormHolder,
			LinkButton,
			RedeemPackagePolicyModal,
			UsabillaFeedback,
			IconArrow
		}
	})
	export default class MutationFormSwitch extends Vue {
		@Prop({type: String, required: true}) formType!: FormType
		@Prop({type: Object, required: false}) preSelectedMutation!: Mutation

		@State customer!: CustomerState
		@State axonImpersonation!: boolean

		@policies.State policies!: Array<Policy>
		@policies.State policiesLoaded!: boolean
		@routing.Getter("selectedPolicy") policy!: Policy
		@routing.Getter redeemable!: boolean
		@routing.Getter isRecentlyExpiredWithAllowedProduct!: boolean
		@routing.Getter premiums!: Premiums
		@routing.Getter isExcludedFromPayoutProcess!: boolean
		@routing.Mutation selectProfileItem!: StoreMutation<string>
		@routing.Mutation selectWidget!: StoreMutation<"addAfkoop">
		@routing.Mutation selectPolicy!: StoreMutation<Policy>

		activeRelatedAccrualPolicy: Policy | undefined | null = null

		MutationType = MutationType

		policyData = policyData
		policyList: Array<Policy> | undefined = []

		selectedPolicyNumber: string | null = null
		subject = ""
		contactFormSubjects: Array<ContactFormSubject> | null = null

		basicData: CustomerContactDetails = {
			emailAddress1: null,
			telephoneNumberMobile: null
		}

		emailCompare: string | null = null
		phoneCompare: string | null = null

		application: Application = Application.SELFSERVICE
		mutationList: Array<Mutation> | null = null
		formContentTexts: FormContentTexts | null = null

		selectedMutation: Mutation | null = null

		requestedMutationType: string | null = null
		requestedReturnUrl: string | null = null

		dataLoaded = false
		requestSend = false
		apiErrorMessage: string | null = null

		offerFase: OfferFase | null = null

		get buttonBarInactive() {
			return this.selectedMutation?.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT && this.requestSend
		}

		async mounted() {
			await Vue.nextTick()

			const params = new URLSearchParams(window.location.search.substring(1))

			if (this.customer.externalIdentifier) {
				this.dataLoaded = false

				this.basicData = await customerApi.getCustomerBasicdata(this.customer.externalIdentifier)
				this.emailCompare = this.basicData.emailAddress1
				this.phoneCompare = this.basicData.telephoneNumberMobile

				if (!this.basicData.emailAddress1) {
					this.basicData.emailAddress1 = this.customer.userIdentifierExternalSystem || ""
				}

				this.formContentTexts = getFormContentTexts(this.formType, this.application)

				if (this.formType === FormType.CONTACT) {
					this.selectedMutation = getMutation(MutationType.CONTACTFORM)

					if (this.policies && this.policies.length > 0) {
						if (this.policies.length === 1) {
							this.selectPolicy(this.policies[0])
							this.setPolicyData(this.policies[0])
						} else {
							this.policyList = this.policies
						}
					}

				} else {
					this.selectedMutation = this.preSelectedMutation

					if (this.policies && this.policies.length > 0) {
						this.activeRelatedAccrualPolicy = this.getActivePackageAccrualPolicy(this.policy)
					}

					this.setPolicyData(this.policy)
				}

				this.requestedMutationType = params.get("mutationType")
				this.requestedReturnUrl = params.get("returnUrl")

				const customerAddressData = await customerApi.getCustomerAddress(this.customer.externalIdentifier)
				const customerCountryCode = customerAddressData.address.countryRef.country.externalIdentifier

				if ((this.requestedMutationType && this.requestedMutationType in MutationType) || this.requestedReturnUrl) {
					this.requestedReturnUrl = this.requestedReturnUrl ?? "index.html"
					this.selectedMutation = getMutation(this.requestedMutationType as MutationType, this.policy.productId, undefined, customerCountryCode)
					history.replaceState("", "", window.location.pathname)
				}

				this.mutationList = this.selectedMutation ? null : getMutationList(this.application, this.policy.productId, customerCountryCode)

				this.dataLoaded = true

				location.hash = ""
			}

			if (this.dataLoaded && this.selectedMutation && this.selectedMutation.mutationType === MutationType.CONTACTFORM) {
				this.contactFormSubjects = contactFormSubjects[this.application]

				this.subject = params.get("subject") ?? ""
			}
		}

		setPolicyData(policy: Policy) {
			this.policyData.policyIdentifier = policy.policyIdentifier
			this.policyData.productName = policy.product
			this.policyData.productCode = policy.productId
			this.policyData.status = policy.status
			this.policyData.clause = policy.clause
			this.policyData.policyVersionExtId = policy.policyVersionExtId
			this.policyData.policyEnddate = policy.endDate

			this.policyData.policyHolder.personID = policy.policyHolderExtId
			this.policyData.policyHolder.fullName = policy.policyHolderFullName

			if (!isUndefined(policy.coPolicyHolderExtId)) {
				this.policyData.coPolicyHolder.personID = policy.coPolicyHolderExtId
				this.policyData.coPolicyHolder.fullName = policy.coPolicyHolderFullName
				this.policyData.coPolicyHolderExists = true
			}

			this.policyData.insured.personID = policy.insuredExtId
			this.policyData.insured.fullName = policy.insuredFullName
			this.policyData.insured.mortalityBase = policy.policyHolderMortalityBase
			this.policyData.insured.insuredType = "Verzekerde"
			this.policyData.insured.insuredDateOfBirth = policy.insuredDateOfBirth

			if (!isUndefined(policy.coInsuredExtId)) {
				this.policyData.coInsured.personID = policy.coInsuredExtId
				this.policyData.coInsured.fullName = policy.coInsuredFullName
				this.policyData.coInsured.mortalityBase = policy.coPolicyHolderMortalityBase
				this.policyData.coInsured.insuredType = "Medeverzekerde"
				this.policyData.multipleInsured = true
			}

			if (this.isSelfService) {
				this.policyData.isPolicyHolderLoggedIn = this.policyHolderLoggedInStatus
			}

			this.policyData.redeemable = this.redeemable
			this.policyData.currentPremiumFrequency = this.premiums.currentFrequency
			this.policyData.currentPremiumTotal = this.premiums.currentTotal
			this.policyData.multiplePremiums = this.premiums.multiplePremiums
			this.policyData.isRecentlyExpiredWithAllowedProduct = this.isRecentlyExpiredWithAllowedProduct
			this.policyData.premiumIncreaseNotAllowed = policy.premiumIncreaseNotAllowed
			this.policyData.extensionNotAllowed = policy.extensionNotAllowed
			this.policyData.hasWisselDatumClause = policy.hasWisselDatumClause
			this.policyData.sourceSystem = policy.sourceSystem
			this.policyData.costAndProvisionVariant = policy.costAndProvisionVariant
			this.policyData.hasPackageAccrualPolicyToRedeem = this.setHasPackageAccrualPolicyToRedeem(policy)
		}

		updateCustomerBasicData(customerContactDetails: CustomerContactDetails) {
			this.basicData = customerContactDetails
		}

		selectMutation(mutation: Mutation | null) {
			this.requestSend = false
			this.apiErrorMessage = null

			if (mutation) {
				tealiumInteractionClickableLink(this.getTealiumPageInfo(), "internal", undefined, `/${mutation.path}`, mutation.subject)
				this.tealiumPageViewEvent(mutation)
			} else {
				location.hash = ""
			}

			this.selectedMutation = mutation
		}

		setProfilePageTab(profilePageTab: string) {
			this.selectProfileItem(profilePageTab)
			location.href = "profile.html"
		}

		goToProfilePage(event: MouseEvent) {
			tealiumInteractionClickableButton(this.getTealiumPageInfo(), 'primary', event)
		}

		setHasPackageAccrualPolicyToRedeem(policy: Policy) {
			return policy.packagePolicy && rules.activePackageRiskPolicy(policy) && this.activeRelatedAccrualPolicy !== undefined || false
		}

		backToHome(event: MouseEvent) {
			tealiumInteractionClickableButton(this.getTealiumPageInfo(), "secondary", event)
			location.href = "index.html"
		}

		goToWidget(event?: MouseEvent) {
			this.selectWidget("addAfkoop")

			if (event) {
				tealiumInteractionClickableButton(this.getTealiumPageInfo(), "primary", event)
			}

			if (this.isExcludedFromPayoutProcess) {
				location.href = "widget.html"
			} else {
				location.href = "payoutprocess.html"
			}
		}

		goToChangeLogin() {
			location.href = myLoginCredentialsUrl
		}

		goToRelativesDesk(event: MouseEvent) {
			tealiumInteractionClickableButton(this.getTealiumPageInfo(), 'primary', event)

			location.href = `${publicSiteUrl}/service/nabestaandendesk`
		}

		goToContactForm(event: MouseEvent) {
			tealiumInteractionClickableLink(this.getTealiumPageInfo(), 'internal', event)
		}

		goBack(event: MouseEvent, action?: () => void) {
			tealiumInteractionClickableButton(this.getTealiumPageInfo(), "secondary", event, "terug")
			action?.()
		}

		getTealiumPageInfo(mutation?: Mutation, subTabName?: string, subTabPath?: string) {
			const currentMutation = this.selectedMutation ?? mutation
			let virtualPagePath = undefined
			let virtualPageName = undefined
			let virtualTabPath = undefined
			let virtualTabName = currentMutation?.subject
			let virtualSubTabPath = subTabPath
			let virtualSubTabName = subTabName

			switch (currentMutation?.mutationType)
			{
				case MutationType.PREMIUM_AMOUNT:
					{
						virtualTabName = 'Premie aanpassen'
						break
					}
				case MutationType.END_PREMIUM:
					{
						virtualTabPath = '/stoppen-premie-betalen'
						break
					}
				case MutationType.OTHER:
				case MutationType.CUSTOMERNAME:
				case MutationType.ADDRESS:
				case MutationType.CONTACTDETAILS:
				case MutationType.BANKACCOUNT:
				case MutationType.COMMUNICATION_PREFERENCE:
				case MutationType.DECEASE:
					{
						virtualPagePath = '/verzekering-wijzigen/wijzig-gegevens'
						virtualPageName = 'Verzekering wijzigen - Wijzig gegevens'
						if (currentMutation.mutationType === MutationType.COMMUNICATION_PREFERENCE) {
							virtualTabName = 'Contact via post of email'
						}
						break
					}
				case MutationType.CREDENTIALS_MYASR:
					{
						virtualTabName = 'Inloggegevens Mijn account'
						break
					}
			}

			return {
                virtualPage: {
					path: virtualPagePath,
					name: virtualPageName ?? 'Verzekering wijzigen'
				},
                virtualTab: {
					path: virtualTabPath,
					name: virtualTabName
				},
                virtualSubTab: {
					path: virtualSubTabPath,
					name: virtualSubTabName
				}
            }
		}

		tealiumPageViewEvent(mutation: Mutation, subTabName?: string, subTabPath?: string, offerFase?: OfferFase) {
			this.offerFase = offerFase ?? null
			tealiumPageView(this.getTealiumPageInfo(mutation, subTabName, subTabPath))
		}

		downloadFormButtonClicked(event: MouseEvent) {
			tealiumInteractionClickableButton(this.getTealiumPageInfo(), "primary", event)
		}

		uploadFormClicked(event: MouseEvent) {
			tealiumInteractionClickableButton(this.getTealiumPageInfo(), "primary", event)
		}

		emailBankAccountFormClicked(event: MouseEvent) {
			tealiumInteractionClickableButton(this.getTealiumPageInfo(), "primary", event)
		}

		downloadFormLinkClicked(event: MouseEvent) {
			tealiumInteractionClickableDownload(this.getTealiumPageInfo(undefined, "Upload formulier"), event)
		}

		requiredFileChecked({ event, textContent }: { event: MouseEvent, textContent: string }) {
			tealiumInteractionOtherCheckbox(this.getTealiumPageInfo(undefined, "Upload formulier"), event, textContent)
		}

		openSelectInput({ label, textContent }: { event: MouseEvent, label: string, textContent: string }) {
			tealiumInteractionEditableDropdown(this.getTealiumPageInfo(undefined, "Upload formulier"), "open", label, textContent)
		}

		updateSelectInput({ label, textContent }: { event: MouseEvent, label: string, textContent: string }) {
			tealiumInteractionEditableDropdown(this.getTealiumPageInfo(undefined, "Upload formulier"), "click", label, textContent)
		}

		focusInputField({ label, isRequired }: { label: string, isRequired: boolean }) {
			tealiumInteractionEditableInputField(this.getTealiumPageInfo(undefined, "Upload formulier"), label, isRequired)
		}

		updateRadioButton({ event, label }: { event: MouseEvent, label: string }) {
			tealiumInteractionClickableRadioButton(this.getTealiumPageInfo(undefined, "Upload formulier"), event, label)
		}

		changeBeneficiaryClicked({ event, targetLink }: { event: MouseEvent, targetLink: string }) {
			tealiumInteractionClickableLink(this.getTealiumPageInfo(undefined, "Upload formulier"), "internal", event, targetLink)
		}

		submitClicked({ event, eventType }: { event: MouseEvent, eventType: TealiumEvent.Interaction.Clickable.Button.EventType }) {
			tealiumInteractionClickableButton(this.getTealiumPageInfo(undefined, "Upload formulier"), eventType, event)
		}

		error({ componentUid, errorMessage }: { componentUid: string, errorMessage: string }) {
			tealiumNonInteraction(this.getTealiumPageInfo(undefined, "Upload formulier"), errorMessage, componentUid)
		}

		getContactSubjectLabel() {
			return this.contactFormSubjects ? this.contactFormSubjects.find(item => item.value === this.subject)!.label : "Contactformulier"
		}

		getActivePackageAccrualPolicy(item: Policy) {
			const isActivePackageAccrualPolicy = (policy: Policy): boolean =>
				rules.packageRiskPolicy(item) ?
					policy.packageNumber === item.packageNumber && rules.packageAccrualPolicy(policy) && rules.redeemable(policy) :
					false

			return this.policies.find(isActivePackageAccrualPolicy)
		}

		showRedeemPackagePolicyModal() {
			eventBus.emit("redeemPackagePolicyModal")
		}

		startWidgetWithRedemptionOfPackagePolicy() {
			this.selectPolicy(this.activeRelatedAccrualPolicy!)
			this.goToWidget()
		}

		isMutationMessageComplaint(payload: MutationPostData): boolean {
			return payload.message.toLowerCase().includes('klacht')
		}

		get isSelfService() {
			return this.application === Application.SELFSERVICE
		}

		get changedEmail() {
			return this.emailCompare !== this.basicData.emailAddress1
		}

		get changedPhone() {
			return this.phoneCompare !== this.basicData.telephoneNumberMobile
		}

		get policyHolderLoggedInStatus() {
			return this.policy ? this.customer.externalIdentifier === this.policy.policyHolderExtId : false
		}

		async sendFormData(formData: MutationPostData) {
			if (this.customer.externalIdentifier) {

				if (this.changedEmail || this.changedPhone) {
					await customerApi.postCustomerBasicData(this.customer.externalIdentifier, this.basicData)
				}

				if (this.formType === FormType.CONTACT && this.isMutationMessageComplaint(formData)) {
					formData.taskType = TaskType.KLACHT
				}

				const formtypeToPost = this.selectedMutation?.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT ? FormType.CONTACT : this.formType

				const response = await customerApi.postForm(this.customer.externalIdentifier, formData, formtypeToPost)

				if (!(response && isNil(response.message))) {
					this.requestSend = false
					this.apiErrorMessage = response.message
				} else {
					if (this.selectedMutation?.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT) {
						this.$emit("setPayoutFormSend")
					} else {
						this.requestSend = true
						this.apiErrorMessage = null
					}
					if (this.selectedMutation) {
						let subTabPath: string | undefined = undefined
						let subTabName: string
						switch (this.offerFase) {
							case OfferFase.REQUEST: {
								subTabPath = '/vraag-offerte-aan/bedankt'
								subTabName = 'Vraag offerte aan - Bedankt'
								break
							}
							case OfferFase.APPROVED: {
								subTabPath = '/upload-offerte/bedankt'
								subTabName = 'Upload offerte - Bedankt'
								break
							}
							default: {
								subTabName = 'Bedankt'
							}
						}
						this.tealiumPageViewEvent(this.selectedMutation, subTabName, subTabPath)
					}
				}
			}
		}
	}
