import { Term } from "leven-shared-components/typings/enums"
import { InsuredPerson, MutationPostData, PolicyData } from "../typings/interfaces"

export const emptyInsured: InsuredPerson = {
	personID: undefined,
	fullName: undefined,
	insuredType: undefined,
	mortalityBase: undefined,
	insuredDateOfBirth: undefined
}

export const policyData: PolicyData = {
	policyIdentifier: "",
	productCode: undefined,
	productName: "",
	status: undefined,
	clause: undefined,
	policyVersionExtId: "",
	policyEnddate: undefined,
	policyHolder: {
		personID: undefined,
		fullName: undefined
	},
	coPolicyHolder: {
		personID: undefined,
		fullName: undefined
	},
	coPolicyHolderExists: false,
	isPolicyHolderLoggedIn: false,
	insured: {...emptyInsured},
	coInsured: {...emptyInsured},
	multipleInsured: false,
	redeemable: false,
	intermediary: undefined,
	currentPremiumFrequency: undefined,
	currentPremiumTotal: undefined,
	multiplePremiums: false,
	isRecentlyExpiredWithAllowedProduct: false,
	premiumIncreaseNotAllowed: false,
	extensionNotAllowed: false,
	hasWisselDatumClause: false,
	sourceSystem: undefined,
	costAndProvisionVariant: undefined,
	hasPackageAccrualPolicyToRedeem: false
}

export const mutationPostData: MutationPostData = {
	subject: "",
	policyId: "",
	message: "",
	listOfDocuments: null
}

export const paymentTerms: Array<Term> = [
	Term.MONTHLY,
	Term.QUARTERLY,
	Term.HALFYEARLY,
	Term.YEARLY
]
