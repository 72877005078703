































import Vue from "vue"
import Component from "vue-class-component"
import { publicSiteUrl, publicCorporateUrl } from "api/settings"

@Component
export default class PageFooter extends Vue {
	publicSiteUrl = ""
	publicCorporateUrl = ""

	async mounted() {
		await this.$nextTick()

		this.publicSiteUrl = publicSiteUrl
		this.publicCorporateUrl = publicCorporateUrl
	}
}
