import {IdentificationStrategy} from "lib/types/security"
import RequestError from "lib/request/RequestError"
import RequestStrategy from "lib/request/RequestStrategy"
import {getJsonICS} from "api/request"
import {ConversionMap, ConversionFunction, required} from "lib/types/import"
import {many} from "lib/import/extract"
import {bool} from "lib/import/convert"

interface ActiveSession {
	active: boolean
}

const activeSession: ConversionMap<ActiveSession> = {
	active: ["active", bool, required]
}

const activeSessionArray = many(activeSession)
export const convertActiveSession: ConversionFunction<Array<ActiveSession>> = value => activeSessionArray(value)

/**
 * Identification strategy for external providers.
 *
 * The client should be redirected to the provider first. Upon return, this strategy tries to obtain a token from the server using
 * the `tokenEndpoint`. This token should be used during the session, not the token from the provider.
 *
 * The credentials type `C` should be `null` if the provider token exists in the cookie. This is the default type.
 */
export default class ProviderIdentification<C = null> implements IdentificationStrategy<C> {
	private identified = false
	constructor(
		private readonly gateway: RequestStrategy,
		private readonly tokenEndPoint: string,
		private readonly introSpectEndpoint: string
		) {}
	get isIdentified(): boolean {
		return this.identified
	}
	async unidentify(): Promise<boolean> {
		this.identified = false
		return true
	}
	async identify(credentials: C): Promise<any> {
		const response = await this.gateway.request("POST", this.tokenEndPoint, credentials)
		if (!response.ok) {
			throw new RequestError(response)
		}
		const token = await this.extractToken(response)
		this.identified = true
		return token
	}
	get identifier(): string | undefined {
		return undefined
	}
	async introspectSession(): Promise<any> {
		const response = await getJsonICS(this.introSpectEndpoint, undefined)
		const validSession = await convertActiveSession(response)

		if (!validSession || validSession && !validSession[0].active) {
			return false
		}

		return true
	}
	/**
	 * Returns the token from the response. Override this method for custom extraction.
	 */
	protected extractToken(response: Response): Promise<any> {
		return response.json()
	}
}
