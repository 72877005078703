













import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"

declare global {
	interface Window {
		usabilla: any
	}
}
declare const usabilla: any

type UsabillaEvent = "CHANGE_INSURANCE" | "END_INSURANCE" | "CHANGE_CUSTOMERDATA"

interface UsabillaWidget {
	event: UsabillaEvent
	loadId: string
	widgetId: string
	category: string
	subcategory: string
	item: string
	siteBusinessLine: string
	pageType: string
	productName: string
	customerSegment: string
}

const usabillaWidgets: Array<UsabillaWidget> = [
	{
		event: "CHANGE_INSURANCE",
		loadId: "64f1d03eb8da",
		widgetId: "605060ad162b9e5e006d996e",
		category: "CES",
		subcategory: "Leven",
		item: "levensverzekering wijzigen",
		siteBusinessLine: "leven",
		pageType: "",
		productName: "",
		customerSegment: ""
	},
	{
		event: "END_INSURANCE",
		loadId: "0156de044165",
		widgetId: "6142eb83376bc277eb02b23b",
		category: "CES",
		subcategory: "Leven",
		item: "Levensverzekering uitkeren",
		siteBusinessLine: "leven",
		pageType: "",
		productName: "",
		customerSegment: ""
	},
	{
		event: "CHANGE_CUSTOMERDATA",
		loadId: "64f1d03eb8da",
		widgetId: "6050595e19f95b3bb36e4022",
		category: "CES",
		subcategory: "Leven",
		item: "persoonlijke gegevens wijzigen",
		siteBusinessLine: "leven",
		pageType: "",
		productName: "",
		customerSegment: ""
	}
]

@Component
export default class UsabillaFeedback extends Vue {
	@Prop({type: String, required: true}) event!: UsabillaEvent

	usabillaWidget: UsabillaWidget | undefined | null = null

	async beforeMount() {
		await Vue.nextTick()

		this.usabillaWidget = usabillaWidgets.find(widget => widget.event === this.event)
	}

}
