import { AuthorizationStrategy } from "lib/types/security"
import { StorageOptions } from "lib/types/storage"
import { RequestMethod, Parameters } from "lib/types/request"
import { queryString } from "lib/request/queryParameters"
import { AxonToken, AxonParameters, AxonHeaders } from "api/types"
import HttpStatus from "lib/request/status"
import AxonIdentification from "./AxonIdentification"
import { customerEnvironmentExtId } from "api/settings"

const isAxonToken = (authorization: any): authorization is AxonToken =>
	!!authorization && authorization.xsrfToken && authorization.clientIdentifier && authorization.customerExtId

const DEFAULT_KEY = "axon"

export default class AxonAuthorization implements AuthorizationStrategy {
	private _authorization: AxonToken | null = null
	private headers?: AxonHeaders
	private parameters?: AxonParameters
	private readonly key: string

	constructor(private readonly options: StorageOptions, private readonly identification: AxonIdentification) {
		this.key = options.key || DEFAULT_KEY
		const data = options.storage.retrieve(this.key)
		this.authorize(data)
	}

	private get authorization(): AxonToken | null {
		return this._authorization
	}

	private set authorization(authorization: AxonToken | null) {
		this._authorization = authorization
		if (authorization) {
			this.options.storage.store(this.key, authorization)
		} else {
			this.options.storage.discard(this.key)
		}
	}

	authorize(authorization: unknown): boolean {
		if (isAxonToken(authorization)) {
			this.authorization = authorization
			this.headers = {
				"X-Quinity-XSRF-Token": authorization.xsrfToken,
				"X-Quinity-Client-Identifier": authorization.clientIdentifier
			}
			this.parameters = {
				customerExtId: authorization.customerExtId,
				customerEnvironmentExtId
			}
			return true
		}
		return false
	}

	unauthorize(): boolean {
		this.authorization = null
		return true
	}

	get isAuthorized(): boolean {
		return !!this.authorization
	}

	isAuthorizedAll(_roles: ReadonlyArray<string>): boolean {
		return this.isAuthorized
	}

	isAuthorizedAny(_roles: ReadonlyArray<string>): boolean {
		return this.isAuthorized
	}

	async request(method: RequestMethod, input: RequestInfo, data?: Parameters): Promise<Response> {
		const query = (method === "GET" || method === "HEAD") ? queryString({...this.parameters, ...data}) : ""
		const response = await this.fetch(method, input + query, data)

		switch (response.status) {
			case HttpStatus.UNAUTHORIZED: {
				if (this.isAuthorized) {
					const authorization = await this.identification.fetchSsoCredentials()
					if (this.authorize(authorization)) {
						return this.fetch(method, input + query, data)
					}
				}
				// Fall through
			}
			case HttpStatus.FORBIDDEN: {
				this.unauthorize()
				break
			}
		}

		return response
	}

	private fetch(method: RequestMethod, input: string, data?: Parameters): Promise<Response> {
		return fetch(input, {
			method,
			headers: {
				"Content-Type": "application/json",
				...this.headers
			},
			mode: "cors",
			credentials: "include",
			body: method === "GET" || method === "HEAD" ? undefined : JSON.stringify(data)
		})
	}

}
