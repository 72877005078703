import Vue, {VueConstructor} from "vue"
import Session from "./Session"

export default (session: Session<any>, roles?: ReadonlyArray<string>) => <V extends VueConstructor<Vue>, W extends V>(component: V): W => {
	return Vue.extend({
		extends: component,
		mixins: [
			{
				async created() {
					await session.verify(roles)
				}
			}
		]
	}) as W
}
