import { ConversionFunction } from "lib/types/import"
import { isNumber, isDate, isString, isArray, isNil, isUndefined } from "lodash-es"
import { date } from "lib/import/convert"
import { ProductCode } from "./products"
import { locale } from "lib/i18n/locale/nl-NL"
import dateFormatter from "lib/i18n/format/date"
import moneyFormatter from "lib/i18n/format/money"
import { Term } from "./enums"
import { clauses, Clause, PolicyStatus } from "./interfaces"

const objectsHaveSameKeys = (...objects: any) => {
	const allKeys = objects.reduce((keys: any, object: any) => keys.concat(Object.keys(object)), [])
	const union = new Set(allKeys)
	return objects.every((object: any) => union.size === Object.keys(object).length)
}
const dateObject = {
	year: "",
	month: "",
	day: ""
}

export const epochDate: ConversionFunction<Date> = value => {
	if (isNumber(value)) {
		const unixEpochTimeMS = value
		const d = new Date(unixEpochTimeMS)
		return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate())
	} else if (isString(value)) {
		return date(value)
	} else if (isArray(value)) {
		return date(value)
	} else if (isDate(value)) {
		return value
	} else if (!isNil(value) && !isUndefined(value) && objectsHaveSameKeys(dateObject, value)) {
		return new Date(value.year, value.month, value.day, 0, 0, 0, 0)
	}
	return undefined
}

export const productCode: ConversionFunction<ProductCode> = value => value
export const convertDate = (value: Date) => dateFormatter(locale)("short")(value)
export const convertMoney = (value: number) => moneyFormatter(locale)(value)
export const cleanFrequency = (frequency: string | undefined): Term | undefined => frequency?.replace("1 keer ", "") as Term || undefined
export const defineClause = (clauseName: string): Clause | undefined => clauses.find(clause => clause.nameAxon === clauseName)
export const status: ConversionFunction<PolicyStatus> = value =>
	value === "Actief" ? PolicyStatus.ACTIVE :
	value === "Beëindigd" ? PolicyStatus.EXPIRED :
	value === "Toekomstig" ? PolicyStatus.FUTURE :
	undefined
