











import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class PolicyIntro extends Vue {
	@Prop({default: false, type: Boolean}) fullWidth!: boolean
}
