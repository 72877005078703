import checkmark from "assets/svg/checkmark.svg?inline"
import close from "assets/svg/close.svg?inline"
import arrow from "assets/svg/arrow.svg?inline"
import asrLogo from "assets/svg/logo-asr.svg?inline"
import upload from "assets/svg/upload.svg?inline"

// tslint:disable:variable-name
export const IconArrow = arrow
export const IconCheckmark = checkmark
export const IconClose = close
export const IconAsrLogo = asrLogo
export const IconUpload = upload
