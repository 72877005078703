




















import Vue from "vue"
import Component from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"

@Component
export default class AccordionItem extends Vue {
	@Prop({type: String, required: true}) id!: string
	@Prop({type: String, required: true}) clicked!: string
	@Prop({type: Boolean, default: false, required: false}) opened!: boolean
	@Prop({type: Boolean, default: false, required: false}) single!: boolean
	@Prop({type: String, required: false}) tealiumTitle!: string

	open = false

	async mounted() {
		await Vue.nextTick()
		this.open = this.opened
	}

	@Watch("clicked")
	changedSingle() {
		this.open = this.id !== this.clicked ? false : true
	}

	uncheck(event: MouseEvent) {
		this.open = !this.open
		this.$emit("toggle", this.open, event)
	}
}
