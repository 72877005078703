































































import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { ClaimDocument } from "store/policies/types"

import ActionButton from "components/button/ActionButton.vue"
import LinkButton from "components/button/LinkButton.vue"
import helpText from "leven-shared-components/data/documenthelptexts"
import InformationModal from "leven-shared-components/InformationModal.vue"
import InformationLink from "custom/InformationLink.vue"

@Component({
	components: {
		ActionButton,
		LinkButton,
		InformationModal,
		InformationLink
	}
})

export default class DocumentsTable extends Vue {
	@Prop({type: Array, required: true }) data!: Array<ClaimDocument>
	@Prop({type: Boolean, default: false}) inPage!: boolean

	helpText = helpText

	get documentsToUpload(): Array<ClaimDocument> {
		return this.data.filter(document => document.statusId === "toBeReceived")
	}
	get documentsUploaded(): Array<ClaimDocument> {
		return this.data.filter(document => (document.statusId === "awaitingApproval" || document.statusId === "accepted") && document.name)
	}

	uploadSingleFile(documentId: string, event: MouseEvent) {
		this.$emit("uploadSingleFile", documentId)
		this.uploadClicked(event)
	}

	uploadClicked(event: MouseEvent) {
		this.$emit("uploadClicked", { event })
	}

	tooltipClicked() {
		this.$emit("tooltipClicked")
	}
}
