















import Vue from "vue"
import Component from "vue-class-component"

import bankStatementsHelpTexts from "leven-shared-components/data/bankStatementsHelpTexts"

@Component
export default class InformationModalBankStatement extends Vue {
	bank: string = ""

	bankStatementsHelpTexts = bankStatementsHelpTexts

	get selectedInstruction() {
		const selectedInstruction = bankStatementsHelpTexts.specificInstructionPerBank.find(item => item.value === this.bank)

		return selectedInstruction ? selectedInstruction.instructionText : undefined
	}
}
