




























































































import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { namespace } from "vuex-class"

import { ClaimDocument, Policy } from "store/policies/types"

import DocumentsTable from "custom/DocumentsTable.vue"
import UsabillaFeedback from "custom/UsabillaFeedback.vue"

import * as rules from "store/rules"

const routing = namespace("routing")

@Component({
	components: {
		DocumentsTable,
		UsabillaFeedback
	}
})
export default class WidgetThankYou extends Vue {
	@Prop({type: Boolean, default: false}) inPage!: boolean
	@Prop({type: Boolean, required: true}) initiatedByAdvisor!: boolean
	@Prop({type: Object, required: false}) activePackageRiskPolicy!: Policy

	@routing.State selectedWidget?: "addAfkoop" | "addExpiratie" | "complete"
	@routing.Getter approved!: boolean
	@routing.Getter documentsToUpload!: Array<ClaimDocument>
	@routing.Getter pendingClaim!: boolean
	@routing.Getter pendingDone!: boolean
	@routing.Getter selectedPolicy?: Policy

	get expirationClaimWithSourceSystemABC() {
		return this.selectedPolicy && rules.hasExpirationClaim(this.selectedPolicy) && this.selectedPolicy.sourceSystem === "ABC_KAS"
	}
}
