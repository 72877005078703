export enum TaskType {
	WIJZ_INCASSO = "WijzIncasso",
	WIJZ_BEGUNSTIGING = "WijzBegunstiging",
	WIJZ_BELEGGINGSKEUZE = "WijzBeleggingskeuze",
	WIJZ_BETAALTERMIJN = "WijzBetaaltermijn",
	OFFERTE_DUUR = "OfferteDuur",
	WIJZ_DUUR = "WijzDuur",
	OFFERTE_EXTRASTORTING = "OfferteExtraStorting",
	WIJZ_EXTRASTORTING = "WijzExtraStorting",
	PREMIE_BERHYPRENTE = "PremieBerHypRente",
	OFFERTE_PREMIE = "OffertePremie",
	WIJZ_PREMIE = "WijzPremie",
	WIJZ_NON_SMOKERS = "WijzNietRokers",
	OFFERTE_STOPPENPREMIE = "OfferteStoppenPremie",
	WIJZ_STOPPENPREMIE = "WijzStoppenPremie",
	OFFERTE_VERZEKERDEN = "OfferteVerzekerden",
	WIJZ_VERZEKERDEN = "WijzVerzekerden",
	WIJZ_VERZEKERINGNEMER = "WijzVerzekeringnemer",
	INFO_ALGEMEEN = "InfoAlgemeen",
	INFO_OVERLIJDEN = "InfoOverlijden",
	INFO_AFKOOP = "InfoAfkoop",
	INFO_EXPIRATIE = "InfoExpiratie",
	INFO_WIJZIGING = "InfoWijziging",
	WIJZ_OVERIG = "WijzOverig",
	KLACHT = "Klacht",
	BEPALEN_BELASTINGSTATUS = "BepBelasting"
}
